import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form';
import styles from '../../styles/UserSettings.module.scss'
import { FormInputSelect } from '../Shared/FormComponents/FormInputSelect/FormInputSelect';
import { Accordion, AccordionDetails, AccordionSummary, ListSubheader, Skeleton, ToggleButton, Tooltip, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { UserSettingsValues } from './UserSettings';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import { FormInputSelectMenuItem } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem';
import flagStyles from '../../styles/flag.module.css';

var length = require('convert-units/lib/definitions/length');
var area = require('convert-units/lib/definitions/area');
var volume = require('convert-units/lib/definitions/volume');
var mass = require('convert-units/lib/definitions/mass');

export interface PreferenceSettings {
    language: string;
    unitSettings: UnitSettings;
}

export enum Languages {
    'English' = 'en',
    'German' = 'de',
    'Japanese' = 'jp',
    'Spanish' = 'es'
}

export enum UnitSystem {
    METRIC = "Metric",
    IMPERIAL = "Imperial",
    CUSTOM = "Custom"
}


export interface UnitSettings {
    system: UnitSystem;
    length: string;
    area: string;
    volume: string;
    weight: string;
    decimals: number;
}

function minmax(min: number, max: number, x: number): number {
    return Math.max(min, Math.min(max, x));
}

export default function PreferenceSettingsPanel(props: PreferenceSettingsPanelProps) {
    const { t } = useTranslation('common');
    const { control, getValues, setValue } = props.useForm;
    let values = props.values;
    const [unitSystem, setUnitSystem] = useState<UnitSystem>(UnitSystem.CUSTOM);
    const [showAdvancedUnitSettings, setShowAdvancedUnitSettings] = useState(false);
    const [decimalPoint, setDecimalPoint] = useState(2);

    const languageOptions = Object.entries(Languages);


    useEffect(() => {
        let system = values?.unitSettings?.system;
        if (system)
            setUnitSystem(system);
        let decimals = values?.unitSettings?.decimals;
        if (decimals)
            setDecimalPoint(decimals);
    }, [values])


    const getUnitOptions = (data: any): JSX.Element[] => {
        let { metric, imperial } = data;
        let out: JSX.Element[] = [];
        switch (unitSystem) {
            case UnitSystem.METRIC: {
                out.push(...Object.keys(metric).map(key => <FormInputSelectMenuItem key={key} value={key}>{key}</FormInputSelectMenuItem>))
                break;
            }
            case UnitSystem.IMPERIAL: {
                out.push(...Object.keys(imperial).map(key => <FormInputSelectMenuItem key={key} value={key}>{key}</FormInputSelectMenuItem>));
                break;
            }
            case UnitSystem.CUSTOM: {
                out.push(<ListSubheader key='metricHeader' className={styles.optionHeader}>
                    <Typography variant='subtitle1'> {t("PreferenceSettings.Metric")}
                    </Typography>
                </ListSubheader>)
                out.push(
                    ...Object.keys(metric).map(key => {
                        return (<option key={key} value={key}>{key}</option>)
                    }));
                out.push(<ListSubheader key='imperialHeader' className={styles.optionHeader}>
                    <Typography variant='subtitle1'>
                        {t("PreferenceSettings.Imperial")}
                    </Typography>
                </ListSubheader>)
                out.push(...Object.keys(imperial).map(key => <FormInputSelectMenuItem key={key} value={key}>{key}</FormInputSelectMenuItem>));
            }
        }
        return out;
    }

    const handleSystemChange = (event) => {
        let system = event.target.value;
        setUnitSystem(system);
        setValue('preferenceSettings.unitSettings.system', system);
        if (system === UnitSystem.CUSTOM)
            return;
        let unitSettings = getValues('preferenceSettings.unitSettings');
        if (!Object.keys(length[system.toLowerCase()]).includes(unitSettings.length)) {
            setValue('preferenceSettings.unitSettings.length', system === UnitSystem.METRIC ? 'mm' : 'in');
        }
        if (!Object.keys(area[system.toLowerCase()]).includes(unitSettings.area)) {
            setValue('preferenceSettings.unitSettings.area', system === UnitSystem.METRIC ? 'mm2' : 'in2');
        }
        if (!Object.keys(volume[system.toLowerCase()]).includes(unitSettings.volume)) {
            setValue('preferenceSettings.unitSettings.volume', system === UnitSystem.METRIC ? 'mm3' : 'in3');
        }
        if (!Object.keys(mass[system.toLowerCase()]).includes(unitSettings.weight)) {
            setValue('preferenceSettings.unitSettings.weight', system === UnitSystem.METRIC ? 'kg' : 'lb');
        }

    }

    const handleDecimalPointChange = (event, name?) => {
        setDecimalPoint(event.target.value);
    }

    const getLanguageOptions = () => {
        return languageOptions.map(([key, value], index) => <FormInputSelectMenuItem key={'language-' + value} value={value} avatar={{ styleClass: `${flagStyles.flag} ${flagStyles[value]}` }} type={'iconRight'}>{t("PreferenceSettings." + key)}</FormInputSelectMenuItem>);
    }

    const getAdvancedUnitOptions = () => {
        return Object.entries(UnitSystem).map(([key, value], index) => <FormInputSelectMenuItem key={'system-' + value} value={value}>{t("PreferenceSettings." + value)}</FormInputSelectMenuItem>);
    }

    return (
        <div className={styles.prefInputGrid}>
            {values ?
                <FormInputSelect search name="preferenceSettings.language" control={control} label={t("PreferenceSettings.Language")}>
                    {getLanguageOptions()}
                </FormInputSelect> :
                <Skeleton className={styles.skeleton} variant='rectangular' />}
            {/* Units */}
            {values ?
                <FormInputSelect onChange={handleSystemChange} name="preferenceSettings.unitSettings.system" control={control} label={t("PreferenceSettings.Units")}
                    endButton={<Tooltip title={t("PreferenceSettings.Advanced")}>
                        <ToggleButton value="advanced" selected={showAdvancedUnitSettings} className={styles.advancedButton} size='small' color='primary' onClick={() => { setShowAdvancedUnitSettings(!showAdvancedUnitSettings) }}>
                            <SettingsIcon />
                        </ToggleButton >
                    </Tooltip>}>
                    {getAdvancedUnitOptions()}
                </FormInputSelect> :
                <Skeleton className={styles.skeleton} variant='rectangular' />}
            <Accordion expanded={showAdvancedUnitSettings} disableGutters={true} className={styles.accordion} classes={{ root: styles.accordion }}>
                <AccordionSummary style={{ display: 'none' }}>
                    <div style={{ display: 'none' }}>
                    </div>
                </AccordionSummary>
                <AccordionDetails classes={{ root: styles.accordionDetailsInput }}>
                    <FormInputSelect search name="preferenceSettings.unitSettings.length" control={control} label={t("PreferenceSettings.Length")} required={true}>
                        {getUnitOptions(length)}
                    </FormInputSelect>
                    <FormInputSelect search name="preferenceSettings.unitSettings.area" control={control} label={t("PreferenceSettings.Area")} >
                        {getUnitOptions(area)}
                    </FormInputSelect>
                    <FormInputSelect search name="preferenceSettings.unitSettings.volume" control={control} label={t("PreferenceSettings.Volume")} >
                        {getUnitOptions(volume)}
                    </FormInputSelect>
                    <FormInputSelect search name="preferenceSettings.unitSettings.weight" control={control} label={t("PreferenceSettings.Weight")} >
                        {getUnitOptions(mass)}
                    </FormInputSelect>
                </AccordionDetails>
            </Accordion>
            {
                values ?
                    <FormInputText rules={{ min: 0, max: 10, required: true }} onChange={handleDecimalPointChange} name="preferenceSettings.unitSettings.decimals" control={control} InputProps={{ type: "number", inputProps: { min: 0, max: 10 } }} helpertext={`${t('PreferenceSettings.DecimalHelper')} ${Number(1).toFixed(minmax(0, 10, decimalPoint))}`} errortext={t('PreferenceSettings.DecimalError')} label={t('PreferenceSettings.DecimalLabel')} /> :
                    <Skeleton className={styles.skeleton} variant='rectangular' />
            }
        </div>
    )
}

export interface PreferenceSettingsPanelProps {
    formId?: string;
    useForm: UseFormReturn<UserSettingsValues>;
    values: PreferenceSettings;
}