import constants from '../services/constants';
import { atom } from 'recoil';




const addedTeamRoleAtom = atom<{role:string, roleId:string} | null>({
    key: constants.addedTeamRoleAtom,
    default: null,
});

export default addedTeamRoleAtom;