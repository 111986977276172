import constants from '../services/constants';
import { atom } from 'recoil';

export interface ISelectedTeamMember {
    teamID: string,
    userId: string,
    username: string, 
    email: string, 
    telephone: string, 
    role: string,
    roleId:string,
    userImage: string
    index: number,
    isTeamAdmin: boolean,
    backgroundColor: string,
    isActive: boolean
}

const selectedTeamMemberAtom = atom<ISelectedTeamMember | null>({
    key: constants.selectedTeamMemberAtom,
    default: null,
});

export default selectedTeamMemberAtom;