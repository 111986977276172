import { Chip, ChipProps } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getChipLabel, ProjectState, IssueState, ModelState } from '../../types/enums'
import { palette } from 'theme';

export const IssueStateChip: React.FC<{ initialState: IssueState, onStatusChange?: (newState: IssueState) => void } & ChipProps> = ({ initialState, onStatusChange, ...props }) => {
    const { t } = useTranslation('common')
    const [color, setColor] = useState<string>(palette.state.closed);
    const [state, setStatus] = useState<IssueState>(initialState);

    const setIssueColor = (val: IssueState | undefined) => {
        switch (val?.toLowerCase()) {
            case 'active': setColor(palette.state.active); break;
            case 'resolved': setColor(palette.state.resolved); break;
            case 'active-due': setColor(palette.state.active); break;
            case 'closed': setColor(palette.state.closed); break;
            default: setColor(palette.state.closed); break;
        }
    }
    useEffect(() => {
        setStatus(state)
        setIssueColor(state)
        onStatusChange && onStatusChange(state)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const handleClick = (e: any) => {
        e.stopPropagation();
        switch (state?.toLowerCase()) {
            case 'active': setStatus('resolved'); break;
            case 'resolved': setStatus('active-due'); break;
            case 'active-due': setStatus('closed'); break;
            case 'closed': setStatus('active'); break;
            default: setStatus('active'); break;
        }
    }
    return (
        <Chip onClick={handleClick} size={'small'} style={{ backgroundColor: color, color: palette.text.third, fontWeight: 'bold' }} label={getChipLabel(state, t)} {...props} />
    )
}

export const ProjectStateChip: React.FC<{ state: ProjectState } & ChipProps> = ({ state, ...props }) => {
    const { t } = useTranslation('common')
    let color;
    switch (state) {
        case 'open': color = palette.state.active; break;
        case 'on-hold': color = palette.state.onhold; break;
        case 'completed': color = palette.state.resolved; break;
        default: color = palette.state.closed; break;
    }
    return (
        <Chip size={'small'} style={{ backgroundColor: color, color: palette.text.third, fontWeight: 'bold' }} label={getChipLabel(state, t)}  {...props} />
    )
}

export const ModelStateChip: React.FC<{ state: ModelState, onStatusChange?: (newState: ModelState) => void } & ChipProps> = ({ state, onStatusChange, ...props }) => {
    const { t } = useTranslation('common')
    const [color, setColor] = useState<string>(palette.state.closed);
    const [status, setStatus] = useState<ModelState>();

    useEffect(() => {
        setStatus(state);
        switch (state?.toLowerCase()) {
            case 'archived': setColor(palette.state.closed); break;
            case 'complete': setColor(palette.state.resolved); break;
            case 'planned': setColor(palette.state.open); break;
            case 'started': setColor(palette.state.active); break;
            case 'superseded': setColor(palette.state.onhold); break;
            default: setColor(palette.state.closed);; break;
        }
    }, [state])

    const handleClick = (e: any) => {
        e.stopPropagation();
        let out: ModelState;
        switch (status?.toLowerCase()) {
            case 'archived': out = 'superseded'; break;
            case 'complete': out = 'archived'; break;
            case 'planned': out = 'started'; break;
            case 'started': out = 'complete'; break;
            default: out = 'planned'; break;
        }
        setStatus(out);
        switch (out) {
            case 'archived': setColor(palette.state.closed); break;
            case 'complete': setColor(palette.state.resolved); break;
            case 'planned': setColor(palette.state.open); break;
            case 'started': setColor(palette.state.active); break;
            case 'superseded': setColor(palette.state.onhold); break;
            default: setColor(palette.state.closed);; break;
        }
        if (onStatusChange)
            onStatusChange(out);
    }

    return (
        <Chip onClick={handleClick} size={'small'} style={{ backgroundColor: color, color: palette.text.third, fontWeight: 'bold' }} label={getChipLabel(status, t)}  {...props} />
    )
}

