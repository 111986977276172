import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'

const DoneButton: React.FC<{done: () => void}> = ({done}) => {
    const {t} = useTranslation('common')
    return (
        <Tooltip title={t("DoneButton.Done")}>
            <CheckIcon onClick={() => done()} fontSize="small"></CheckIcon>
        </Tooltip>
    )
}
// sx={{ fontSize: 40 }}
// fontSize="small"

export default DoneButton