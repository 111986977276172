import React, { useState, useEffect, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IssueElement.module.scss';
import restAPI from 'services/rest-api';
import WarningIcon from '@mui/icons-material/Warning';
import { States } from './IssueDialog';
import { Box, Typography, Skeleton, Divider } from '@mui/material';
import Ripple from 'components/Shared/Ripple';
import ContextualMenu from 'components/Shared/ContextualMenu/ContextualMenu';
import ContextualMenuItem from 'components/Shared/ContextualMenu/ContextualMenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToEye } from '@fortawesome/free-solid-svg-icons';
export interface IssueElementProps {
    issue: any;
    onClick: (issue: any) => void;
    handlePreview: (issueId: string) => void;
    projectId: string;
    highlight: boolean;
    selected: boolean;
    modelviewer: any;
    onPropertyChange: (id: string, propertyName: string, event: any) => void;
    onClose: () => void;
    onHover: (issueId: string) => void;
    onIssueDelete: (issueId: string) => void;
}

export const IssueElement: React.FC<Partial<IssueElementProps>> = (props) => {
    let { issue, onIssueDelete, onHover, onClick, handlePreview } = props;
    let { t } = useTranslation('common');
    const [assigned, setAssigned] = useState(null);
    let properties: { [key: string]: string } = Object.entries(issue?.properties ?? {}).reduce((red, [key, value]: [string, any]) => {
        return { ...red, ...{ [key]: value?.value } };
    }, {});
    const [thumbnail, setThumbnail] = useState(null);
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        let mounted = true;
        if (!(properties?.assigned_to?.length > 0)) {
            setAssigned('');
        } else {
            restAPI
                .getUserInfoForId(properties.assigned_to, ['name'])
                .then((result) => {
                    if (mounted) setAssigned(result.name);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        return () => {
            mounted = false;
        };
    }, [issue, properties.assigned_to]);

    useEffect(() => {
        let mounted = true;
        restAPI
            .getNodeThumbnail(issue.projectId, issue._id)
            .then((result) => {
                if (mounted) setThumbnail(result);
            })
            .catch((err) => {
                console.error(err);
                restAPI
                    .getProjectThumbnail(issue.projectId)
                    .then((result) => {
                        if (mounted) setThumbnail(result);
                    })
                    .catch((err) => {
                        if (mounted) setThumbnail('');
                    });
            });

        return () => {
            mounted = false;
        };
    }, [issue]);

    const handleHover = (e: any) => {
        let type = e?.type;
        switch (type) {
            case 'pointerenter':
                onHover?.(issue);
                break;
            case 'pointerleave':
                onHover?.(null);
                break;
        }
    };
    const handleClick = (e: any) => {
        onClick?.(issue);
    };

    return (
        <Box ref={ref} className={`${styles.issueElement}`} onClick={handleClick} onPointerEnter={handleHover} onPointerLeave={handleHover}>
            <Box className={styles.thumbnail}>
                {thumbnail === null ? (
                    <Skeleton variant="rectangular" width={'128px'} height={'72px'} style={{ gridArea: 'thumbnail' }} />
                ) : thumbnail.length > 0 ? (
                    <img src={thumbnail} alt="Thumbnail of issue" width="128" />
                ) : (
                    <Box className={styles.thumbnail}>
                        <WarningIcon />
                    </Box>
                )}
            </Box>
            <Box className={styles.info}>
                <Box className={styles.title}>
                    <Typography variant="body1">{issue?.properties?.title?.value}</Typography>
                    <Box
                        className={`${styles['state']} ${styles[properties?.state ?? 'unset']}`}
                        title={States[properties?.state] ? t(`IssueDialog.States.${States[properties.state]}`) : 'Unset'}>
                        <Typography variant="body1">{properties.state?.substring(0, 1) ?? 'U'}</Typography>
                    </Box>
                </Box>
                <Box className={styles.assigned}>
                    {assigned === null ? (
                        <Skeleton variant="text" width={64} />
                    ) : (
                        <Typography variant="caption" title={assigned ?? t('IssuesPanel.NotAssigned')}>
                            {assigned.length > 0 ? t('IssuesPanel.AssignedTo') + assigned : t('IssuesPanel.NotAssigned')}
                        </Typography>
                    )}
                </Box>
            </Box>

            <Ripple parentRef={ref.current} />
            <ContextualMenu parent={ref}>
                <ContextualMenuItem onClick={() => handlePreview(issue)}>
                    <FontAwesomeIcon icon={faArrowsToEye} />
                    {t('IssuesPanel.Preview')}
                </ContextualMenuItem>
                <Divider />
                <ContextualMenuItem variant="red" onClick={() => onIssueDelete(issue._id)}>
                    <DeleteIcon />
                    {t('IssuesPanel.Delete')}
                </ContextualMenuItem>
            </ContextualMenu>
        </Box>
    );
};
