import { Link } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../styles/LanguageBar.module.css'
import constants from '../../services/constants'

type LanguageType = 'en' | 'de' | 'es' | 'jp';

const baseLanguageStyle = {
    fontSize: 16,
    fontWeight: 400,
    color: 'rgb(139, 139, 139, 0.6)',
    textDecoration: 'none'
}
const selectedLanguageStyle = { ...baseLanguageStyle, fontWeight: 700 }

export default function LanguageBar() {
    const getValidLanguage = (lang: string | null): LanguageType => {
        if (lang === 'en' || lang === 'de' || lang === 'es' || lang === 'jp') {
            return lang;
        }
        return 'en';
    }
    const savedLanguage: LanguageType = getValidLanguage(localStorage.getItem(constants.languageStorage));
    const { i18n } = useTranslation('common')
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>(savedLanguage)

    const changeLanguage = useCallback((selected: LanguageType) => {
        i18n.changeLanguage(selected)
        setSelectedLanguage(selected)
        localStorage.setItem(constants.languageStorage, selected)
    }, [i18n]);

    const getStyle = (selected: LanguageType) => {
        return selectedLanguage === selected ? selectedLanguageStyle : baseLanguageStyle
    }

    useEffect(() => { changeLanguage(savedLanguage) }, [savedLanguage, changeLanguage])

    return (<div className={styles.root}>
        <Link sx={getStyle('en')} onClick={() => changeLanguage('en')}>English</Link>
        <span className={styles.separator}>&nbsp;·&nbsp;</span>
        <Link sx={getStyle('de')} onClick={() => changeLanguage('de')}>Deutsch</Link>
        <span className={styles.separator}>&nbsp;·&nbsp;</span>
        <Link sx={getStyle('es')} onClick={() => changeLanguage('es')}>Español</Link>
        <span className={styles.separator}>&nbsp;·&nbsp;</span>
        <Link sx={getStyle('jp')} onClick={() => changeLanguage('jp')}>日本語</Link>
    </div>)
}
