import constants from '../services/constants';
import { atom } from 'recoil';

export interface ISelectedTeamRole {
    teamID: string,
    role: string,
    roleId: string,
    index: number
}

const selectedTeamRoleAtom = atom<ISelectedTeamRole | null>({
    key: constants.selectedTeamRoleAtom,
    default: null,
});

export default selectedTeamRoleAtom;