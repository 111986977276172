import { Box, Menu, MenuItem, Skeleton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import styles from 'styles/SearchDialog.module.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IssueStateChip, ProjectStateChip } from './StateChip'
import theme from 'theme';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import restAPI from '../../services/rest-api'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import WarningIcon from '@mui/icons-material/Warning';

function toTitleCase(str) {
    if (!str)
        return '';
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

function getHighlightString(highlight) {
    if (!highlight)
        return null;
    let prop = ''
    let hitValue = highlight.texts.find((text) => text.type === 'hit')?.value;
    let hitValueText = highlight.texts.reduce((a, b) => a + b.value, "").split('/').find((text) => text.includes(hitValue));
    if (highlight.path === 'documents.path') {
        let index = 0
        let pos = 0;
        let curr = highlight.texts[index];
        while (index < highlight.texts.length) {
            curr = highlight.texts[index];
            if (curr.type === 'hit')
                break;
            pos += curr.value.split('/').length;
            index++;
        }
        switch (pos) {
            case 3: prop = 'Project ID'; break;
            case 4: prop = 'Document ID'; break;
            case 5: prop = 'Name'; break;
            default: prop = highlight.path;
        }
    }
    else if (highlight.path.includes('properties')) {
        prop = highlight.path.split('.').filter((str) => str.toLowerCase() !== 'value').pop();
    }
    else {
        prop = highlight.path;
    }
    return { prop: prop, value: hitValueText };
}

const IssueCard: React.FC<{ issue: any }> = ({ issue }) => {
    const { t } = useTranslation('common');
    let { prop, value } = getHighlightString(issue.highlights[0]);
    const [thumbnail, setThumbnail] = useState(null)

    useEffect(() => {
        restAPI.getProjectThumbnail(issue.projectId).then((result) => {
            setThumbnail(result.data.thumbnail);
        }).catch((err) => {
            setThumbnail('');
        });
    }, [issue])

    return (
        <MenuItem classes={{ root: styles.item }} key={`issue-${issue._id}`} id={issue._id} data-type='issue'>
            <Link to={`/issue/${issue._id}`} className={styles.itemLink}>
                <Tooltip title={toTitleCase(t(prop)) + ": " + toTitleCase(value)}>
                    <Box className={`${styles.issueCard} ${styles.itemCard}`}>
                        <Typography variant='h5' className={styles.issueTitle}>
                            {issue.properties.title.value}
                        </Typography>
                        <Typography variant='caption' className={styles.issueProject}>
                            {issue.projectJoin.name}
                        </Typography>
                        <Typography variant='caption' className={styles.issueAssignee}>
                            {t("SearchDialog.Assigned") + " " + issue.properties.assigned_to.value}
                        </Typography>
                        <IssueStateChip initialState={issue.properties.state.value} className={styles.stateChip} />
                        {thumbnail === null ? <Skeleton variant='rectangular' width={'128px'} height={'72px'} style={{ gridArea: 'thumbnail' }} /> :
                            (thumbnail.length > 0) ?
                                <img src={thumbnail} style={{ gridArea: 'thumbnail' }} alt='thumbnail of issue' className={styles.thumbnail} /> :
                                <Box className={styles.thumbnail}>
                                    <WarningIcon className={styles.thumbnailIcon} />
                                </Box>
                        }
                    </Box>
                </Tooltip>
            </Link>
        </MenuItem>
    )
}

const DocumentCard: React.FC<{ document: any }> = ({ document }) => {
    const { t } = useTranslation('common');
    let { prop, value } = getHighlightString(document.highlights[0]);
    const [thumbnail, setThumbnail] = useState(null)

    useEffect(() => {
        restAPI.getProjectThumbnail(document.projectId).then((result) => {
            setThumbnail(result.data.thumbnail);
        }).catch((err) => {
            setThumbnail('');
        });
    }, [document])

    return (
        <MenuItem classes={{ root: styles.item }} key={`document-${document._id}`} id={document._id} data-type='document'>
            <Link to={`/document/${document._id}`} className={styles.itemLink}>
                <Tooltip title={toTitleCase(t(prop)) + ": " + toTitleCase(value)}>
                    <Box className={`${styles.issueCard} ${styles.itemCard}`}>
                        <Typography variant='h5' className={styles.issueTitle}>
                            {document.properties.name.value}
                        </Typography>
                        <Typography variant='caption' className={styles.issueProject}>
                            {document.projectJoin.name}
                        </Typography>
                        {thumbnail === null ? <Skeleton variant='rectangular' width={'128px'} height={'72px'} style={{ gridArea: 'thumbnail' }} /> :
                            (thumbnail.length > 0) ?
                                <img src={thumbnail} style={{ gridArea: 'thumbnail' }} alt='thumbnail of document' className={styles.thumbnail} /> :
                                <Box className={styles.thumbnail}>
                                    <WarningIcon className={styles.thumbnailIcon} />
                                </Box>
                        }
                    </Box>
                </Tooltip>
            </Link>
        </MenuItem>
    )
}

const ProjectCard: React.FC<{ project: any }> = ({ project }) => {
    const { t } = useTranslation('common');

    let { prop, value } = getHighlightString(project.highlights[0]);
    const [thumbnail, setThumbnail] = useState(null)
    useEffect(() => {
        let mount = true;
        restAPI.getProjectThumbnail(project.projectId).then((result) => {
            if (!mount)
                return;
            setThumbnail(result.data.thumbnail);
        }).catch((err) => {
            setThumbnail('');
        });
        return () => {
            mount = false;
        }
    }, [project])


    return (
        <MenuItem classes={{ root: styles.item }} key={`project-${project._id}`} id={project._id} data-type='project'>
            <Link to={`/project/${project.projectId}/`} className={styles.itemLink}>
                <Tooltip title={toTitleCase(t(prop)) + ": " + toTitleCase(value)}>
                    <Box className={`${styles.projectCard} ${styles.itemCard}`}>
                        <Typography variant='h5' className={styles.projectTitle}>
                            {project.projectName}
                        </Typography>
                        <Typography variant='caption' className={styles.projectTeam}>
                            {project.teamName}
                        </Typography>
                        <Typography variant='caption' className={styles.projectUpdate}>
                            {project.updated?.value}
                        </Typography>
                        <ProjectStateChip state={project.projectState} className={styles.stateChip} />
                        {thumbnail !== null ?
                            (thumbnail.length > 0) ?
                                <img src={thumbnail} style={{ gridArea: 'thumbnail' }} alt='thumbnail of project' className={styles.thumbnail} /> :
                                <Box className={styles.thumbnail}>
                                    <MapsHomeWorkIcon className={styles.thumbnailIcon} />
                                </Box> :
                            <Skeleton variant='rectangular' width={'128px'} height={'72px'} style={{ gridArea: 'thumbnail' }} />}
                    </Box>
                </Tooltip>
            </Link>
        </MenuItem>
    )
}

const SkeletonCard = () => {
    return (
        [<MenuItem key="skeleton-title" className={styles.categoryTitle} disabled>
            <Skeleton variant='text' style={{ width: '100%' }} />
        </MenuItem>,
        <MenuItem key={`skeleton-card`} disabled>
            <Box className={`${styles.projectCard} ${styles.itemCard}`}>
                <Skeleton variant='text' className={styles.projectTitle} />
                <Skeleton variant='text' className={styles.projectTeam} />
                <Skeleton variant='text' className={styles.projectUpdate} />
                <Skeleton variant='rectangular' className={styles.stateChip} />
                <Skeleton variant='rectangular' width={'128px'} height={'72px'} style={{ gridArea: 'thumbnail' }} />
            </Box>
        </MenuItem>]
    )
}



export default function SearchResultDialog(props: SearchResultProps) {
    const scrollbarRef = useRef<PerfectScrollbar>(null);
    // Translation
    const { t } = useTranslation('common');

    const getResultEntries = (results: any[]) => {
        if (!results)
            return null;
        // Return empty skeleton
        if (results.length === 0) {
            return (
                <MenuItem key="skeleton-title" className={styles.noResults} style={{ backgroundColor: theme.palette.error.main }} disabled>
                    <Typography align='center' color={theme.palette.error.contrastText} >
                        {t('SearchDialog.NoResults')}
                    </Typography>
                </MenuItem>)
        }

        let issues: any[] = [], documents: any[] = [], projects: any[] = []
        results.forEach((result) => {
            result.highlights = result.highlights.filter((hit) => !['itemtype', 'urns'].some((prop) => hit.path.includes(prop))).sort((a: any, b: any) => b.score - a.score);
            if (result.highlights.length === 0)
                return;
            switch (result.itemtype) {
                case 'hsbshare.issue': issues.push(result); break;
                case 'hsbshare.document': documents.push(result); break;
                default: projects.push(result); break;
            }
        });

        // Sorting on search score
        issues = issues.sort((a, b) => b.score - a.score);
        documents = documents.sort((a, b) => b.score - a.score);
        projects = projects.sort((a, b) => b.score - a.score);
        let out = [];
        if (projects.length > 0) {
            out.push(
                <MenuItem key="projects-title" className={styles.categoryTitle} disabled>
                    <Typography variant='subtitle2'>
                        {t('SearchDialog.Projects')}
                    </Typography>
                </MenuItem>);
            out.push(...projects.map((proj) =>
                <ProjectCard key={`project-${proj._id}`} project={proj} />
            ))
        }
        if (issues.length > 0) {
            out.push(<MenuItem key="issue-title" className={styles.categoryTitle} disabled>
                <Typography variant='subtitle2'>
                    {t('SearchDialog.Issues')}
                </Typography>
            </MenuItem>);
            out.push(...issues.map((issue) =>
                <IssueCard key={`issue-${issue._id}`} issue={issue} />
            ))
        }
        if (documents.length > 0) {
            out.push(
                <MenuItem key="doc-title" className={styles.categoryTitle} disabled>
                    <Typography variant='subtitle2'>
                        {t('SearchDialog.Documents')}
                    </Typography>
                </MenuItem>);
            out.push(...documents.map((doc) =>
                <DocumentCard key={`document-${doc._id}`} document={doc} />
            ))
        }

        return (<PerfectScrollbar ref={scrollbarRef} options={{ suppressScrollX: true }} className={styles.scrollbar}>
            {out}
        </PerfectScrollbar>)
    }

    const handleClose = (event: React.MouseEvent, reason?: 'backdropClick' | 'escapeKeyDown') => {
        if (reason === 'backdropClick')
            return;

        if (props.onClose)
            props.onClose(event);
    }

    const handleBlur = (event: React.FocusEvent) => {
        let related = event.relatedTarget as Node;
        if (!related || !props.anchor.parentNode.contains(related)) {
            handleClose(null);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            scrollbarRef.current?.updateScroll();
        }, 200);
    })

    return (
        <Menu open={props.open}
            onClose={handleClose}
            onBlur={handleBlur}
            anchorEl={props.anchor}
            className={styles.dialog}
            disablePortal={true}
            disableAutoFocus={true}
            disableRestoreFocus={true}
            disableEnforceFocus={true}
            autoFocus={false}
            classes={{ root: styles.root, paper: styles.paper }}>
            {props.results ?
                getResultEntries(props.results) :
                SkeletonCard()
            }
        </Menu >
    )
}

export interface SearchResultProps {
    open: boolean;
    results: any[];
    onClose?: (event: React.MouseEvent) => void;
    onSelect?: (event: React.MouseEvent) => void;
    anchor: HTMLElement;
}