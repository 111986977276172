import { Languages, UnitSystem } from 'components/UserSettings/PreferenceSettings';
import { UserSettingsValues } from 'components/UserSettings/UserSettings';
import { createContext } from 'react';
import Cookies from 'universal-cookie';
import restAPI from 'services/rest-api';

export interface UserSettings {
    settings: UserSettingsValues;
    setSettings: React.Dispatch<UserSettingsValues>;
    fetchSettings: () => Promise<UserSettingsValues>;
}

export const fetchSettings = () => {
    return Promise.all([
        restAPI.getUserInfo('phone', 'avatar', 'activeTeam', 'registered'),
        restAPI.getUserTeams(),
        restAPI.getUserSettings(),
        restAPI.getNotifSettings(),
    ])
        .then(([userInfo, userTeams, prefSettings, notifSettings]) => {
            let fetchedSettings: UserSettingsValues = {};
            // General Settings
            let active =
                userTeams.data.teams.find((t) => {
                    return t.teamID === userInfo.activeTeam;
                }) || userTeams.data.teams[0];
            fetchedSettings.generalSettings = {
                avatar: {
                    URI: userInfo?.avatar?.URI || '',
                    letter: '',
                    color: userInfo?.avatar?.backgroundColor || 'hsl(' + Math.random() * 359 + ',70%,68%)',
                },
                name: '',
                email: '',
                team: active.teamID ?? '',
                /**
                 * @todo: API call for team
                 */
                phone: userInfo.phone,
                teams: userTeams.data?.teams,
                registered: userInfo.registered,
            };
            //Pref Settings
            fetchedSettings.preferenceSettings = {
                language: prefSettings.data?.language || Languages.English,
                unitSettings: prefSettings.data?.unitSettings || {
                    system: UnitSystem.METRIC,
                    length: 'mm',
                    area: 'mm2',
                    volume: 'mm3',
                    weight: 'kg',
                    decimals: prefSettings.data?.decimalPointSettings || new Cookies().get('settings_decimal_point') || 2,
                },
            };
            // Notif Settings
            let notifInput = {};
            // Function: createNestedObject( base, names[, value] )
            //   base: the object on which to create the hierarchy
            //   names: an array of strings contaning the names of the objects
            //   value (optional): if given, will be the last object in the hierarchy
            // Returns: the last object in the hierarchy
            var createNestedObject = function (base, names, value) {
                // If a value is given, remove the last name and keep it for later:
                var lastName = arguments.length === 3 ? names.pop() : false;

                // Walk the hierarchy, creating new objects where needed.
                // If the lastName was removed, then the last object is not set yet:
                for (var i = 0; i < names.length; i++) {
                    base = base[names[i]] = base[names[i]] || {};
                }

                // If a value was given, set it to the last name:
                if (lastName) base = base[lastName] = value;

                // Return the last object in the hierarchy:
                return base;
            };

            Object.entries(notifSettings).forEach(([key, value]) => {
                createNestedObject(notifInput, key.split('-'), value);
            });
            fetchedSettings.notificationSettings = notifInput;
            setSettings(fetchedSettings);
            return fetchedSettings;
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

var settings: UserSettingsValues = null;
const setSettings = (v: UserSettingsValues):void => {
    settings = v;
};

export const UserSettingsValue = { settings: settings, setSettings: setSettings, fetchSettings: fetchSettings };

export const UserSettingsContext = createContext<UserSettings>({
    settings: null,
    setSettings: () => {},
    fetchSettings: fetchSettings,
});
