import { Menu, MenuProps } from '@mui/material';
import { RefObject, useEffect, useState } from 'react';
import styles from './ContextualMenu.module.scss';

export default function ContextualMenu(props: ContextualMenuProps) {
    let { parent, onClose, classes, ...menuProps } = props;
    const [position, setPosition] = useState<{ x: number; y: number } | null>(null);

    const handleContextMenu = (e: any) => {
        setPosition(position ? null : { x: e.clientX, y: e.clientY });
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClose = (e: any, reason: 'backdropClick' | 'escapeKeyDown') => {
        setPosition(null);
        onClose?.(e, reason);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClick = (e: any) => {
        handleClose(e, 'backdropClick');
    }

    useEffect(() => {
        let parentRef = parent.current;
        parentRef?.addEventListener('contextmenu', handleContextMenu);

        return () => {
            parentRef?.removeEventListener('contextmenu', handleContextMenu);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parent]);

    return (
        <Menu
            open={!!position}
            onClick={handleClick}
            onClose={handleClose}
            classes={Object.assign({ paper: styles['contextual-menu-container'] }, classes)}
            anchorReference="anchorPosition"
            anchorPosition={position ? { top: position.y - 6, left: position.x + 2 } : undefined}
            {...menuProps}>
            {props.children}
        </Menu>
    );
}

export interface ContextualMenuProps extends Omit<MenuProps, 'open' | 'anchorPosition' | 'anchorReference'> {
    parent: RefObject<HTMLElement>;
}
