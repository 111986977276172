import constants from '../services/constants';
import { atom } from 'recoil';

export interface IPublicProjectPolicy {
    projectId: string,
    isPublic: boolean,
    policies?: Array<{ name: "read" | "none", resource: string }>
}

export interface IProjectUserPolicy {
    id: string,
    name: string,
    policies?: Array<{name: string, resource: string}>
}

const publicProjectPoliciesAtom = atom<(IPublicProjectPolicy | null)>({
    key: constants.publicProjectPoliciesAtom,
    default: null,
});

export default publicProjectPoliciesAtom;
