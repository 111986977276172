import React from 'react'
import styles from '../../styles/CreateAccount.module.css'
import { Link, useNavigate } from "react-router-dom"
import { useFormik } from 'formik'
import SnackBarMessage, { ISnackBarData } from '../Shared/snackBar/SnackBarMessage'
import respAPI from '../../services/rest-api'
import HalfFooter from '../Shared/HalfFooter'
import { useTranslation } from "react-i18next"
import constants from '../../services/constants'
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import RoundedButton from 'components/Shared/RoundedButton'
import HsbShareLogo from 'components/Shared/HsbShareLogo'

interface ValidateState {
    showPassword: boolean,
    showConfirmPassword: boolean,
    confirmPasswordError: boolean,
    agreeToTermsError: boolean,
    passwordLengthError: boolean
}

export default function CreateAccount() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [validationValues, setValues] = React.useState<ValidateState>({
        showPassword: false,
        showConfirmPassword: false,
        confirmPasswordError: false,
        agreeToTermsError: false,
        passwordLengthError: false
    });
    const [stateSnackBar, setSnackBar] = React.useState<ISnackBarData>({ open: false, severity: 'success', message: '' });

    const handleChange = (prop: keyof ValidateState) => {
        setValues({ ...validationValues, [prop]: !validationValues[prop] });
    };

    const validate = (values: any) => {
        let errors: any = {}
        const confirmPasswordError = values.confirmPassword !== values.password;
        const agreeToTermsError = !values.agreeToTerms;
        const passwordLengthError = (values?.password?.length || 0) < 8
        if (confirmPasswordError) {
            errors.confirmPassword = "Passwords must match.";
        }
        if (agreeToTermsError) {
            errors.agreeToTerms = "To create an account, agree to usage terms.";
        }
        if (passwordLengthError) {
            errors.confirmPassword = "Password must be at least 8 characters long.";
        }
        setValues({
            ...validationValues,
            confirmPasswordError: confirmPasswordError,
            agreeToTermsError: agreeToTermsError,
            passwordLengthError: passwordLengthError
        })
        return errors;
    }

    const formik = useFormik({
        validate: validate,
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            agreeToTerms: false,
        },
        onSubmit: async (values) => {
            try {
                const response = await respAPI.createAccount(values?.name?.trim(), values?.email?.trim(), values?.password?.trim())
                if (response?.data?.message === "an email to create an account has been sent.") {
                    navigate(`/email-sent?u=${values?.email}&c=verification`)
                    return;
                }
                setSnackBar({ open: true, severity: 'success', message: response?.data?.message || JSON.stringify(response?.data || 'unexpected response') })
            } catch (error: any) {
                if (error?.response?.data?.message === "User already exists") {
                    setSnackBar({ open: true, severity: 'error', message: t('createAccount.userExists') })
                    return;
                }
                setSnackBar({ open: true, severity: 'error', message: error?.response?.data?.message })
            }
        },
    });

    return (
        <Grid className={styles.grid} flexDirection={'column'} justifyContent={'space-between'} display={'flex'}>
            <Box>
                <HsbShareLogo width={222} />
            </Box>
            <Box>
                <form onSubmit={formik.handleSubmit}>
                    <Grid item xs={12}>
                        <Typography {...constants.pageHeading} className={styles.title} variant="h4">
                            {t('createAccount.title')}
                        </Typography>
                        <Typography  {...constants.pageText} gutterBottom> {t('login.shareShortDescription')}</Typography>
                    </Grid>
                    <Grid item style={{ paddingTop: 10 }} xs={12}>
                        <Box {...constants.vSpacing}><Divider className={styles.divider}>&nbsp;&nbsp;&nbsp;{t('createAccount.signupEmail')}&nbsp;&nbsp;&nbsp;</Divider></Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={0}
                            className={styles.section}>
                            <Grid item xs={12}>
                                <FormControl {...constants.vSpacing} variant="outlined" fullWidth>
                                    <Typography variant="caption" gutterBottom>{t('createAccount.name')}</Typography>
                                    <OutlinedInput required type="text" id="name" onChange={formik.handleChange} value={formik.values.name} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl {...constants.vSpacing} variant="outlined" fullWidth>
                                    <Typography variant="caption" gutterBottom>{t('login.email')}</Typography>
                                    <OutlinedInput required type="email" id="email" onChange={formik.handleChange} value={formik.values.email} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl {...constants.vSpacing} variant="outlined" fullWidth>
                                    <Typography variant="caption" gutterBottom>{t('login.password')}</Typography>
                                    <OutlinedInput required type={validationValues.showPassword ? 'text' : 'password'}
                                        id="password" onChange={formik.handleChange} value={formik.values.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility"
                                                    onMouseDown={(event) => event.preventDefault()}
                                                    onClick={() => handleChange('showPassword')}
                                                >
                                                    {validationValues.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {validationValues.passwordLengthError && formik.values?.name && formik.values?.email ? <FormHelperText style={{ paddingTop: 10 }}>{t('createAccount.passwordLength')}</FormHelperText> : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl {...constants.vSpacing} variant="outlined" fullWidth>
                                    <Typography variant="caption" gutterBottom>{t('createAccount.confirmPassword')}</Typography>
                                    <OutlinedInput required type={validationValues.showConfirmPassword ? 'text' : 'password'}
                                        error={validationValues.confirmPasswordError}
                                        id="confirmPassword" onChange={formik.handleChange} value={formik.values.confirmPassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility"
                                                    onMouseDown={(event) => event.preventDefault()}
                                                    onClick={() => handleChange('showConfirmPassword')}>
                                                    {validationValues.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                    {validationValues.confirmPasswordError ? <FormHelperText style={{ paddingTop: 10 }}>{t('createAccount.passwordsMatch')}</FormHelperText> : null}
                                </FormControl>
                            </Grid>
                            <FormControlLabel control={<Checkbox id="agreeToTerms" onChange={formik.handleChange} checked={formik.values.agreeToTerms} />}
                                label={<div style={{ marginLeft: -5 }}>
                                    <span>
                                        <Typography className={styles.small} variant="caption">{t('createAccount.IAgreeToThe')}&nbsp;</Typography>
                                        <Typography className={styles.small} variant="caption"><Link className={styles.small} to="/terms?tab=terms" target="_blank">{t('createAccount.termsOfService')},&nbsp;</Link></Typography>
                                        <Typography className={styles.small} variant="caption"><Link className={styles.small} to="/terms?tab=privacy" target="_blank">{t('createAccount.privacyPolicy')},&nbsp;</Link></Typography>
                                        <Typography className={styles.small} variant="caption"><Link className={styles.small} to="/terms?tab=cookie" target="_blank">{t('createAccount.cookieUse')}</Link></Typography>
                                    </span>
                                </div>} />
                            {validationValues.agreeToTermsError ? <Grid item xs={12}><FormHelperText style={{ paddingLeft: 15 }}>{t('createAccount.agreeToTermsToContinue')}</FormHelperText></Grid> : null}
                        </Grid>
                    </Grid>
                    <Grid style={{ paddingTop: 10 }} item xs={12}>
                        <div className={styles.section}>
                            <RoundedButton type="submit" variant="contained" >{t('createAccount.createAccount')}</RoundedButton>
                        </div>
                    </Grid>
                    <Grid className={`${styles.account}`} item xs={12}>
                        <Typography variant="caption" gutterBottom>{t('createAccount.alreadyAccount')} <Link to="/login">{t('createAccount.signIn')}</Link></Typography>
                    </Grid>
                </form>
            </Box>
            <Box>
                <HalfFooter />
            </Box>
            <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
        </Grid>
    )
}
