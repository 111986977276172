import { Box } from '@mui/material';
import React, { useEffect,  useRef, useState } from 'react';
import styles from './ButtonGroupIndicator.module.scss';
import theme from 'theme';

export function Indicator({ anchor, side = 'left' }: IndicatorProps) {
    const rect = useRef<Partial<DOMRect>>({ x: side === 'left' ? 0 : window.innerWidth, y: 0, width: 0, height: 0 });
    const barWidth = useRef(0);
    const transition = useRef('');
    const [, setSet] = useState(false);

    useEffect(() => {
        if (!anchor) {
            barWidth.current = 0;
            transition.current = 'inset';
        } else {
            transition.current = barWidth.current !== 0 ? 'inset' : 'inset top bottom';
            let anchorRect = anchor?.getBoundingClientRect();
            rect.current = anchorRect;
            barWidth.current = rect.current.width / 20;
            setSet(true);
        }
    }, [anchor]);

    return (
        <Box
            id="sidebarIndicator"
            className={styles['sidebar-root-container']}
            style={{
                position: 'fixed',
                left: rect.current.x,
                top: rect.current.y,
                right: rect.current.x + rect.current.width ?? 0,
                bottom: rect.current.y + rect.current.height ?? 0,
                height: rect.current.height,
                width: rect.current.width,
            }}>
            <Box
                className={`${styles['sidebar-root-indicator']} ${side === 'left' ? styles['left'] : styles['right']}`}
                style={{
                    background: theme.palette.primary.main,
                    width: barWidth.current,
                }}
            />
        </Box>
    );
}

export interface IndicatorProps {
    anchor: Element;
    side?: 'left' | 'right';
}
