import { Box, CardActionArea, Skeleton, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import WarningIcon from '@mui/icons-material/Warning';
import itemStyles from './ProjectItem.module.scss';
import listStyles from './ProjectPage.module.scss';
import { ProjectState } from '../../types/enums';
import { ProjectStateChip } from '../Home/StateChip';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextSizeWithMax } from 'components/Shared/OneLineTextDiv';
import { useEffect, useRef, useState } from 'react';
import { useContainerDimensions } from 'hooks/useContainerDimensions';
import restAPI from 'services/rest-api';
import ContextualMenu from 'components/Shared/ContextualMenu/ContextualMenu';
import ContextualMenuItem from 'components/Shared/ContextualMenu/ContextualMenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from 'components/Shared/DeleteDIalog/DeleteDialog';
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage';

interface IProjectItem {
    state: ProjectState;
    projectName: string;
    projectId: string;
    teamName: string;
    updatedText: string;
    notifications: number;
    onProjectDelete: (projectId: string) => void;
    id?: string;
    thumbnail?: string;
}

const ProjectItem: React.FC<IProjectItem> = (props: IProjectItem) => {
    let { state, projectName, projectId, teamName, updatedText, onProjectDelete, thumbnail } = props;
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const ref = useRef<HTMLDivElement>();
    const { width } = useContainerDimensions(ref, 50, 50);
    const [image, setImage] = useState(!(thumbnail?.length > 0) ? `./img/emptyproject.svg` : thumbnail);
    const [openDeleteProject, setOpenDeleteProject] = useState(false);
    const [snackBar, setSnackBar] = useState<ISnackBarData>({ open: false, severity: "success", message: "", });

    const handleIssueClick = (e) => {
        e.stopPropagation();
        navigate('/project/' + projectId + '?rpanel=issues');
    };

    const handleProjectClick = (e) => {
        if (projectId) {
            navigate('/project/' + projectId);
            e.stopPropagation();
        }
    };

    useEffect(() => {
        let mounted = true;
        if (!image) {
            restAPI.getProjectThumbnail(projectId).then((dataURI) => {
                if (mounted) setImage(dataURI ?? `./img/emptyproject.svg`);
            }).catch((err) => {
                setImage(`../img/emptyproject.svg`);
            });
        }

        return () => {
            mounted = false;
        };
    }, [image, projectId]);

    const handleDeleteProject = () => {
        restAPI.deleteProject(projectId).then(result => {
            onProjectDelete(projectId);
            setSnackBar({ open: true, severity: "success", message: t("ProjectItem.ProjectDeleteSuccess", { projectName: projectName }) });
        }).catch(error => {
            setSnackBar({ open: true, severity: "error", message: t("ProjectItem.ProjectDeleteFailure") });
        }).finally(() => {
            setOpenDeleteProject(false);
        })
    }

    return (
        <>
            {
                projectName ?
                    <Card className={`${itemStyles['project-item']} ${listStyles['project-item']}`} ref={ref} elevation={2} onClick={handleProjectClick}>
                        <CardActionArea>
                            <CardMedia>
                                <Tooltip title={t('ProjectList.Issues')}>
                                    <WarningIcon className={itemStyles['issues-button']} onClick={handleIssueClick} color="primary" />
                                </Tooltip>
                                <Box style={image?.endsWith('.svg') ? { textAlign: 'center', background: '#e0dfda' } : null}>
                                    <Box
                                        height={194}
                                        width={'100%'}
                                        sx={{
                                            background: `url(${image})`,
                                            backgroundPosition: 'center center',
                                            backgroundSize: image?.endsWith('.svg') ? 'contain' : 'cover',
                                            backgroundRepeat: 'no-repeat',
                                        }}>

                                    </Box>
                                </Box>
                            </CardMedia>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'stretch',
                                    alignContent: 'stretch',
                                    padding: '12px 12px 8px 12px !important',
                                }}>
                                <div className={`${itemStyles.container}`}>
                                    <TextSizeWithMax
                                        text={projectName}
                                        maxWidth={width * 0.8}
                                        fontSize={'1.63rem'}
                                        className={`${itemStyles.title}  ${itemStyles.truncate}`}
                                    />
                                    <ProjectStateChip state={state} />
                                </div>
                                <div className={`${itemStyles.container} ${itemStyles.vertical}`}>
                                    <TextSizeWithMax text={teamName} maxWidth={width * 0.5} className={`${itemStyles.subtitle} ${itemStyles.truncate}`} />
                                    {updatedText && (
                                        <TextSizeWithMax
                                            text={updatedText}
                                            maxWidth={width * 0.5}
                                            className={`${itemStyles.subdate} ${itemStyles.grow} ${itemStyles.right}  ${itemStyles.truncate}`}
                                        />
                                    )}
                                </div>
                            </CardContent>
                        </CardActionArea>
                        {
                            projectId &&
                            <ContextualMenu parent={ref}>
                                <ContextualMenuItem variant='red' onClick={() => setOpenDeleteProject(true)}><DeleteIcon />{t("ProjectItem.Delete")}</ContextualMenuItem>
                            </ContextualMenu>
                        }
                    </Card> :
                    <Skeleton variant='rounded' className={listStyles['project-item-skeleton']}></Skeleton>
            }
            <DeleteDialog
                open={openDeleteProject}
                onSubmit={handleDeleteProject}
                onClose={() => setOpenDeleteProject(false)}
                title={`Delete ${projectName}`}
                description={`Are you sure you want to delete ${projectName}? This can not be reversed.`}
                buttonText={"Delete this project"}
                verification={projectName}
                caseSensitive />
            <SnackBarMessage severity={snackBar.severity} message={snackBar.message} open={snackBar.open} onSetOpen={setSnackBar} />
        </>
    );
};
export default ProjectItem;
