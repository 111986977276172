import constants from '../services/constants';
import { atom } from 'recoil';




const deletedTeamMemberAtom = atom<{userId: string, teamID: string} | null>({
    key: constants.deletedTeamMemberAtom,
    default: null,
});

export default deletedTeamMemberAtom;