import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import React, { useCallback, useState } from 'react'
import SendIcon from '@mui/icons-material/Send'
import { ICommentActions } from './Comments'
import styles from '../IssueDialog.module.scss'


export const CommentInput: React.FC<{ issueId: string } & ICommentActions> = ({ issueId, ...props }) => {
    const [comment, setComment] = useState('');

    const commentTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(e.target.value);
    }

    const addComment = useCallback((event) => {
        if (comment === '') {
            return;
        }

        props.addComment(issueId, comment);
        setComment('');
        event.target.blur();
    }, [props, issueId, comment]);

    const keyDownHandler = useCallback((event) => {
        if (event.key !== 'Enter' || !comment) {
            return;
        }

        addComment(event);
    }, [comment, addComment]);

    return (
        <OutlinedInput maxRows={5} minRows={1} multiline value={comment} onKeyDown={keyDownHandler} className={styles['comment-input']} placeholder='Send Comment...' onChange={commentTextChangeHandler} sx={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: 400 }}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton disabled={comment?.length === 0} sx={{ marginRight: "5px" }} onClick={addComment} onMouseDown={(event) => event.preventDefault()}>
                        <SendIcon sx={{ fontSize: '18px', color: comment?.length === 0 ? "#dddddd" : "#F9AF08" }} />
                    </IconButton>
                </InputAdornment>
            }
        />
    )
}