import React, { useState, useEffect, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ImportSetsPanel.module.scss';
import restAPI from 'services/rest-api';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography, Skeleton } from '@mui/material';
import ContextualMenu from 'components/Shared/ContextualMenu/ContextualMenu';
import ContextualMenuItem from 'components/Shared/ContextualMenu/ContextualMenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import ImportSetDialog from './ImportSetDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Viewer } from 'hsbshareviewer';
import { ImportSetType, getImportSetEntities } from './importSetEntityHelper';
import EditIcon from '@mui/icons-material/Edit';


export interface ImportSetElementProps {
    importSet: any;
    projectId: string;
    selected: boolean;
    open: boolean;
    viewer: Viewer;
    onClose: () => void;
    onClick: (importSet: any) => void;
    onEdit: (importSet: any) => void;
    onImportSetDelete: (issueId: string) => void;
    onImportSetCreated?: (importSet: any) => void;
    onImportSetUpdated?: (importSet: any) => void;
}

export const ImportSetElement: React.FC<ImportSetElementProps> = (props) => {
    let { importSet, projectId, open, onEdit, selected, viewer, onClose, onClick, onImportSetDelete, onImportSetCreated, onImportSetUpdated } = props;
    let { t } = useTranslation('common');

    const [thumbnail, setThumbnail] = useState(null);
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        let mounted = true;
        const entities = getImportSetEntities(viewer, importSet?.modelReferences);
        viewer?.screenCapture.screenshotEntities(entities).then(url => {
            if (mounted) {
                if (!url) {
                    //if there is no screen shot fallback to projectId
                    restAPI.getProjectThumbnail(importSet.projectId).then((result) => {
                        if (mounted) setThumbnail(result);
                    }).catch((err) => {
                        if (mounted) setThumbnail('');
                    });
                    return;
                }
                setThumbnail(url);
            }
        }, error => {
            console.error(`error viewer.screenshotEntities: ${error}`);
            if (mounted) {
                setThumbnail('');
            }
        });
        return () => {
            mounted = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importSet, open]);

    const handleClick = (e:any)=>{
        onClick?.(importSet);
    }


    return (
        <Box onClick={handleClick} className={`${styles['import-set-card']}`}>
            <Box ref={ref} className={`${styles['content']} ${selected ? styles.selected : ''}`} >
                <Box>
                    {thumbnail === null ?
                        <Skeleton variant="rectangular" />
                        : thumbnail.length > 0 ?
                            <img className={styles.thumbnail} src={thumbnail} alt="Thumbnail of importset" />
                            : <Box className={styles.thumbnail}><WarningIcon /></Box>
                    }
                </Box>
                <Box className={`${styles['import-set-right-content']}`}>
                    <Box className={styles['import-set-name-and-type']}>
                        <Typography variant="body1" classes={{ root: styles['import-set-name'] }}>
                            {importSet?.properties?.name?.value}
                        </Typography>
                        <Box>
                            <FontAwesomeIcon className={styles['import-set-share-icon']} size={'xs'} icon={faShareNodes} />
                        </Box>
                        <Typography variant="body2" classes={{ root: styles['import-set-secondary-text'] }}>
                            {ImportSetType?.[importSet?.properties?.hsbType?.key] ? t(`${ImportSetType[importSet.properties.hsbType.key]}`) : t(`ImportSet.Type.Unset`)}
                        </Typography>
                    </Box>
                    <Box className={styles['import-set-info']}>
                        <Typography variant="body2" classes={{ root: styles['import-set-secondary-text'] }}>
                            {importSet?.createdBy?.name ?? t('ImportSetsPanel.Unknown')}
                        </Typography>
                        <FontAwesomeIcon className={styles['import-set-circle-icon']} icon={faCircle} />
                        <Typography variant="body2" classes={{ root: styles['import-set-secondary-text'] }}>
                            {importSet?.references?.length} {t('ImportSetsPanel.Entities')}
                        </Typography>
                    </Box>
                </Box>
                { selected && <ContextualMenu parent={ref}>
                    <ContextualMenuItem onClick={() => onEdit(importSet._id)}><EditIcon/>{t("IssuesPanel.Edit")}</ContextualMenuItem>
                    <ContextualMenuItem variant='red' onClick={() => onImportSetDelete(importSet._id)}><DeleteIcon />{t("IssuesPanel.Delete")}</ContextualMenuItem>
                </ContextualMenu> }
            </Box>
            { selected && <ImportSetDialog onImportSetCreated={onImportSetCreated} onImportSetUpdated={onImportSetUpdated} importSet={importSet} open={open} projectId={projectId} onClose={onClose} viewer={viewer} />}
        </Box>
    );
};
