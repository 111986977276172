import { Box, List, Typography } from '@mui/material';
import Tab from 'components/Shared/Tab';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ImportSetsPanel.module.scss';
import restApi from 'services/rest-api';
import { ImportSetElement } from './ImportSetElement';
import { Viewer } from 'hsbshareviewer';
import { useRecoilState } from 'recoil';
import importSetCreatedAtom from 'atoms/importSetCreatedAtom';
import { getImportSetEntities } from './importSetEntityHelper';

export default function ImportSetsPanel(props: ImportSetsPanelProps) {
    let { open, onClose, projectId, viewer } = props;
    let { t } = useTranslation('common');
    const [currImportSets, setCurrImportSets] = useState<any[]>();
    const [search, setSearch] = useState<string>(null);
    const [clickedImportSet, setClickedImportSet] = useState(null);
    const [importSets, setImportSets] = useState([]);
    const [openEditIssueDialog, setOpenEditIssueDialog] = useState(false);
    const [importSetCreated, setImportSetCreated] = useRecoilState(importSetCreatedAtom);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => loadImportsets(), [projectId, importSetCreated]);

    const loadImportsets = () => {
        let mounted = true;
        restApi.ImportSets.getProjectsImportSets(projectId).then(
            (importSetDB) => {
                if (mounted) {
                    const importSetData = importSetDB ?? [];
                    setCurrImportSets(importSetData);
                    setImportSets(importSetData);
                    if(importSetCreated !==null){
                        const selectedImportSet = importSetData.find(importSet => importSet._id === importSetCreated)
                        setClickedImportSet(selectedImportSet);
                        zoomIn(viewer, selectedImportSet);
                    }
                }
            },
            (error) => {
                if (mounted) {
                    setCurrImportSets([]);
                    setImportSets([]);
                    console.error(`error getting project import sets from db: ${error}`);
                }
            }
        );
        return () => {
            mounted = false;
        };
    };
    const zoomIn = (modelViewer, importSet) => {
        if(!modelViewer || !importSet){
            return;
        }
        const entities = getImportSetEntities(modelViewer, importSet.modelReferences);
        viewer?.setSelection(entities, true, true);
        viewer?._selectionTool?.IsolateSelection();
        viewer?._selectionTool?.ZoomToSelection();
    }

    const handleSearch = (search: string) => {
        setSearch(search);
        const searchResult = importSets.filter((importSet) => {
            return JSON.stringify(importSet).toLowerCase().indexOf(search?.toLowerCase()) >= 0;
        });
        setCurrImportSets(searchResult);
    };

    useEffect(() => {
        if (!(search?.length > 0)) {
            // If no filter and no search set jsut return original array.
            let map = importSets?.map((importSet, index) => {
                return { ...importSet, index: `#${index}` };
            });
            setCurrImportSets(map);
        }
    }, [importSets, search]);

    const handleDeleteImportSet = (importSetId: string) => {
        restApi.ImportSets.deleteImportSet(importSetId).then(
            (result: any) => {
                const importsetDeleted = result.filter((nodeData) => nodeData.itemtype === 'hsbshare.importset')?.[0];
                setCurrImportSets(currImportSets.filter((currImpSet) => currImpSet._id !== importsetDeleted?._id));
            },
            (error) => console.error(error)
        );
    };
    const handleImportSetUpdated = (importSet) => {
        loadImportsets();
    };

    const onEditImportSet = (importSet) => {
        //open import set dialog in edit mode
        setOpenEditIssueDialog(true);
    };

    const handleClose = () => {
        setOpenEditIssueDialog(false);
        setClickedImportSet(null);
        setImportSetCreated(null);
    };
    const onOpenClickHandler = async (importSet) => {
        if(clickedImportSet?._id === importSet._id && !openEditIssueDialog){
            return setClickedImportSet(null);
        }
        setClickedImportSet({ ...importSet });
        zoomIn(viewer, importSet);
    };

    return (
        <Tab
            open={open}
            direction={'right'}
            onClose={onClose}
            title={t('ImportSetsPanel.Title')}
            onSearch={handleSearch}
            classes={{ paper: styles.sidePanel }}>
            {currImportSets?.length > 0 ? (
                <List classes={{ root: styles.list }}>
                    {currImportSets
                        .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
                        .map((importSet) => (
                            <ImportSetElement
                                onClick={onOpenClickHandler}
                                selected={(importSet._id === clickedImportSet?._id)}
                                open={importSet._id === clickedImportSet?._id && openEditIssueDialog}
                                onClose={handleClose}
                                onEdit={importSet._id === clickedImportSet?._id && onEditImportSet}
                                key={importSet._id}
                                importSet={importSet}
                                projectId={projectId}
                                viewer={viewer}
                                onImportSetUpdated={handleImportSetUpdated}
                                onImportSetDelete={importSet._id === clickedImportSet?._id && handleDeleteImportSet}
                            />
                        ))}
                </List>
            ) : (
                <Box className={styles['no-sets']}>
                    <Typography variant="h5">{t('ImportSetsPanel.NoSets')}</Typography>
                    <Typography variant="caption">{t('ImportSetsPanel.NoSetsHelp')}</Typography>
                </Box>
            )}
        </Tab>
    );
}

export interface ImportSetsPanelProps {
    open: boolean;
    projectId: string;
    onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
    importSets?: any[];
    selection?: any[];
    viewer: Viewer;
}
