import React, { ForwardedRef, PropsWithChildren, useState,useEffect } from "react";
import { EventEmitter } from 'events';
import {v4 as uuidv4} from 'uuid';

interface ViewerCanvasProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    renderEventEmitter: EventEmitter;
}


const ViewerCanvas = React.forwardRef((props: PropsWithChildren<ViewerCanvasProps>, ref: ForwardedRef<HTMLDivElement>) => {
    let { renderEventEmitter, children,...divProps} = props;
  
    const [renderTarget, setRenderTarget] = useState(false);

    const handleRender =(e:any)=>{
        setRenderTarget(!renderTarget);
    }

    useEffect(() => {
        
        renderEventEmitter?.addListener('render',handleRender)

        return () => {
            renderEventEmitter?.removeListener('render',handleRender)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderEventEmitter])


    return (
        <div id={`ViewerCanvas-${uuidv4()}`} ref={ref} {...divProps} style={{backgroundColor: '#e0dfda'}} tabIndex={1}>
            {children}
        </div>
    );
})
export {ViewerCanvas};