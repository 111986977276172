import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { FormInputImageProps as FormInputAvatarProps } from "./FormInputProps";
import { Avatar, Button, DialogActions, DialogContent, Slider, Snackbar, Alert, Skeleton, DialogTitle, IconButton } from "@mui/material";
import { Edit, Delete, RotateRight, RotateLeft, Close } from "@mui/icons-material";
import styles from "./FormInput.module.css"
import { InputLabel } from "@mui/material";
import AvatarEditor from 'react-avatar-editor'
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import UserAvatar from "../AvatarObject";
import DialogBox from "../DialogBox";

export const FormInputAvatar = ({ name, control, size, label, setValue, getValues, onChange, disabled }: FormInputAvatarProps) => {

    const inputButton = useRef<HTMLInputElement | null>(null);
    // const [value, setAvatar] = React.useState<{ avatarURL?: string, bgColor: string, avatarLetter: string }>();
    const handleEdit = () => {
        if (inputButton.current?.click) {
            inputButton.current.click();
        }
    }

    const avatarEditorRef = React.useRef<AvatarEditor>(null);
    const [imageFile, setImage] = React.useState<File | null>(null);
    const [editImage, setEditImage] = React.useState<boolean>(false)
    const [scale, setScale] = React.useState<number>(1.2)

    const [rotation, setRotation] = React.useState<number>(0)

    const handleDelete = (value: UserAvatar) => {
        if (inputButton.current)
            inputButton.current.value = "";
        let out: UserAvatar = {
            letter: value.letter,
            color: value.color,
            URI: ''
        }
        setValue(name, out, { shouldDirty: true });
        if (onChange) {
            onChange(out);
        }
    }

    const handleUpload = (event: any) => {
        if (event?.target?.files && event.target.files.length > 0) {
            // File size
            let file = event.target.files[0] as File;
            const kbLimit = 500;
            // check bytesize vs limit in kb
            if (file.size > 1024 * kbLimit) {
                setSnackbarMessage("The image was too big! (max 500Kb)");
                setShowSnackbar(true);
                return;
            }

            // File Type
            const fileType = file.type;
            const validImageTypes = ['image/jpeg', 'image/png'];
            if (!validImageTypes.includes(fileType)) {
                setSnackbarMessage("The file was not an image! (.jpeg/.png)");
                setShowSnackbar(true);
                return;
            }

            setImage(file);
            setEditImage(true);
        }
    }

    const handleImageSaved = (value: UserAvatar) => {
        if (!avatarEditorRef)
            return;
        const dataUrl = avatarEditorRef.current?.getImageScaledToCanvas().toDataURL();
        if (!dataUrl)
            return;
        setEditImage(false);
        setScale(1);
        setRotation(0);
        let out: UserAvatar = {
            letter: value.letter,
            color: value.color,
            URI: dataUrl
        }
        setValue(name, out, { shouldDirty: true });
        if (onChange) {
            onChange(out);
        }
    }

    const handleCancel = () => {
        setEditImage(false);
        setScale(1);
        setRotation(0);
    }

    const handleScaling = (event: Event, value: number | number[], activeThumb: number) => {
        if (!Array.isArray(value))
            setScale(value);
        event.preventDefault();
    }

    const handleRotation = (event: Event, value: number | number[], activeThumb: number) => {
        if (!Array.isArray(value))
            setRotation(value);
        event.preventDefault();
    }

    // ----Snackbar----
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
    const handleClose = (
        event: Event | React.SyntheticEvent | React.MouseEvent,
        reason?: string,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    const action = (
        <React.Fragment>
            <Button color="error" size="small" onClick={handleClose}>
                Ok
            </Button>

        </React.Fragment>
    );

    return (
        <div style={{ display: 'inline-block' }}>
            {label &&
                <InputLabel className={styles.label}>{label}</InputLabel>}
            <Controller
                name={name}
                control={control}

                render={({
                    field: { value }
                }) => (
                    <div className={styles.container} style={{ height: size || "64px", width: size || "64px" }}>
                        {value ?
                            <Avatar className={styles.Avatar} src={value?.URI} sx={{ backgroundColor: value?.color }}>
                                {value?.letter}
                            </Avatar> :
                            <Skeleton className={styles.Avatar} variant="circular" width={size || "64px"} height={size || "64px"} />}
                        {!disabled &&
                            <button type="button" onClick={() => handleEdit()} className={`${styles.AvatarButtonLeft} ${styles.AvatarButton}`} aria-label="Edit the avatar">
                                <Edit />
                            </button>}
                        {!disabled &&
                            <button type="button" onClick={() => handleDelete(value)} className={`${styles.AvatarButtonRight} ${styles.AvatarButton}`} aria-label="Clear the avatar">
                                <Delete />
                            </button>}
                        {!disabled &&
                            <input ref={inputButton} type="file" accept="image/png, image/jpeg" style={{ display: "none" }} onChange={handleUpload} onClick={() => { if (inputButton.current) inputButton.current.value = ''; }} />}
                        {!disabled &&
                            <DialogBox aria-labelledby="edit-image-upload" open={editImage} onClose={handleCancel}>
                                <DialogTitle>
                                    Edit Image Upload
                                    <IconButton onClick={handleCancel}>
                                        <Close />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <AvatarEditor
                                        ref={avatarEditorRef}
                                        image={imageFile || ''}
                                        width={300}
                                        height={300}
                                        border={10}
                                        color={[255, 255, 255, 0.6]} // RGBA
                                        rotate={rotation * -1}
                                        scale={scale}
                                        borderRadius={300}
                                        style={{ position: 'relative', left: 'calc(50% - 160px)' }}
                                    />
                                    <div className={styles.imageControls}>
                                        <ZoomOutIcon />
                                        <Slider key={'scaleSlider'} aria-label="Image Scale" value={scale} onChange={handleScaling} min={1}
                                            max={2.5} step={0.01}></Slider>
                                        <ZoomInIcon />
                                    </div>
                                    <div className={styles.imageControls}>
                                        <RotateRight />
                                        <Slider key={'rotationSlider'} aria-label="Image Rotate" value={rotation} onChange={handleRotation} min={-180}
                                            max={180} step={-1}></Slider>
                                        <RotateLeft />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button type="submit" variant='contained' onClick={() => { handleImageSaved(value) }}>Submit</Button>
                                </DialogActions>
                            </DialogBox>}
                    </div>
                )}
            />
            <Snackbar
                color='error'
                open={showSnackbar}
                onClose={handleClose}
                autoHideDuration={6000}
                action={action}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};