import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import constants from "../services/constants";
import { IShareSession } from "../types/IShareSession";
import restAPI from "services/rest-api";
import config from "services/config";

/**
 * The useSession hook provides functionality regarding anything to do with the session.
 *
 * @returns methods - individual functions to manage the session state. {@link useSessionReturn}
 *
 * @example
 * ```tsx
 * function App() {
 *   const { isGuest, isAuth } = useSession();
 *   return (
 *     <div>
 *          {isGuest() && <div> A guest is logged in</div>}
 *          {isAuth() && <div> A user is logged in</div>}
 *     </div>
 *   );
 * }
 * ```
 * @example
 * ```tsx
 * function App() {
 *   const { logout } = useSession();
 *   return (
 *     <button onClick={()=>logout()}>
 *          Click here to log out.
 *     </button>
 *   );
 * }
 * ```
 * @example
 * ```tsx
 * function App() {
 *   const { getSession, startGuestSession } = useSession();
 *   const [session, setSession] = useState();
 *
 *   useEffect(()=>{
 *      // If no session
 *      if(!getSession()){
 *          // Try to login as guest
 *          startGuestSession().then((result)=>{
 *              setSession(result);
 *          })
 *      }
 *   })
 *   return (
 *     <div>
 *         Current session name is {session?.name}
 *     </div>
 *   );
 * }
 * ```
 */
export default function useSession(): useSessionReturn {
  const [cookies, setCookie, removeCookie] = useCookies([constants.authCookie]);
  const navigate = useNavigate();

  /**
   * Method to return the current active session in the cookies
   * @returns The current session set in the cookies
   */
  const getSession = (): IShareSession => {
    return cookies[constants.authCookie];
  };

  /**
   * Sets the session in the cookies to the given one.
   * @param session The desired new session
   * @param newDate (Optional) the new expiry date for the given session
   */
  const setSession = (session: IShareSession, newDate?: Date) => {
    if (!session) {
      throw new TypeError(
        "useSession::setSession(): Provided session can not be null!",
        { cause: { code: "NullReference", values: session } }
      );
    }
    storeSession(session, newDate ?? new Date(session.exp));
  };

  /**
   * Logs the user out of the current session
   * @param redirect (Optional, default = true) Whether upon logout the user should be redirected to the homepage.
   */
  const logout = (redirect: boolean = true) => {
    removeCookie(constants.authCookie, { path: "/" });
    if (redirect) navigate("/login");
  };

  /**
   * Starts up a guest session.
   * @returns Promise which resolves if logged in as a user, reject otherwise
   */
  const startGuestSession = () => {
    let session = getSession();
    if (!session) {
      //login in as guest
      return restAPI
        .login(config.guestUser, config.guestPassword)
        .then((response) => {
          session = response?.data?.user;
          if (!session)
            return Promise.reject(
              "useSession::startGuestSession(): Could not log in as guest"
            );
          setSession(session);
          return Promise.resolve(session);
        })
        .catch((err) => {
          console.error(err);
          return Promise.reject("Could not start guest session!");
        });
    } else if (session.email === config.guestUser)
      return Promise.resolve(session);

    return Promise.reject("Another session is already active");
  };

  /**
   * Stores the given session in the cookies.
   * @param session The session to set in the cookies
   * @param expires (Optional) The preferred expiry date, if not given the cookie will expire when the session ends. Different behavior per browser/platform.
   */
  const storeSession = (session: IShareSession, expires?: Date) => {
    if (!session?.refreshToken) {
      return;
    }
    let options: any = { path: "/", sameSite: "lax" };
    if (expires) {
      options.expires = expires;
    }
    setCookie(constants.authCookie, session, options);
  };

  /**
   * Check whether the current session is authorized
   * @returns True if the user is logged in
   * @example
   * ```tsx
   * function App() {
   *   const {  isAuth } = useSession();
   *   return (
   *     <div>
   *          {isAuth() && <div> A user is logged in</div>}
   *     </div>
   *   );
   * }
   * ```
   */
  const isAuth = () => {
    return cookies[constants.authCookie]?.refreshToken !== undefined;
  };

  /**
   * Checks whether a guest session is active
   * @returns True if the current session is a guest session, false otherwise
   */
  const isGuest = () => {
    return cookies[constants.authCookie]?.email === config.guestUser;
  };

  return { getSession, setSession, logout, startGuestSession, isAuth, isGuest };
}

/**
 * Return type for {@link useSession}
 */
export interface useSessionReturn {
  /**
   * Method to return the current active session in the cookies
   * @returns The current session set in the cookies
   * @example
   * ```tsx
   * function App() {
   *   const { getSession, startGuestSession } = useSession();
   *   const [session, setSession] = useState();
   *
   *   useEffect(()=>{
   *      // If no session
   *      if(!getSession()){
   *          // Try to login as guest
   *          startGuestSession().then((result)=>{
   *              setSession(result);
   *          })
   *      }
   *   })
   *   return (
   *     <div>
   *         Current session name is {session?.name}
   *     </div>
   *   );
   * }
   * ```
   */
  getSession(): IShareSession;
  /**
   * Sets the session in the cookies to the given one.
   * @param session The desired new session
   * @param newDate (Optional) the new expiry date for the given session
   */
  setSession: (session: IShareSession, newDate?: Date) => void;
  /**
   * Logs the user out of the current session
   * @param redirect (Optional, default = true) Whether upon logout the user should be redirected to the homepage.
   * @example
   * ```tsx
   * function App() {
   *   const { logout } = useSession();
   *   return (
   *     <button onClick={()=>logout()}>
   *          Click here to log out.
   *     </button>
   *   );
   * }
   * ```
   */
  logout: (redirect?: boolean) => void;
  /**
   * Starts up a guest session.
   * @returns Promise which resolves if logged in as a user, reject otherwise
   * @example
   * ```tsx
   * function App() {
   *   const { getSession, startGuestSession } = useSession();
   *   const [session, setSession] = useState();
   *
   *   useEffect(()=>{
   *      // If no session
   *      if(!getSession()){
   *          // Try to login as guest
   *          startGuestSession().then((result)=>{
   *              setSession(result);
   *          })
   *      }
   *   })
   *   return (
   *     <div>
   *         Current session name is {session?.name}
   *     </div>
   *   );
   * }
   * ```
   */
  startGuestSession: () => Promise<IShareSession>;

  /**
   * Check whether the current session is authorized
   * @returns True if the user is logged in
   * @example
   * ```tsx
   * function App() {
   *   const {  isAuth } = useSession();
   *   return (
   *     <div>
   *          {isAuth() && <div> A user is logged in</div>}
   *     </div>
   *   );
   * }
   * ```
   */
  isAuth: () => boolean;
  /**
   * Checks whether a guest session is active
   * @returns True if the current session is a guest session, false otherwise
   * @example
   * ```tsx
   * function App() {
   *   const { isGuest } = useSession();
   *   return (
   *     <div>
   *          {isGuest() && <div> A guest is logged in</div>}
   *     </div>
   *   );
   * }
   * ```
   */
  isGuest: () => boolean;
}
