
var convert = require('convert-units');


// Sadly this does not matter much since the #types/convert-units is out of date.
// type UnitType<T> =
//     T extends 'volume' ? convert.Volume :
//     T extends 'area' ? convert.Area :
//     T extends 'length' ? convert.Distance :
//     T extends 'mass' ? convert.Mass :
//     never
export interface FormattingProps {
    from: string,
    to: string,
    measure: string,
    exclude: string[],
    cutOffNumber: number,
}

export class Formatting {


    private _unitSettings: any;
    public get unitSettings(): any {
        return this._unitSettings;
    }
    public set unitSettings(v: any) {
        this._unitSettings = v;
    }

    public format(value, options?: Partial<FormattingProps>) {
        
            let measure = options?.measure ?? 'length';
            let from = options?.from ?? this.getDefaultForMeasure(measure)
            let to = options?.to ?? this.unitSettings?.[measure];
            try {
            let output = convert(value);
            output = output.from(from)
           
            if (to) {
                return output.to(to).toFixed(this.unitSettings.decimals) + ' ' + to;
            }
            output = output.toBest({ system: this.unitSettings.system.toLowerCase(), cutOffNumber: options?.cutOffNumber ?? 1, exclude: options?.exclude ?? [] });
            return output.val.toFixed(this.unitSettings.decimals) + ' ' + output.unit;
        }
        catch (err) {
            console.warn(`Could not convert ${value}! Tried to convert from ${from} to ${to}.`)
            return value;
        }
    }

    private getDefaultForMeasure(area) {
        switch (area) {
            case 'area':
                return 'mm2'
            case 'volume':
                return 'mm3'
            case 'weight':
                return 'kg'
            default:
                return 'mm'
        }
    }
}

const formatter = new Formatting();
export default formatter;
