import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { FormInputFileProps } from "./FormInputProps";
import styles from './FormInput.module.css';
import { InputLabel, Paper, Typography, Box, Snackbar, Alert, FormHelperText } from "@mui/material";

export const FormInputFile: React.FC<FormInputFileProps> = ({
  name,
  control,
  label,
  variant,
  fileTypes,
  setValue,
  onChange,
  rules,
  classes,
  helperText,
  helperIcon,
  disabled,
  multiple
}) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const enterCounter = useRef(0);
  const [isDraggedUpon, setIsDraggedUpon] = useState(false);
  const inputButton = useRef(null);

  const handleUploadFile = (event: any) => {
    if (event?.target?.files?.length > 0) {
      if (multiple) {
        const files = Array.from(event.target.files).filter((fileData: any) => {
          let dotIndex = fileData.name.lastIndexOf('.');
          const fileType = fileData.name.substring(dotIndex);
          return !fileTypes || fileTypes?.includes(fileType);
        });
        if (files.length > 0) {
          setValue?.(name, files, { shouldDirty: true });
          onChange?.(files);
        }
      } else {
        let file = event.target.files[0] as File;
        if (file) {
          handleFile(file);
        }
      }

      //Reset file input
      event.target.value = null;
    }
  }

  const handleDrop = (e: any) => {
    handleUploadFile(e);
    e.preventDefault();
    enterCounter.current = 0;
    setIsDraggedUpon(false);
  }

  const handleFile = (file: File) => {
    let dotIndex = file.name.lastIndexOf('.');
    const fileType = file.name.substring(dotIndex);
    if (fileTypes && !fileTypes.includes(fileType)) {
      setShowSnackbar(true);
    } else {
      setValue?.(name, file, { shouldDirty: true });
      onChange?.(file);
    }
  }

  const handleDragOver = (e: any) => {
    e.preventDefault();
  }

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    ++enterCounter.current;
    if (!isDraggedUpon) {
      setIsDraggedUpon(true);
    }
  }

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    --enterCounter.current;
    if (enterCounter.current === 0) {
      setIsDraggedUpon(false);
    }
  }

  const handleClick = (e: any) => {
    if (inputButton.current?.click)
      inputButton.current.click();
  }

  const handleSnackbarClose = (e: any) => {
    setShowSnackbar(false);
  }

  return (
    <div className={`${styles.formInput}`}>
      {label &&
        <InputLabel className={styles.label} style={{ display: "inline" }}>{label + (fileTypes ? (" (" + fileTypes.toString() + ")") : "")}</InputLabel>}
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({
          field: { value },
          fieldState: { error }
        }) => {
          return (
            <Box className={`${styles.fileInputContainer} ${classes?.['input-container']}`}>
              <Paper variant={variant ?? 'outlined'} classes={{ root: `${classes?.container} ${styles.fileInput} ${isDraggedUpon ? styles.fileInputDropping : ''} ${!!error ? styles.fileInputError : ''}` }}>
                <Box className={`${styles.fileInputBox} ${classes?.['file-input-container']}`}
                  onDrop={handleDrop}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                  onClick={handleClick}>
                  {helperText ?
                    <Typography>
                      {helperText}
                    </Typography> :
                    <Typography className={styles.fileName}>
                      {value?.name}
                    </Typography>}
                  {helperIcon ?? null}
                </Box>
              </Paper>
              {!!error &&
                <FormHelperText classes={{ root: styles.fileHelperText }}>
                  {error.message}
                </FormHelperText>}
              <input type="file"
                ref={inputButton}
                style={{ display: 'none' }}
                id="model"
                name="model"
                onChange={handleUploadFile}
                accept={fileTypes?.toString()}
                disabled={disabled}
                multiple={multiple} />
              <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                  {"File Was the wrong type! Please upload type: " + fileTypes?.toString()}
                </Alert>
              </Snackbar>
            </Box>
          )
        }}
      />
    </div >
  );
};