import React from 'react'
import { IComment } from './Comments'
import { Avatar, ListItemButton, Stack, Typography } from '@mui/material'
import DisplayPropertyChange from './DisplayPropertyChange'
import theme from 'theme'
import { onlyFirstLetterCapitalized } from 'services/stringUtils'


export default function CommentItem(props: React.PropsWithChildren<IComment & { scrollToItem: boolean }>) {
  return (
    <ListItemButton style={{ width: '100%', padding: '10px 15px 10px 5px' }}>
      <Stack direction='row' style={{ width: 'inherit' }}>
        <Stack style={{ height: 'inherit' }} direction='column'>
          {props?.avatar ?
            <Avatar src={props?.avatar?.URI} sx={{ width: 56, height: 56, backgroundColor: props?.avatar?.backgroundColor }}>
              {onlyFirstLetterCapitalized(props?.user)}
            </Avatar>
            : <Avatar src={null} sx={{ width: 56, height: 56 }} />}
          <hr style={{ height: '100%', width: 0, marginBottom: '-1px', border: `1px solid #D8D8D8` }} />
        </Stack>
        <Stack direction='column' style={{ marginLeft: '20px', width: 'calc(100% - 77px)' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
            <Typography variant='h5' style={{ padding: '0px', margin: '0px', fontSize: "16px", lineHeight: "24px" }}>{props.user}</Typography>
            <Typography sx={{ color: "rgb(0,0,0,.4)", fontWeight: '400', lineHeight: '1', fontSize: "14px", textTransform: 'capitalize' }} style={{ color: `${theme.palette.text.disabled}` }}>{props.workGroupFirstUpdate}</Typography>
          </Stack>
          {props.comment && <Typography sx={{ marginBottom: "15px" }} style={{ overflowY: 'auto', color: theme.palette.text.secondary }}>{props.comment}</Typography>}
          <DisplayPropertyChange changes={props.changes} />
          {props?.images?.map((imagePath: any, index: number) =>
            <img key={`commentImg${props.user}${index}${imagePath}`} alt="attachment" src={imagePath} style={{ objectFit: 'cover', height: '194px', width: '100%', padding: '10px 15px 0px 0px' }} />
          )}
        </Stack>
      </Stack>
    </ListItemButton>
  )
}

