import styles from 'styles/HomeLayout.module.scss';
import NavBar from 'components/Shared/NavBar';
import SideNavBar from 'components/Shared/SideNavBar';
import { Box } from '@mui/material';
import HomeController from 'components/Home/HomeController';
import TeamsController from 'components/Teams/TeamsController';
import { useParams, useNavigate } from 'react-router-dom';
import ProjectsController from 'components/Projects/ProjectsController';
import { useEffect } from 'react';
import AnnouncementBar from 'components/Modals/AnnouncementBar/AnnouncementBar';

const controllers = ['home', 'teams', 'projects', 'stacking'];

function toTitleCase(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
}

export default function Home() {
    const { component } = useParams();
    const navigate = useNavigate();
    if ((component?.length > 0)  && !controllers.includes(component?.toLowerCase())) {
        navigate('/404', { replace: true });
    }

    const sideNavBar = (
        <div style={{ width: 75, height: '100%' }}>
            <SideNavBar />
        </div>
    );

    const getComponent = () => {
        switch (component) {
            case 'teams':
                return <TeamsController />;
            case 'projects':
                return <ProjectsController />;
            default:
                return <HomeController />;
        }
    };

    useEffect(() => {
        if (component?.length > 0) document.title = `${toTitleCase(component)} | hsbShare`;
        return () => {
            document.title = `hsbShare | Flexible Offsite Construction Software`;
        };
    }, [component]);

    return (
        <Box className={styles.contentLayoutStyle}>
            <Box sx={{ gridArea: 'header' }}>
                <NavBar />
            </Box>
            <Box sx={{ gridArea: 'sidebar' }}>{sideNavBar}</Box>
            <Box className={styles.component}>{getComponent()}</Box>
            <AnnouncementBar/>
        </Box>
    );
}
