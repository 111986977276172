import { Grid } from '@mui/material'
import MailSent from '../components/Password/MailSent'
import styles from '../styles/MailSentPage.module.css'

export default function MailSentPage() {
    return (
        <Grid className={styles.grid} container>
            <Grid item xs={12} sm={12} md={5}>
                <div className={styles.loginSidePanel}>
                    <MailSent />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7} className={`${styles.imageSidePanel} ${styles.responsive} `}>
                <div className={styles.clipi} style={{ position: 'relative', height: '100vh' }}>
                </div>
            </Grid>
        </Grid>
    )
}