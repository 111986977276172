import { MenuItem, Select, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Check from '@mui/icons-material/Check'

const StyledSelect = styled(Select)(({ theme }) => ({
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
    fontSize: '20px',
    MenuProps: {
        border: '1px solid #EEEEEE',
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSelect-icon': {
        color: 'rgba(0,0,0,0.87)'
    }
}))

const checkboxStyle = {
    fontSize: "large",
    marginRight: '5px'
}
const menuTextStyle = {
    paddingLeft: '5px',
    marginRight: '50px'
}

const ActivitySelect: React.FC<{ activity: string }> = ({ activity }) => {
    const { t } = useTranslation('common')
    const [selectedActivity, setSelectedActivity] = React.useState(activity);

    const handleChange = (event: any) => {
        setSelectedActivity(event.target.value);
    };

    const render = (selected: any) => {
        if (typeof selected !== 'string') {
            return null;
        }
        const result = t(selected as string)
        if (typeof result !== 'string') {
            return null;
        }
        return (<div>{result}</div>)
    }

    return (
        <StyledSelect
            value={selectedActivity}
            onChange={handleChange}
            renderValue={render}
        >
            <MenuItem key={'Activity.CreatedByMe'} value={'Activity.CreatedByMe'} disableRipple>
                {selectedActivity === 'Activity.CreatedByMe' ? <Check sx={checkboxStyle} /> : <Check sx={{ visibility: 'hidden' }} />}
                <span style={menuTextStyle}>{t('Activity.CreatedByMe')}</span>
            </MenuItem>
            <MenuItem key={'Activity.AssignedToMe'} value={'Activity.AssignedToMe'} disableRipple>
                {selectedActivity === 'Activity.AssignedToMe' ? <Check sx={checkboxStyle} /> : <Check sx={{ visibility: 'hidden' }} />}
                <span style={menuTextStyle}>{t('Activity.AssignedToMe')}</span>
            </MenuItem>
            <MenuItem key={'Activity.LatestActivity'} value={'Activity.LatestActivity'} disableRipple>
                {selectedActivity === 'Activity.LatestActivity' ? <Check sx={checkboxStyle} /> : <Check sx={{ visibility: 'hidden' }} />}
                <span style={menuTextStyle}>{t('Activity.LatestActivity')}</span>
            </MenuItem>
        </StyledSelect>
    )
}

export default ActivitySelect;