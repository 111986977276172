import Tab from 'components/Shared/Tab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConsoleTree from './ConsoleTree';
import { NodeData } from './NodeData';
import styles from './HierarchyPanel.module.scss'

export default function HierarchyPanel(props: Partial<HierarchyPanelProps>) {
    let { open, onClose, projectNode, onVisibilityToggle, onSelection, onNodeHover, selected, onIsolation, isolatedHandle } = props
    const { t } = useTranslation('common');
    const [searchTerm, setSearchTerm] = useState('');
    const [scrollTop, setScrollTop] = useState(0);

    const handleSearch = (value: string) => {
        setSearchTerm(value);
    }

    return (
        <Tab
            open={open}
            direction={'left'}
            onClose={onClose}
            title={t("ConsolePanel.Console")}
            classes={{ paper: styles.panel }}
            onSearch={handleSearch}
            scrollTop={scrollTop}
        >
            {projectNode?.nodeModel &&
                <ConsoleTree
                    root={projectNode.nodeModel.ModelRoot}
                    onNodeSelect={onSelection}
                    onNodeToggle={onVisibilityToggle}
                    onNodeHover={onNodeHover}
                    setScroll={setScrollTop}
                    selected={selected}
                    searchTerm={searchTerm}
                    onIsolation={onIsolation}
                    isolatedHandle={isolatedHandle}
                />
            }
        </Tab>
    )
}


export interface HierarchyPanelProps {
    /**
     * Sets the tab to open or closed
     */
    open: boolean;
    /**
     * Method is invoked whenever this tab is closed
     */
    onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
    /**
     * The root node of the project
     */
    projectNode: any;
    /**
     * Method is invoked whenever the visibility of a node is toggled
     * @param node The node that was toggled
     * @param visible Whether the node was toggled visibile or invisible
     */
    onVisibilityToggle: (entities: any, visible: boolean) => void;
    /**
     * Method is invoked whenever a node is hovered over
     * @param node The node that was toggled
     * @param visible Whether the node was toggled visibile or invisible
     */
    onNodeHover: (entities: any, hover: boolean) => void;
    /**
     * Method is invoked whenever a node is selected.
     * @param entities The handles of the entities that were selected
     * @param root The root node of the selected entities.
     */
    onSelection: (entities, root) => void;

    selected?: any[];
    onIsolation: (entities: NodeData, isolated: boolean) => void;
    isolatedHandle: string;
}