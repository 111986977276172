
import Login from '../components/Login/Login'
import Grid from '@mui/material/Grid';
import styles from '../styles/LoginPage.module.scss'

import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useSession from 'hooks/useSession';

export default function LoginPage() {
    const {getSession, isGuest} = useSession();
    const navigate = useNavigate();
    useEffect(() => {
        let session = getSession();
        if (session && !isGuest()) {
            navigate('/');
        }
    })


    return (
        <Grid className={styles.grid} container>
            <Grid item xs={12} sm={12} md={5}>
                <div className={styles.loginSidePanel}>
                    <Login />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7} className={`${styles.imageSidePanel} ${styles.responsive} `}>
                <video preload={'true'} autoPlay muted loop className={styles.clipi}>
                    <source src='../img/login-video.mov' type='video/mp4'></source>
                    Your browser does not support the video.
                </video>
            </Grid>
        </Grid>
    )
}
