import { Box, Switch } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputSwitchProps } from "./FormInputProps";
import styles from './FormInput.module.css';
import { InputLabel } from "@mui/material";

export const FormInputSwitch = ({
    name,
    control,
    label,
    onChange: onChangeProps,
    classes
}: FormInputSwitchProps) => {
    return (
        <Box className={`${classes?.container}`} style={{ display: 'inline-block' }}>
            {label &&
                <InputLabel className={styles.label}>{label}</InputLabel>}
            <Controller
                name={name}
                control={control}
                render={({
                    field: { onChange, value } }) => (
                    <Switch
                        checked={value}
                        onChange={(e: any) => { onChange(e); onChangeProps(e) }}
                    />
                )}
            />
        </Box>
    );
};