import { Box } from '@mui/system';
import React, { useRef, useState } from 'react';
import styles from './FeedbackDialog.module.scss';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { faMeh } from '@fortawesome/free-solid-svg-icons';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import restAPI from 'services/rest-api';
import useSession from 'hooks/useSession';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

enum Mood {
    Happy = 'Happy',
    Meh = 'Meh',
    Sad = 'Sad',
}

interface FeedbackForm {
    mood: 'happy' | 'meh' | 'sad' | 'none';
    feedback: string;
    token: string;
}

const schema = yup.object().shape({
    mood: yup.mixed<Mood>().oneOf(Object.values(Mood)).required(),
    feedback: yup.string().notRequired(),
    token: yup.string().min(1).required(),
}).required();

function FeedbackDialog() {
    const { getSession } = useSession();
    const { t } = useTranslation('common');
    const { control, reset, handleSubmit, setValue, getValues } = useForm<FeedbackForm>({
        defaultValues: {
            mood: 'none',
            feedback: '',
            token: null,
        },
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    });
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState<'input' | 'verification'>('input');
    const refRecaptcha = useRef(null);
    const [mood, setMood] = useState<'happy' | 'meh' | 'sad' | 'none'>('none');
    const handleOpenClick = (e: any) => {
        setOpen(true);
    };

    const handleMoodChange = (e: any, value: 'happy' | 'meh' | 'sad' | 'none') => {
        setValue('mood', value ?? 'none');
        setMood(value ?? 'none');
    };

    const handleValidSubmit = (e: any) => {
        let out = {
            user: getSession().email,
            location: window.location.toString(),
            userAgent: navigator.userAgent,
            mood: e.mood,
            feedback: e.feedback,
        };
        restAPI.sendFeedback(out).catch((err) => {
            console.error(err);
        }).finally(() => {
            handleReset();
        });
    };

    const handleReset = () => {
        reset();
        setMood('none');
        setStep('input');
        setOpen(false);
        refRecaptcha.current?.reset();
    };

    const validMoodValue = () => {
        return ['happy', 'meh', 'sad'].includes(getValues()?.mood);
    };

    const handleCaptcha = (token: string) => {
        setValue('token', token);
        if (token) {
            handleValidSubmit(getValues());
        }
    };

    const handleForward = () => {
        setStep('verification');
    };

    return (
        <Box id="feedback-dialog-root" className={`${styles['dialog-root']} ${open ? styles['dialog-open'] : styles['dialog-closed']}`}>
            {open && <Box id="backdrop" onClick={handleReset} className={styles['dialog-backdrop']} />}
            <Button id="feedback-dialog-button" classes={{ root: styles['dialog-button'] }} variant="contained" onClick={handleOpenClick}>
                <FontAwesomeIcon icon={faLightbulb} />
                <Typography classes={{ root: styles['dialog-button-text'] }}>{t('Feedback.Title')}</Typography>
            </Button>

            <Paper id="feedback-dialog-content" classes={{ root: styles['dialog-content'] }}>
                <form onSubmit={handleSubmit(handleValidSubmit)}>
                    <Box className={`${styles['dialog-input']} ${step === 'input' ? styles['visible'] : styles['invisible']}`}>
                        <Typography className={styles['dialog-title']}>{t('Feedback.Session')}</Typography>
                        <Typography className={styles['dialog-subtitle']}>{t('Feedback.Valuable')}</Typography>
                        <ToggleButtonGroup
                            classes={{ root: styles['dialog-mood-group'] }}
                            exclusive
                            value={mood}
                            id="feedback-dialog-content-state"
                            className={styles['dialog-state']}
                            onChange={handleMoodChange}>
                            <ToggleButton
                                value="sad"
                                classes={{ root: styles['dialog-mood-toggle'], selected: styles['dialog-mood-toggle-selected'] }}>
                                <FontAwesomeIcon icon={faFrown} />
                            </ToggleButton>
                            <ToggleButton
                                value="meh"
                                classes={{ root: styles['dialog-mood-toggle'], selected: styles['dialog-mood-toggle-selected'] }}>
                                <FontAwesomeIcon icon={faMeh} />
                            </ToggleButton>
                            <ToggleButton
                                classes={{ root: styles['dialog-mood-toggle'], selected: styles['dialog-mood-toggle-selected'] }}
                                value="happy">
                                <FontAwesomeIcon icon={faSmile} />
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <Box id="feedback-dialog-content-text" className={styles['dialog-input-text']}>
                            <Typography className={styles['dialog-input-label']}>{t('Feedback.Improve.Label')}</Typography>
                            <FormInputText
                                classes={{ root: styles['dialog-input-textarea'], container: styles['dialog-input-text-container'] }}
                                placeholder={t('Feedback.Improve.Hint')}
                                InputProps={{ type: 'textarea', multiline: true, rows: 4 }}
                                type="textarea"
                                name="feedback"
                                control={control}
                            />
                        </Box>
                        <Button type="button" variant="contained" className={styles['submit-button']} disabled={!validMoodValue()} onClick={handleForward}>
                            <Typography>{t('Feedback.Submit.Action')}</Typography>
                        </Button>
                        <IconButton type="reset" className={styles['dialog-close-button']} onClick={handleReset}>
                            <FontAwesomeIcon icon={faXmark} />
                        </IconButton>
                    </Box>
                    <Box
                        className={`${styles['dialog-verification']} ${step === 'verification' ? styles['visible'] : styles['invisible']}`}>
                        <ReCAPTCHA ref={refRecaptcha} sitekey="6LdXf7slAAAAAOMfGG1J7vwyTzR_fllEx-TDDyPc" onChange={handleCaptcha} />
                    </Box>
                </form>
            </Paper>

        </Box>
    );
}

export default FeedbackDialog;
