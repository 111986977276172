import { CircularProgress, Slide, Snackbar } from '@mui/material'
import Alert from '../Alert'
import styles from './snackBarMessage.module.scss'

export interface ISnackBarData {
    open: boolean,
    severity: 'error' | 'info' | 'success' | 'warning',
    message: string,
    progress?: number
}

export default function SnackBarMessage(props: { message: string, open: boolean, severity: 'error' | 'info' | 'success' | 'warning', progress?: number, onSetOpen: (data: ISnackBarData) => void }) {
    if (!props.open) {
        return null;
    }

    const handleCloseSnackBar = (event: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        props.onSetOpen({ open: false, message: '', severity: props.severity });
    };

    return (
        <Snackbar TransitionComponent={Slide} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={props.open} autoHideDuration={props.progress > -1 ? null : 6000} onClose={handleCloseSnackBar}>
            <Alert classes={{ root: styles['snackbar-container'] }} onClose={props.progress > -1 ? null : handleCloseSnackBar} severity={props.severity} icon={props.progress > -1 ? <CircularProgress variant="determinate" value={props.progress} size={22} /> : null}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}


/* example

const [stateSnackBar, setSnackBar] = useState<ISnackBarData>({ open: false, severity: 'success', message: '' })
<SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />

*/