import React, { useEffect } from 'react'
import CommentItem from './CommentItem'
import { IComment } from './Comments'
import PerfectScrollbar from 'react-perfect-scrollbar'
import scrollCommentIntoViewAtom from 'atoms/scrollCommentIntoViewAtom'
import { useRecoilState } from 'recoil'


export default function CommentList(props: React.PropsWithChildren<{ comments: Array<IComment> }>) {
    const [scrollCommentIntoView, setScrollCommentIntoView] = useRecoilState(scrollCommentIntoViewAtom);
    const listRef: React.RefObject<any> = React.createRef();

    useEffect(() => {
        if (!scrollCommentIntoView || !listRef?.current) {
            return;
        }

        listRef.current?.lastElementChild?.scrollIntoView();
        setScrollCommentIntoView(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRef, scrollCommentIntoView]);

    return (
        <PerfectScrollbar style={{ flex: 1, overflowY: 'auto' }}>
            <ul style={{ padding: '0px 0px 0px 0px', margin: '0px 0px 0px 0px', width: '368px' }} ref={listRef}>
                {
                    props?.comments?.map((commentItem: any, index: number) => {
                        return <CommentItem user={commentItem.user}
                            scrollToItem={index === (props.comments?.length - 2)}
                            key={`issueComment${index}`}
                            avatar={commentItem.avatar}
                            comment={commentItem.comment}
                            created={commentItem?.created}
                            updated={commentItem?.updated}
                            workGroupFirstUpdate={commentItem?.workGroupFirstUpdate}
                            addComment={commentItem.addComment}
                            addAttachment={commentItem.addAttachment}
                            changes={commentItem.changes}
                            images={commentItem.images}
                        />
                    })
                }
            </ul>
        </PerfectScrollbar>
    )
}
