import styles from "../../styles/NavBar.module.scss";
import { AppBar, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ flexGrow: 1 }} position="sticky">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={0}
        >
          <Box className={styles.clip}>
            <Link to="/">
              <img
                alt=""
                style={{ cursor: "pointer", marginLeft: "4px" }}
                src="/img/header.svg"
                height="64px"
              />
            </Link>
          </Box>
        </Stack>
      </AppBar>
    </Box>
  );
}
