import { Dialog, DialogProps } from '@mui/material';
import React from 'react';

const DialogBox: React.FC<React.PropsWithChildren<Omit<DialogProps, "ref"> & { innerRef?: React.Ref<HTMLDivElement> }>> = (props) => {
    let { innerRef, children, ...dialogProps } = props;
    return (
        <Dialog
            {...dialogProps}
            ref={innerRef}
        >
            {children}
        </Dialog>
    );
}
export default DialogBox