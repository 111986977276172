import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { FormInputSelect } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import restApi from 'services/rest-api';
import Comments from './IssueComments/Comments';
import styles from './IssueDialog.module.scss';
import propertiesStyles from '../../../styles/Properties.module.scss';
import EditableInput from 'components/Shared/EditableInput/EditableInput';
import reloadActiveIssueTimelineAtom from 'atoms/reloadActiveIssueTimelineAtom';
import { useRecoilState } from 'recoil';
import { FormInputSelectMenuItem } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FormInputFile } from 'components/Shared/FormComponents/FormInputFile';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import restAPI from '../../../services/rest-api';
import useSession from 'hooks/useSession';
import DownloadIcon from '@mui/icons-material/Download';
import { ScreenCapture } from 'hsbshareviewer/src/model.api/modelviewer/Tools/ScreenCapture';
import { HsbEntity } from 'hsbshareviewer';

export enum States {
    OPEN = 'Open',
    ACTIVE = 'Active',
    IN_PROGRESS = 'InProgress',
    ON_HOLD = 'OnHold',
    RESOLVED = 'Resolved',
    CLOSED = 'Closed',
    DUE = 'Due',
}

export enum Priorities {
    MINOR = 'Minor',
    NORMAL = 'Normal',
    MAJOR = 'Major',
    CRITICAL = 'Critical',
    SHOW_STOPPER = 'ShowStopper',
}

export enum Types {
    BUG = 'Bug',
    WISH = 'Wish',
    QUESTION = 'Question',
    TASK = 'Task',
}

export interface IssueDialogProps {
    projectId: string;
    onClose: (event) => void;
    issue: any;
    onPropertyChange?: (id: string, propertyName: string, event: any) => void;
    onSubmit?: (issues: any[]) => void;
    screenCapture: ScreenCapture;
    entityTable: { [key: string]: { [key: string]: HsbEntity } };
}

const interpretIssueProperties = (issue: any): IssueForm => {
    return {
        title: issue?.properties?.title?.value || '',
        description: issue?.properties?.description?.value || '',
        state: Object.keys(States).includes(issue?.properties?.state?.value) ? issue?.properties.state.value : '',
        assigned_to: issue?.properties?.assigned_to?.value,
        priority: Object.keys(Priorities).includes(issue?.properties?.priority?.value) ? issue?.properties.priority.value : '',
        type: Object.keys(Types).includes(issue?.properties?.type?.value) ? issue?.properties.type.value : '',
        documents: issue?.documents?.length > 0 ? issue?.documents.filter((d) => d.name !== 'thumbnail.png') : null,
    };
};

interface IssueForm {
    title: string;
    description: string;
    state: string;
    assigned_to: string;
    priority: string;
    type: string;
    documents: any[];
}

const defaultIssueValues = {
    title: null,
    description: '',
    state: null,
    assigned_to: '',
    priority: null,
    type: null,
    documents: [],
}

export default function IssueDialog(props: React.PropsWithChildren<IssueDialogProps>) {
    const { screenCapture, onClose, onSubmit, projectId, onPropertyChange, entityTable, issue } = props;
    const { t } = useTranslation('common');
    const [projectMembers, setProjectMembers] = useState([]);
    const [, setReloadIssueTimeline] = useRecoilState(reloadActiveIssueTimelineAtom);
    const [formValues, setFormValues] = useState<IssueForm>(defaultIssueValues);
    const { control, getValues, setValue, reset, formState } = useForm({
        defaultValues: defaultIssueValues,
        mode: 'onTouched',
    });
    const [thumbnail, setThumbnail] = useState(null);
    const [isCreateMode, setIsCreateMode] = useState(!issue);
    const location = useLocation();
    const [, setSearchParams] = useSearchParams();
    const { getSession } = useSession();
    const [isDraggedUpon, setIsDraggedUpon] = useState(false);

    const getIssueScreenshot = useCallback(async () => {
        if (!issue) return null;
        let refs: { [key: string]: { ifcid?: string; referenceId?: string }[] } = JSON.parse(issue.modelReferences);
        let entities = Object.entries(refs).reduce((accu, [key, value]) => {
            let model = entityTable[key];
            if (!model) return accu;
            let ents = value.map((ref) => model[ref.referenceId]).filter((e) => e);
            accu.push(...ents);
            return accu;
        }, []);
        // Screenshot
        let dataURI = await screenCapture.screenshotEntities(entities);
        return dataURI;
    }, [entityTable, issue, screenCapture]);

    useEffect(() => {
        if (issue) {
            setIsCreateMode(!issue._id);
            let props = interpretIssueProperties(issue);
            reset(props);
            setFormValues(getValues());
            if (!issue._id) {
                getIssueScreenshot().then((dataURI) => {
                    issue.thumbnailDataUrl = dataURI;
                    setThumbnail(dataURI);
                });
            } else {
                restAPI.getNodeThumbnail(projectId, issue._id).then((dataURI) => {
                    issue.thumbnailDataUrl = dataURI;
                    setThumbnail(dataURI);
                });
            }
        } else {
            reset();
            setFormValues(defaultIssueValues)
        }
    }, [getIssueScreenshot, getValues, issue, projectId, reset]);

    /**
     * Assignee
     */
    useEffect(() => {
        if (projectId) {
            restApi.getProjectUsers(projectId).then((p) => {
                setProjectMembers(p.data);
                let assigneeId = props?.issue?.properties?.assigned_to?.value;
                if (assigneeId && p.data.map((m) => m._id).includes(assigneeId)) {
                    setValue('assigned_to', assigneeId);
                } else {
                    setValue('assigned_to', '');
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    const handleClose = (event) => {
        if (!isDraggedUpon) onClose(event);
        reset();
    };

    const handlePropertyChange = (newValue: any, propertyName: string) => {
        if (!isCreateMode && onPropertyChange) {
            onPropertyChange(issue._id, propertyName, newValue);
            setReloadIssueTimeline(true);
        }
        setFormValues(getValues());
    };

    //#region Properties
    const getStateOptions = () => {
        const states = Object.keys(States).map((s) => {
            return (
                <FormInputSelectMenuItem
                    key={s}
                    value={s}
                    avatar={{ letter: s.substring(0, 1), styleClass: propertiesStyles[s.toLowerCase()] }}
                    type={'iconRight'}>
                    {t(`IssueDialog.States.${States[s]}`)}
                </FormInputSelectMenuItem>
            );
        });
        states.unshift(
            <FormInputSelectMenuItem key={'unset'} value={''}>
                {t('IssueDialog.States.Unset')}
            </FormInputSelectMenuItem>
        );
        return states;
    };

    const getMemberOptions = () => {
        const members = projectMembers.map((projectMember) => {
            return (
                <FormInputSelectMenuItem
                    key={projectMember._id}
                    value={projectMember._id}
                    description={projectMember.email}
                    avatar={{
                        URI: projectMember.avatar.URI,
                        backgroundColor: projectMember.avatar.backgroundColor,
                        letter: projectMember.name.substring(0, 1),
                    }}>
                    {projectMember.name}
                </FormInputSelectMenuItem>
            );
        });
        members.unshift(
            <FormInputSelectMenuItem key={'unassigned'} value={''}>
                {t('IssueDialog.Unassigned')}
            </FormInputSelectMenuItem>
        );
        return members;
    };

    const getPriorityOptions = () => {
        const priorities = Object.keys(Priorities).map((p) => {
            return (
                <FormInputSelectMenuItem
                    key={p}
                    value={p}
                    avatar={{ letter: p.substring(0, 1), styleClass: propertiesStyles[p.toLowerCase()] }}
                    type={'iconRight'}>
                    {t(`IssueDialog.Priorities.${Priorities[p]}`)}
                </FormInputSelectMenuItem>
            );
        });
        priorities.unshift(
            <FormInputSelectMenuItem key={'unset'} value={''}>
                {t('IssueDialog.Priorities.Unset')}
            </FormInputSelectMenuItem>
        );
        return priorities;
    };

    const getTypeOptions = () => {
        const types = Object.keys(Types).map((type) => {
            return (
                <FormInputSelectMenuItem
                    key={type}
                    value={type}
                    avatar={{ letter: type.substring(0, 1), styleClass: propertiesStyles[type.toLowerCase()] }}
                    type={'iconRight'}>
                    {t(`IssueDialog.Types.${Types[type]}`)}
                </FormInputSelectMenuItem>
            );
        });
        types.unshift(
            <FormInputSelectMenuItem key={'unset'} value={''}>
                {t('IssueDialog.Types.Unset')}
            </FormInputSelectMenuItem>
        );
        return types;
    };

    const getAssignee = () => {
        let assignee = projectMembers.find((pm) => pm._id === formValues['assigned_to']);
        if (!assignee) {
            return null;
        }
        if (assignee.avatar?.URI) {
            return <img src={assignee.avatar.URI} alt={`Icon for ${assignee.name}`} />;
        }
        return (
            <Box className={styles['assignee-icon']} sx={{ backgroundColor: assignee.avatar?.backgroundColor }}>
                {assignee.name.substring(0, 1)}
            </Box>
        );
    };

    //#endregion

    const handleSubmit = async () => {
        if (!formState.isValid) {
            return;
        }

        try {
            const issueCopy = { ...issue, ...getValues() };
            Object.keys(issueCopy).forEach(key => issueCopy[key] === undefined ? delete issueCopy[key] : {});
            // Node
            let node = await restApi.createIssueNode(props.projectId, issueCopy);
            if (!node) {
                throw Error('Could not create issue for node!');
            }
            // File
            let blob = await (await fetch(issueCopy.thumbnailDataUrl)).blob();
            let thumbnailFile = new File([blob], 'thumbnail.png', { type: 'image/png' });
            const dt = new DataTransfer();
            dt.items.add(thumbnailFile);
            let docs = getValues('documents');
            docs?.forEach((doc) => {
                dt.items.add(doc);
            });
            let fileResults = await restApi.uploadDocumentsToNode(node._id, dt.files);
            node.documents = fileResults?.documents;
            onSubmit?.([node]);
        } catch (err) {
            console.error('Something went wrong!', err);
        }

        const queryParams = new URLSearchParams(location.search);
        queryParams.set('rpanel', 'issues');
        setSearchParams(queryParams);
    };

    const getClassForExtension = (fileType: string) => {
        if (fileType?.includes('image')) {
            return 'image';
        } else if (fileType?.includes('pdf')) {
            return 'pdf';
        }
    };

    const handleFileDeletion = (e: any, fileData: any) => {
        if (!isCreateMode) {
            restApi.deleteFileFromNode(issue._id, fileData.name).then((result) => {
                setValue('documents', result?.documents?.length > 0 ? result.documents : null);
            }).catch((err) => {
                console.error(err);
            });
        } else {
            const docs = getValues('documents')?.filter((file: any) => file !== fileData);
            setValue('documents', docs.length > 0 ? docs : null);
        }
        e.stopPropagation();
    };

    const handleAttachmentsAdd = (e: any) => {
        if (!isCreateMode) {
            const dt = new DataTransfer();
            e.forEach((file: any) => dt.items.add(file));
            restApi.uploadDocumentsToNode(issue._id, dt.files).then((filesResult) => {
                setValue('documents', filesResult?.documents || null);
            });
        } else {
            setValue('documents', [...(getValues('documents') || []), ...e]);
        }
    };

    const handleFilePreview = (file: any) => {
        if (!file.path) {
            return;
        }

        const url = restAPI.getFileDownloadURL(file.path, getSession()?.token);
        window.open(url);
    };

    const handleFileDownload = (e: any, file: any) => {
        e.stopPropagation();
        e.preventDefault();
        const path = restAPI.getFileDownloadURL(file.path, getSession()?.token);
        fetch(path)
            .then((res) => res.blob())
            .then((blob) => {
                // Create link element to download file
                let url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.href = url;
                link.download = file.name;
                // Start download
                link.click();
                // Cleanup
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.error('Could not download file!', err);
            });
    };

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        if (!isDraggedUpon) {
            setIsDraggedUpon(true);
        }
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    const handleDragLeave = (e: any) => {
        if (e.target.parentNode.id === 'main-content-container') {
            return;
        }

        e.preventDefault();
        setIsDraggedUpon(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDraggedUpon(false);
        if (e.dataTransfer?.files?.length > 0) {
            handleAttachmentsAdd(Array.from(e.dataTransfer.files));
        }
    };

    const handleThumbnailLink = (e: any) => {
        var image = new Image();
        image.src = issue.thumbnailDataUrl;
        var w = window.open('Test', issue.title ?? 'Image');
        if (w) {
            w.document.write(image.outerHTML);
        }
    };

    return (
        <Dialog open={!!issue} onClose={handleClose} classes={{ paper: styles['dialog-paper'] }}>
            <DialogTitle className={`${styles['title-container']} ${propertiesStyles[formValues.state]}`}>
                <Grid display={'flex'} width={'100%'}>
                    <Box className={styles['issue-information']}>
                        {!isCreateMode ? (
                            <Box>
                                <EditableInput
                                    onSave={(value) => handlePropertyChange(value, 'title')}
                                    type="text"
                                    text={issue?.properties?.title?.value}
                                    inputProps={{ className: styles['main-content-title'] }}
                                />
                                <Typography variant="subtitle1">
                                    <span>{t('IssueDialog.CreatedBy')}</span> {issue?.createdBy?.name}{' '}
                                    <span>{t('IssueDialog.CreatedOn')}</span> {new Date(issue?.createdAt).toLocaleString()}
                                </Typography>
                            </Box>
                        ) : (
                            <FormInputText
                                variant="outlined"
                                control={control}
                                rules={{ required: true, minLength: 1 }}
                                errortext={"A title is required for an issue!"}
                                name="title"
                                placeholder="Title"
                                classes={{ container: styles['create-issue-input-container'] }} />
                        )}
                    </Box>
                </Grid>
            </DialogTitle>
            <DialogContent className={styles['content-container']}>
                <Box
                    id="main-content-container"
                    className={`${styles['main-content-container']} ${isDraggedUpon && styles['main-content-container-dragged']}`}
                    onDrop={handleDrop}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}>
                    <PerfectScrollbar className={styles['main-content-container-scroll']} options={{ suppressScrollX: true }}>
                        {thumbnail && (
                            <Box id="issue-thumbnail" onClick={handleThumbnailLink} className={styles['main-content-thumbnail']}>
                                <img src={thumbnail} alt="issue thumbnail" />
                            </Box>
                        )}
                        <Box className={styles['main-content-description']}>
                            <Typography variant="subtitle1">{t('IssueDialog.Description')}</Typography>
                            {!isCreateMode ? (
                                <EditableInput
                                    onSave={(value) => handlePropertyChange(value, 'description')}
                                    type="textarea"
                                    text={issue?.properties?.description?.value}
                                />
                            ) : (
                                <FormInputText
                                    multiline
                                    minRows={5}
                                    variant="outlined"
                                    name="description"
                                    control={control}
                                    classes={{ container: styles['create-issue-input-container'] }}></FormInputText>
                            )}
                        </Box>
                    </PerfectScrollbar>
                    <Box className={styles['attachments-container']}>
                        <Box display={'flex'} gap={'5px'} sx={{ marginBottom: '5px' }}>
                            <Typography variant="subtitle1">{t('IssueDialog.Attachments')}</Typography>
                            {getValues('documents')?.length > 0 && (
                                <Typography className={styles['attachments-amount']}>
                                    {getValues('documents').filter((d) => d.name !== 'thumbnail.png').length}
                                </Typography>
                            )}
                            {getValues('documents')?.length > 0 && (
                                <FormInputFile
                                    multiple
                                    control={control}
                                    name="documents"
                                    classes={{
                                        container: styles['inline-input-wrapper'],
                                        'input-container': styles['inline-input-container'],
                                    }}
                                    helperIcon={<AddIcon />}
                                    onChange={(e) => handleAttachmentsAdd(e)}
                                />
                            )}
                        </Box>
                        {getValues('documents')?.length > 0 ? (
                            <PerfectScrollbar
                                className={styles['scrollable-attachments']}
                                options={{ useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false }}>
                                {[...getValues('documents')]
                                    .filter((d) => d.name !== 'thumbnail.png')
                                    .map((attachment, index) => {
                                        return (
                                            <Box
                                                className={`${styles['attachment']} ${styles[getClassForExtension(attachment.type)]}`}
                                                sx={{ cursor: attachment.path ? 'pointer' : 'default' }}
                                                key={`${index} ${attachment.name}`}
                                                onClick={() => handleFilePreview(attachment)}>
                                                <Box className={styles['attachment-information']}>
                                                    <Box>{attachment.name?.split('.').pop()}</Box>
                                                    <Typography title={attachment.name}>
                                                        {attachment.name?.replace(/\.[^/.]+$/, '')}
                                                    </Typography>
                                                </Box>
                                                <Box className={styles['attachment-actions']}>
                                                    {attachment.path && (
                                                        <IconButton aria-label="delete" onClick={(e) => handleFileDownload(e, attachment)}>
                                                            <DownloadIcon />
                                                        </IconButton>
                                                    )}
                                                    <IconButton onClick={(e) => handleFileDeletion(e, attachment)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                            </PerfectScrollbar>
                        ) : (
                            <FormInputFile
                                multiple
                                name="documents"
                                control={control}
                                classes={{
                                    container: styles['attachments-input-wrapper'],
                                    'input-container': styles['attachments-input-container'],
                                }}
                                helperText={t('IssueDialog.AddAttachmentsHelp')}
                                helperIcon={<AttachFileIcon />}
                                onChange={(e) => handleAttachmentsAdd(e)}
                            />
                        )}
                    </Box>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems="flex-end"
                    justifyContent={'space-between'}
                    className={styles['side-content-container']}>
                    <table className={styles['properties-table']}>
                        <tbody>
                            <tr>
                                <th>
                                    <Typography>{t('IssueDialog.State')}</Typography>
                                </th>
                                <td>
                                    <FormInputSelect
                                        search
                                        name="state"
                                        control={control}
                                        classes={{ container: styles['property-selector'] }}
                                        onChange={(event: any) => handlePropertyChange(event.target.value, 'state')}>
                                        {getStateOptions()}
                                    </FormInputSelect>
                                </td>
                                <td>
                                    <Box
                                        className={`${styles['property-circle']} ${propertiesStyles[formValues['state']?.toLowerCase()]} `}>
                                        <span>{formValues['state']?.substring(0, 1)}</span>
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Typography>{t('IssueDialog.Assignee')}</Typography>
                                </th>
                                <td>
                                    <FormInputSelect
                                        search
                                        name="assigned_to"
                                        control={control}
                                        classes={{ container: styles['property-selector'] }}
                                        onChange={(event: any) => handlePropertyChange(event.target.value, 'assigned_to')}
                                        placeholder={t('IssueDialog.Unassigned')}>
                                        {getMemberOptions()}
                                    </FormInputSelect>
                                </td>
                                <td>{getAssignee()}</td>
                            </tr>
                            <tr>
                                <th>
                                    <Typography>{t('IssueDialog.Priority')}</Typography>
                                </th>
                                <td>
                                    <FormInputSelect
                                        search
                                        name="priority"
                                        control={control}
                                        classes={{ container: styles['property-selector'] }}
                                        onChange={(event: any) => handlePropertyChange(event.target.value, 'priority')}
                                        placeholder={t('IssueDialog.Priorities.Unset')}>
                                        {getPriorityOptions()}
                                    </FormInputSelect>
                                </td>
                                <td>
                                    <Box
                                        className={`${styles['property-circle']} ${propertiesStyles[formValues['priority']?.toLowerCase()]
                                            } `}>
                                        <span>{formValues['priority']?.substring(0, 1)}</span>
                                    </Box>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <Typography>{t('IssueDialog.Type')}</Typography>
                                </th>
                                <td>
                                    <FormInputSelect
                                        search
                                        name="type"
                                        control={control}
                                        classes={{ container: styles['property-selector'] }}
                                        onChange={(event: any) => handlePropertyChange(event.target.value, 'type')}
                                        placeholder={t('IssueDialog.Types.Unset')}>
                                        {getTypeOptions()}
                                    </FormInputSelect>
                                </td>
                                <td>
                                    <Box className={`${styles['property-circle']} ${propertiesStyles[formValues['type']?.toLowerCase()]} `}>
                                        <span>{formValues['type']?.substring(0, 1)}</span>
                                    </Box>
                                </td>
                            </tr>
                            <tr></tr>
                        </tbody>
                    </table>
                    {!isCreateMode ? (
                        <Comments issueId={issue?._id} />
                    ) : (
                        <Box className={styles['creation-action-container']} display={'flex'} gap={1}>
                            <Button
                                variant="contained"
                                className={styles['save-button']}
                                disabled={!formState.isValid}
                                onClick={handleSubmit}>
                                create
                            </Button>
                            <Button variant="outlined" className={styles['cancel-button']} onClick={handleClose}>
                                cancel
                            </Button>
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
}
