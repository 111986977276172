import React from 'react'
import { useEffect, useState, useRef } from 'react'
import TeamMemberList from './TeamMemberList'
import TeamMemberItem from './TeamMemberItem'
import useArray from '../../hooks/useArray'
import { useRecoilState, useRecoilValue } from 'recoil'
import selectedTeamAtom from '../../atoms/selectedTeamAtom'
import selectedTeamMemberAtom, { ISelectedTeamMember } from 'atoms/selectedTeamMemberAtom'
import TeamAddMembersDialog from './TeamAddMembersDialog'
import restAPI from '../../services/rest-api'
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage'
import { useTranslation } from 'react-i18next'
import updatedTeamMemberAtom from 'atoms/updatedTeamMemberAtom'
import deletedTeamMemberAtom from 'atoms/deletedTeamMemberAtom'
import updatedTeamRoleAtom from 'atoms/updatedTeamRoleAtom'
import taskManager from 'services/taskManager'


const TeamMemberListController: React.FC<React.PropsWithChildren<{}> & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>> = () => {
    const { t } = useTranslation('common')
    const selectedTeam = useRecoilValue(selectedTeamAtom)
    const [, setSelectedTeamMember] = useRecoilState(selectedTeamMemberAtom)
    const { items, setItems, remove } = useArray([]) //const {items, setItems, add, update, remove } = useArray([])
    const [filter, setFilter] = useState(null);
    const [snackBarData, setSnackBarData] = useState<ISnackBarData>({ open: false, severity: 'info', message: '' })
    const teamRoles = useRef(null);
    const updatedTeamMember: ISelectedTeamMember = useRecoilValue(updatedTeamMemberAtom);
    const deletedTeamMember = useRecoilValue(deletedTeamMemberAtom);
    const updatedTeamRole = useRecoilValue(updatedTeamRoleAtom);

    useEffect(() => remove('userId', deletedTeamMember?.userId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deletedTeamMember])

    useEffect(() => {
        const editedTeamMembers = items.map(teamMember => {
            if (teamMember.userId === updatedTeamMember.userId) {
                teamMember.role = updatedTeamMember.role;
                teamMember.roleId = updatedTeamMember.roleId;
                //here we are switching roles; so we must update the roleID too.
            }
            return teamMember;
        });
        setItems(editedTeamMembers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedTeamMember])

    useEffect(() => {
        if (updatedTeamRole?.oldRoleName !== updatedTeamRole?.role) {
            const editedTeamMembers = items.map(teamMember => {
                if (teamMember.roleId === updatedTeamRole.roleId) {
                    teamMember.role = updatedTeamRole.role;//rename
                }
                return teamMember;
            });
            setItems(editedTeamMembers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedTeamRole]);

    useEffect(() => {
        if (!selectedTeam?.teamID || (selectedTeam?.selectedIndex === undefined)) {
            setItems([])
            setSelectedTeamMember(null)
            return;
        }

        restAPI.getTeamMembers(selectedTeam.teamID).then(teamMembers => {
            setItems(teamMembers)
            const selected: ISelectedTeamMember = teamMembers.length === 0 ? null :
                (({ teamID, userId, username, email, telephone, userImage, role, roleId, isTeamAdmin, isActive, backgroundColor }) =>
                    ({ teamID, userId, username, email, telephone, userImage, role, roleId, isTeamAdmin, isActive, backgroundColor, index: 0 }))(teamMembers[0]);
            selectedTeam.selectedTeamMember ? setSelectedTeamMember({ ...selectedTeam.selectedTeamMember }) : setSelectedTeamMember(selected)
        });

        restAPI.getTeamPolicyRoles(selectedTeam.teamID).then(roles => {
            if (!roles)
                teamRoles.current = []
            else {
                teamRoles.current = roles.map((r) => r.Name);
            }
        })

        return () => {
            teamRoles.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeam]);

    const [open, setOpen] = React.useState(false);
    const addTeamMember = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false);
    }
    const onFilter = async (query: string | null) => {
        setFilter(query?.toLowerCase());
    }

    const onInvite = async (data: any) => {
        try {
            const workItems: Array<Awaited<Promise<any>>> = data.reduce((acc, member) => {
                acc.unshift(() => restAPI.addUserToTeam(member.username, selectedTeam?.teamID, member.roleId))
                return acc;
            }, []);
            let leftOver = await taskManager.forkWithRetry(workItems)
            if (leftOver.length > 0) {
                return setSnackBarData({ open: true, severity: 'error', message: t('TeamMemberListController.InviteFailed') });
            }
            const teamMembers = await restAPI.getTeamMembers(selectedTeam.teamID);
            setItems(teamMembers)
            const selected: ISelectedTeamMember = teamMembers.length === 0 ? null :
                (({ teamID, userId, username, email, telephone, userImage, role, roleId, isTeamAdmin, isActive, backgroundColor }) =>
                    ({ teamID, userId, username, email, telephone, userImage, role, roleId, isTeamAdmin, isActive, backgroundColor, index: 0 }))(teamMembers[0]);
            selectedTeam.selectedTeamMember ? setSelectedTeamMember({ ...selectedTeam.selectedTeamMember }) : setSelectedTeamMember(selected);

            setSnackBarData({ open: true, severity: 'success', message: t('TeamMemberListController.UsersInvited') });
        } catch (error) {
            setSnackBarData({ open: true, severity: 'error', message: t('TeamMemberListController.InviteFailed') })
            console.error(error);
        }
    }

    return (<div style={{ height: '100%' }}>
        <TeamMemberList addTeamMember={addTeamMember} onFilter={onFilter}>
            {items.filter((t) => { return !filter || [t.name, t.email, t.role].some((value) => value?.toLowerCase().includes(filter)) }).map((teamMemberData, index) =>
                <TeamMemberItem key={`tml${teamMemberData.userId}.${teamMemberData.teamID}}`}
                    teamID={teamMemberData.teamID}
                    userId={teamMemberData.userId}
                    username={teamMemberData.username}
                    email={teamMemberData.email}
                    role={teamMemberData.role}
                    roleId={teamMemberData.roleId}
                    telephone={teamMemberData.telephone}
                    userImage={teamMemberData.userImage}
                    backgroundColor={teamMemberData.backgroundColor}
                    index={index}
                    isTeamAdmin={teamMemberData.isTeamAdmin}
                    isActive={teamMemberData.isActive}
                />
            )}
        </TeamMemberList>

        <TeamAddMembersDialog teamID={selectedTeam?.teamID} onClose={handleClose}  isOpen={open} onInvite={onInvite} /> 
        <SnackBarMessage severity={snackBarData.severity}
            message={snackBarData.message}
            open={snackBarData.open}
            onSetOpen={setSnackBarData} />
    </div>
    )
}

export default TeamMemberListController;