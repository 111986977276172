import ProjectList from '../Home/ProjectList';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActivityList from '../Home/ActivityList';
import IssueItem from '../Home/IssueItem';
import restAPI from '../../services/rest-api';
import { useEffect, useMemo, useState } from 'react';
import config from 'services/config';
import { HsbProjectsCacheService } from 'hsbshareviewer';
import useSession from 'hooks/useSession';
import styles from 'styles/HomeLayout.module.scss';

const getLoadingIssues = (activityKey: string) => {
    return (
        <ActivityList activity={activityKey}>
            {Array.from(Array(8).keys()).map((index) => (
                <IssueItem
                    id={null}
                    projectId={null}
                    key={`col${index}`}
                    assignedTo={null}
                    updatedText={null}
                    image={null}
                    title={null}
                    state={null}></IssueItem>
            ))}
        </ActivityList>
    );
};

export default function HomeController() {
    const hsbProjectsCacheService = useMemo(() => new HsbProjectsCacheService(), []);
    const { getSession } = useSession();
    const { t } = useTranslation('common');
    const [createdByMeIssues, setCreatedByMeIssues] = useState<any>([]);
    const [assignedToMeIssues, setAssignedToMeIssues] = useState<any>([]);

    hsbProjectsCacheService.hsbProjectsLoader.initialize(config.server, getSession().token);
    hsbProjectsCacheService.nodeLoaderService.initialize(config.server, getSession());
    hsbProjectsCacheService.hsbProjectsLoader._modelmaploader.initialize(hsbProjectsCacheService.nodeLoaderService);
    hsbProjectsCacheService.setProjectsCache([]);

    function getCreatedByMeIssuesFromServer() {
        setCreatedByMeIssues(getLoadingIssues('Activity.CreatedByMe'));
        restAPI.getIssuesCreatedByMe().then((issuesCreatedByMeDBArray) => {
            const issuesJSX = issuesCreatedByMeDBArray?.map((issuesCreatedByMeDB) => {
                return (
                    <IssueItem
                        id={issuesCreatedByMeDB._id}
                        projectId={issuesCreatedByMeDB?.project?.projectID}
                        key={issuesCreatedByMeDB._id}
                        assignedTo={issuesCreatedByMeDB?.assigned_to_email ?? issuesCreatedByMeDB?.properties?.assigned_to?.value}
                        updatedText={
                            t('ProjectList.LastOpenedOnThenDate') + ' ' + new Date(issuesCreatedByMeDB?.updatedAt).toLocaleDateString()
                        }
                        image={getIssueImage(issuesCreatedByMeDB)}
                        title={issuesCreatedByMeDB?.properties?.title?.value}
                        state={getState(issuesCreatedByMeDB?.properties?.state?.key)}></IssueItem>
                );
            });
            setCreatedByMeIssues(<ActivityList activity={'Activity.CreatedByMe'}>{issuesJSX}</ActivityList>);
        });
    }

    function getAssignedToMeIssuesFromServer() {
        setAssignedToMeIssues(getLoadingIssues('Activity.AssignedToMe'));
        restAPI.getIssuesAssignedToMe().then((issuesAssignedToMeDBArray) => {
            const issuesJSX = issuesAssignedToMeDBArray?.map((issuesAssignedToMeDB) => {
                return (
                    <IssueItem
                        id={issuesAssignedToMeDB._id}
                        projectId={issuesAssignedToMeDB?.project?.projectID}
                        key={issuesAssignedToMeDB._id}
                        assignedTo={issuesAssignedToMeDB?.assigned_to_email ?? issuesAssignedToMeDB?.properties?.assigned_to?.value}
                        updatedText={
                            t('ProjectList.LastOpenedOnThenDate') + ' ' + new Date(issuesAssignedToMeDB?.updatedAt).toLocaleDateString()
                        }
                        image={getIssueImage(issuesAssignedToMeDB)}
                        title={issuesAssignedToMeDB?.properties?.title?.value}
                        state={getState(issuesAssignedToMeDB?.properties?.state?.key)}></IssueItem>
                );
            });
            setAssignedToMeIssues(<ActivityList activity={'Activity.AssignedToMe'}>{issuesJSX}</ActivityList>);
        });
    }

    useEffect(() => {
        getCreatedByMeIssuesFromServer();
        getAssignedToMeIssuesFromServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getState = (stateKey) => {
        switch (stateKey) {
            case 'Issue.StateValues.Active':
                return 'active';
            case 'Issue.StateValues.Resolved':
                return 'resolved';
            case 'Issue.StateValues.ActiveDue':
                return 'active-due';
            case 'Issue.StateValues.Closed':
                return 'closed';
        }
        return undefined;
    };

    const getHsbShareFileURL = (path) => `${config.server}/api/v2/efs/datastore/getFile?path=/${path}&access_token=${getSession().token}`;
    const isImage = (path) => /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(path);

    const getIssueImage = (issuesCreatedByMeDB) => {
        //try to get an image from the issue itself.
        if (issuesCreatedByMeDB?.documents?.length > 0) {
            const imageDocuments = issuesCreatedByMeDB.documents.filter((document) => isImage(document.path));
            if (imageDocuments.length > 0) {
                return getHsbShareFileURL(imageDocuments[imageDocuments.length - 1].path);
            }
        }
        //try a project large format image
        if (issuesCreatedByMeDB?.dataModelFiles?.length > 0) {
            const images = issuesCreatedByMeDB?.dataModelFiles?.reduce((acc, dataModelFile) => {
                if (dataModelFile?.documents?.length > 0) {
                    const imageDocuments = dataModelFile?.documents.filter((document) => isImage(document.path));
                    imageDocuments?.forEach((document) => acc.unshift(document?.path));
                }
                return acc;
            }, []);
            if (images.length > 0) {
                return getHsbShareFileURL(images[images.length - 1]);
            }
        }
        //otherwise we try a project thumbnail
        if (issuesCreatedByMeDB?.modelNode?.thumbnailDataUrl) {
            return issuesCreatedByMeDB?.modelNode?.thumbnailDataUrl; //encode image format
        }
        //if all fails; return a place holder
        return `./img/emptyproject.svg`;
    };

    return (
        <Box sx={{ height: '100%' }} display={'flex'} flexDirection={'column'}>
            <Box>
                <ProjectList />
            </Box>
            <Box>
                {' '}
                <Typography variant="h5" sx={{ paddingLeft: '15px', lineHeight: '40px' }}>
                    {t('ItemList.Activity')}
                </Typography>
            </Box>
            <Box className={styles['activity-list-container']}>
                <Box className={styles['activity-container']}>{createdByMeIssues}</Box>
                <Box className={styles['activity-container']}>{assignedToMeIssues}</Box>
            </Box>
        </Box>
    );
}
