import React, { SyntheticEvent,  useEffect, useRef, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import { DialogTitle, DialogActions, Button, IconButton, InputAdornment, Box } from '@mui/material'
import Close from '@mui/icons-material/Close';
import { Control, useFieldArray, useForm, UseFormWatch } from 'react-hook-form';
import styles from 'styles/TeamAddMembersDialog.module.css'
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import { FormInputSelect } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelect';
import { AccountCircle, Add } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import DialogBox from 'components/Shared/DialogBox';
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage';
import addedTeamRoleAtom from 'atoms/addedTeamRoleAtom';
import {  useRecoilValue } from 'recoil';
import deletedTeamRoleAtom from 'atoms/deletedTeamRoleAtom';
import updatedTeamRoleAtom from 'atoms/updatedTeamRoleAtom';
import { FormInputSelectMenuItem } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem';
import restAPI from '../../services/rest-api';
import useArray from 'hooks/useArray';


const MemberTextField: React.FC<{
  id: number,
  control: Control<any>,
  watch: UseFormWatch<any>,
  roles: Array<{role: string, roleId:string}>,
  handleRemove: (id: number) => void,
  handlePaste: (e: SyntheticEvent, id: number) => void,
  handleInputChange: (e: any) => void
}> = ({ id, control, watch, roles, handleRemove, handlePaste, handleInputChange }) => {
  const watchEmail = watch(`members.${id}.username`);
  const { t } = useTranslation('common');
 


  const usernameValidator = (value: string) => {
    if (!value || value === '')
      return undefined;
    let match = (/(.+)(@)(.*)/).exec(value);
    if (!match)
      return t('AddTeamMemberDialog.EmailAtHelp');
    if (match[3] === '')
      return t('AddTeamMemberDialog.EmailDomainHelp');
    return undefined;
  }

  const onRemove = (...data) => {
    handleRemove(id);
  }

  const onPaste = (e: any) => {
    handlePaste(e, id);
  }

  const getRoleOptions = () => {
    return roles.map((role, index) => <FormInputSelectMenuItem key={'role-' + role.roleId} value={role.roleId}>{role.role}</FormInputSelectMenuItem>)
  }

  return (
    <div key={id} id={'input-member-' + id}>
      <FormInputText name={`members.${id}.username` as const} control={control}
        rules={{ validate: usernameValidator }}
        endButton={
          <FormInputSelect search classes={{ container: styles.endSelect }} rules={{ required: watchEmail && t('AddTeamMemberDialog.RoleRequired') as string }} name={`members.${id}.roleId` as const} control={control} placeholder={t('AddTeamMemberDialog.RoleHelp')}>
            {getRoleOptions()}
          </FormInputSelect>
        }
        InputProps={{
          onPaste: onPaste,
          startAdornment:
            (< InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>),
          placeholder: t('AddTeamMemberDialog.EmailHelp')
        }}
        onRemove={(watchEmail?.length > 0) && onRemove}
        onChange={handleInputChange}
      />
    </div>
  )
}

const TeamAddMembersDialog: React.FC<{ teamID: string, onClose: () => void, isOpen: boolean, onInvite: (...data: any) => void }> = ({ teamID, onClose, isOpen, onInvite }) => {
  const { t } = useTranslation('common');
  const scrollbarRef = useRef<PerfectScrollbar>(null);
  const { handleSubmit, watch, reset, getValues, control, setValue } = useForm({
    defaultValues: {
      members: [{
        username: '',
        roleId: ''
      }]
    },
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });
  const { items, setItems } = useArray([]);
  const setTeamPolicies = () => { 
      if(!teamID){
        return;
      }
      restAPI.getTeamPolicyRoles(teamID).then(roles => {
        if (!roles)
          setItems([]);
        else {
          setItems(roles.map((role) => { return {role: role.Name, roleId: role._id}}))
        }
    })
  }
  useEffect(() => {
    setTeamPolicies();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamID]);
  const addedTeamRole = useRecoilValue(addedTeamRoleAtom);
  const deletedTeamRole = useRecoilValue(deletedTeamRoleAtom);
  const updatedTeamRole = useRecoilValue(updatedTeamRoleAtom);
  useEffect(() => {
    setTeamPolicies();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedTeamRole, deletedTeamRole, updatedTeamRole]);
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "members",
  });
  const [stateSnackBar, setSnackBar] = useState<ISnackBarData>({ open: false, severity: 'success', message: '' })
  const handleValidSubmit = (data: any, e: any) => {
    onInvite(data.members.filter(m => m.username !== ''));
    onClose();
    remove();
    reset({
      members: [{
        username: '',
        roleId: ''
      }]
    });
  }

  const handleInvalidSubmit = (...data: any) => {
    console.error('Invalid:', data);
  }

  const handleCancel = (...data: any) => {
    onClose();
    reset({
      members: [{
        username: '',
        roleId: ''
      }]
    });
  }

  const addNewField = () => {
    append({
      username: '',
      roleId: ''
    });
    setTimeout(() => {
      scrollbarRef.current?.updateScroll();
    }, 200);
  }

  const handlePaste = (e: any, id: number) => {
    e.stopPropagation();
    e.preventDefault();
    let clipboardData = e.clipboardData;
    let textData: string = clipboardData.getData('Text');
    let out = (textData?.match(/(.+)\n?/g) ?? []) // Split into several mails
      .map((s) => { return s.replace(/[,|\s]*$/gm, "") })// Get rid of trailing commas and trailing whitespace
      .map((s) => { return s.replace(/(\r\n|\n|\r)/gm, "") })// Get rid of linebreaks etc
      .map((s) => s.trim()) // trim leading and trailing whitespace

    const roleId = getValues().members[id]?.roleId;
    if (addFields(out, roleId)) {
      addNewField();
    } else {
      setSnackBar({ open: true, severity: 'warning', message: t('TeamMemberList.DuplicateError') });
    }
  }

  const addFields = (members: string[], roleId?: string) => {
    if (members.length === 0)
      return false;
    let values = getValues().members;
    let currValues = Object.values(values).map((field) => field.username.toLowerCase()); // Get current usernames
    members = members.filter((s) => !currValues.includes(s.toLowerCase())); // Filter out usernames that have already been added
    let emptyfields = Object.entries(values).filter(([key, value]) => { return !(value?.username !== '') }); // Check if there are empty form fields
    members.forEach((pastedString, index) => {
      if (index < emptyfields.length) { // IF there is an empty field available
        let id = parseInt(emptyfields[index][0]);
        setValue(`members.${id}`, { username: pastedString, roleId: roleId ?? '' });
      }
      else { // Else create a field
        append({ username: pastedString, roleId: roleId ?? ''});
      }
    });
    setTimeout(() => {
      scrollbarRef.current?.updateScroll();
    }, 200);
    return members.length > 0;
  }

  const handleRemoveField = (id: number) => {
    if (fields.length > 1)
      remove(id);
    else
      setValue(`members.0`, { username: '', roleId: ''});
    reset(getValues());
    setTimeout(() => {
      scrollbarRef.current?.updateScroll();
    }, 200);
  }

  const handleInputChange = (e: any) => {
    let emptyFields = Object.entries(getValues().members).filter(([key, value]) => value?.username === '' && value?.roleId === '');
    if (e.target.value !== '' && emptyFields.length === 0) {
      addNewField();
      setTimeout(() => {
        e.target.focus();
      }, 1);
    } else if (emptyFields.length > 1) {
      handleRemoveField(fields.map(m => m.username).indexOf(''));
    }
  };



  return (
    <DialogBox aria-labelledby='add-team-members-dialog-title' open={isOpen} onClose={handleCancel}>
      <form onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}>
        <DialogTitle id="add-team-members-dialog-title">
          {t('AddTeamMemberDialog.Title')}
          <IconButton onClick={handleCancel}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PerfectScrollbar ref={scrollbarRef} className={styles.scrollBox}>
            <Box>
              {fields.map((field, index) => {
                return <MemberTextField key={field.id} id={index} control={control} watch={watch} roles={items} handleRemove={handleRemoveField} handlePaste={handlePaste} handleInputChange={handleInputChange} />
              })}
            </Box>
            <Button key='submitButton' type='submit' style={{ display: 'none' }} />
          </PerfectScrollbar>
        </DialogContent>
        <DialogActions>
          <Button key='inviteButton' variant='contained' type='submit' disabled={Object.entries(getValues().members).filter(([key, value]) => { return value?.username !== '' && value?.roleId !== '' }).length === 0}>
            <Add />
            {t('AddTeamMemberDialog.Invite')}
          </Button>
        </DialogActions>
      </form>
      <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
    </DialogBox>
  );
}
export default TeamAddMembersDialog
