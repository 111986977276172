import CreateAccount from '../components/CreateAccount/CreateAccount'
import Grid from '@mui/material/Grid';
import styles from '../styles/CreateAccountPage.module.css'

export default function CreateAccountPage() {
    return (
        <Grid className={styles.grid} container>
            <Grid item xs={12} sm={12} md={5}>
                <div className={styles.loginSidePanel}>
                    <CreateAccount />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7} className={`${styles.imageSidePanel} ${styles.responsive} `}>
                <div className={styles.clipi} style={{ position: 'relative', height: '100vh' }}></div>
            </Grid>
        </Grid>
    )
}