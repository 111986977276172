import { Box, Button, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Typography, } from "@mui/material";
import { FormInputSelect } from "components/Shared/FormComponents/FormInputSelect/FormInputSelect";
import { FormInputText } from "components/Shared/FormComponents/FormInputText";
import Tab from "components/Shared/Tab";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "styles/SearchPanel.module.scss";
import { HsbProject, HsbElement } from "hsbshareviewer";
import DeleteIcon from "@mui/icons-material/Delete";
import EventEmitter from "events";
import restAPI from "./../../services/rest-api";
import React from "react";
import SnackBarMessage, { ISnackBarData, } from "components/Shared/snackBar/SnackBarMessage";
import useSession from "hooks/useSession";
import { FormInputSelectMenuItem } from "components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem";

export default function SearchPanel(props: Partial<SearchPanelProps>) {
  let { open, project, onClose, onVisibilityToggle, allVisualEntities } = props;
  let { isGuest } = useSession();
  let { t } = useTranslation("common");
  const [options, setOptions] = useState(null);
  const { control, setValue, handleSubmit, trigger, getValues, formState, reset, } = useForm<any>({
    defaultValues: {
      objectType: "",
      propertyName: "",
      propertyValue: "",
      name: "",
    },
    mode: "onChange",
  });
  const [propertyNameOptions, setPropertyNameOptions] = useState([]);
  const [propertyValueOptions, setPropertyValueOptions] = useState([]);
  const [search, setSearch] = useState([]);
  const [hasManualName, setHasManualName] = useState(false);
  const [entities, setEntities] = useState([]);
  const [selectedSearch, setSelectedSearch] = useState(null);
  const [stateSnackBar, setSnackBar] = React.useState<ISnackBarData>({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    setEntities(allVisualEntities);
    if (allVisualEntities?.length > 0 && options === null) {
      let tempOptions = {};
      let tempLookup = [];

      allVisualEntities.forEach((entity) => {

        tempOptions[entity._objecttype] = tempOptions[entity._objecttype] ?? {};
        tempLookup[entity._objecttype] = tempLookup[entity._objecttype] ?? {};

        let props = Object.values(entity._properties).reduce(
          (propsAccu: any, value: any) => {
            if (!value._value || value._value === "") return propsAccu;
            return { ...propsAccu, [value._key]: value._value };
          }, {}
        );

        Object.entries(props).forEach(([key, value]) => {
          if (!value || value === "") return;
          if (tempOptions[entity._objecttype][key]) {
            if (!tempOptions[entity._objecttype][key].includes(value))
              tempOptions[entity._objecttype][key].push(value);
          } else {
            tempOptions[entity._objecttype][key] = [value];
          }
        });
        tempLookup[entity._objecttype][entity.handle] = props;
      }, {});

      setOptions(tempOptions);

      setPropertyNameOptions(Object.values(tempOptions).reduce((accu: any, curr: any) => { return { ...accu, ...curr }; }, {}) as any[]);

      if (isGuest()) {
        setSearch([]);
      } else {
        restAPI.getUserProjectSettings(props.project?.projectID).then((searchTerms) => {
          setSearch(searchTerms?.projectSearch ?? []);
        }).catch((error) => console.error(error));
      }
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVisualEntities]);

  const handleObjectTypeChange = (objType: string) => {
    if (objType === "any") {
      objType = "";
      setValue("objectType", "");
    } else {
      setValue("propertyValue", "");
      setValue("propertyName", "");
    }
    setPropertyNameOptions(options[objType] || Object.values(options).reduce((accu: any, curr: any) => { return { ...accu, ...curr }; }, {}));
    setPropertyValueOptions([]);
    setAutomaticName();
    toggleEntities(getSearchTerm());
  };

  const handlePropertyNameChange = (propName: string) => {
    if (propName === "any") {
      propName = "";
      setValue("propertyName", "");
    }
    setValue("propertyValue", "");
    setPropertyValueOptions(propName ? Object.values(propertyNameOptions[propName]) : []);
    toggleEntities(getSearchTerm());
    setAutomaticName();
  };

  const handlePropertyValueChange = () => {
    setAutomaticName();
    toggleEntities(getSearchTerm());
  };

  const handleNameChange = (name: string) => {
    setHasManualName(name && name.length > 0);
  };

  const setAutomaticName = () => {
    if (hasManualName) {
      return;
    }

    let name = "";
    Object.entries(getValues()).forEach((e) => {
      if (e[0] !== "name") {
        name += (name.length > 0 ? "-" : "") + (e[1] && e[1].toString().length > 0 ? e[1] : "any");
      }
    });
    setValue("name", name);
  };

  const selectSearch = (searchTerm: any, edit: boolean = false) => {
    let setSearchTerm = searchTerm;
    if (!edit && selectedSearch?._id === searchTerm._id) {
      setSearchTerm = null;
      setHasManualName(false);
      reset();
      toggleEntities();
    } else if (!edit) {
      setHasManualName(true);
      setValue("objectType", searchTerm?.objectType);
      setValue("propertyName", searchTerm?.propertyName);
      setValue("propertyValue", searchTerm?.propertyValue);
      setValue("name", searchTerm?.name);
      trigger();
      toggleEntities(searchTerm);
    }
    setSelectedSearch(setSearchTerm);
  };

  const deleteSearch = (e: any, id: string) => {
    e.stopPropagation();
    if (isGuest()) {
      setSearch(search.filter((s) => s._id !== id));
      if (selectedSearch?._id === id) {
        setSelectedSearch(null);
        reset();
        toggleEntities();
      }
      setHasManualName(false);
    } else {
      restAPI.deleteUserProjectSettings(props.project?.projectID, id).then(() => {
        let copy = [...search],
          searchTermIndex = copy.indexOf(copy.find((c) => c._id === id));
        copy.splice(searchTermIndex, 1);
        setSearch(copy);
        if (selectedSearch?._id === id) {
          setSelectedSearch(null);
          reset();
          toggleEntities();
        }
        setHasManualName(false);
      }).catch((error) => {
        setSnackBar({ open: true, severity: "error", message: t("SearchPanel.ErrorSearchDeletion"), });
        if (window.location.hostname === "localhost") {
          console.error(error);
        }
      });
    }
  };

  const onValidSubmit = () => {
    let copy = [...search];
    let searchTerm: any = getSearchTerm();
    searchTerm._id = copy.length + 1;
    if (isGuest()) {
      setSearch([...copy, searchTerm]);
      selectSearch(searchTerm, true);
    } else {
      restAPI.upsertUserProjectSettings(props.project?.projectID, searchTerm.name, searchTerm.objectType, searchTerm.propertyName, searchTerm.propertyValue, selectedSearch?._id).then((result) => {
        if (selectedSearch) {
          copy[copy.findIndex((c) => c._id === selectedSearch._id)] =
            result.projectSearch.find((s) => s._id === selectedSearch._id);
        } else {
          copy = [...result.projectSearch];
        }
        setSearch(copy);
        selectSearch(
          result.projectSearch[result.projectSearch.length - 1],
          true
        );
      }).catch((error) => {
        setSnackBar({
          open: true,
          severity: "error",
          message: t("SearchPanel.ErrorSearchSubmit"),
        });
        if (window.location.hostname === "localhost") {
          console.error(error);
        }
      });
    }
  };

  const getSearchTerm = () => {
    return {
      name: getValues().name, objectType: getValues().objectType.toString().length > 0 ? getValues().objectType : "any",
      propertyName: getValues().propertyName.toString().length > 0 ? getValues().propertyName : null,
      propertyValue: getValues().propertyValue.toString().length > 0 ? getValues().propertyValue : null,
    };
  };

  const toggleEntities = (searchTerm?: any) => {
    var visible: any[] = [];
    if (searchTerm) {
      visible = project.searchAllEntities(entities, searchTerm.objectType, searchTerm.propertyName, searchTerm.propertyValue);
    } else {
      visible = project.allHsbEntities(false);
    }
    visible = visible.reduce((accu, ent) => {
      return ent instanceof HsbElement ? accu.concat(ent.entities, [ent]) : accu.concat([ent]);
    }, []);
    onVisibilityToggle(visible);
  };

  const getObjectTypeOptions = () => {
    const objectTypes = Object.keys(options)?.map((key) => {
      return <FormInputSelectMenuItem key={key} value={key}>{key}</FormInputSelectMenuItem>;
    });
    objectTypes?.unshift(<FormInputSelectMenuItem key={"clear"} value={"any"}>any</FormInputSelectMenuItem>);
    return objectTypes;
  }

  const getPropertyOptions = () => {
    const properties = Object.keys(propertyNameOptions)?.map((key: any) => {
      return <FormInputSelectMenuItem key={key} value={key}>{key}</FormInputSelectMenuItem>;
    });
    properties?.unshift(<FormInputSelectMenuItem key={"clear"} value={"any"}>any</FormInputSelectMenuItem>);
    return properties;
  }

  const getPropertyValueOptions = () => {
    const propertyValues = propertyValueOptions?.map((value: any) => {
      return <FormInputSelectMenuItem key={value} value={value}>{value}</FormInputSelectMenuItem>;
    });
    return propertyValues;
  }

  return (
    <Tab open={open} direction={"left"} onClose={onClose} title={t("SearchPanel.Title")} classes={{ paper: styles["side-panel"] }} loading={!project}>
      <form className={styles["search-container"]} onSubmit={handleSubmit(onValidSubmit)}>
        <FormInputSelect search rules={{ required: !getValues().propertyName }} classes={{ container: styles["input-field"] }} name="objectType" control={control} label={t("SearchPanel.ObjectType")} placeholder={t("SearchPanel.ObjectType")} onChange={(e: any) => handleObjectTypeChange(e.target.value)}>
          {options && getObjectTypeOptions()}
        </FormInputSelect>
        <FormInputSelect search rules={{ required: !getValues().objectType }} classes={{ container: styles["input-field"] }} name="propertyName" control={control} label={t("SearchPanel.PropertyName")} placeholder={t("SearchPanel.PropertyName")} onChange={(e: any) => handlePropertyNameChange(e.target.value)}>
          {getPropertyOptions()}
        </FormInputSelect>
        <FormInputSelect search rules={{ required: getValues().propertyName }} helpertext={t("SearchPanel.PropertyValueError")} classes={{ container: styles["input-field"] }} name="propertyValue" control={control} label={t("SearchPanel.PropertyValue")} placeholder={t("SearchPanel.PropertyValue")} onChange={(e: any) => handlePropertyValueChange()}>
          {getPropertyValueOptions()}
        </FormInputSelect>
        <FormInputText rules={{ required: true }} classes={{ container: styles["text-input-field"] }} name="name" control={control} label={t("SearchPanel.SearchName")} placeholder={t("SearchPanel.SearchName")} onChange={(e: any) => handleNameChange(e.target.value)} endButton={
          <Button disabled={!formState.isValid} className={styles["submit-button"]} type="submit" variant="contained" disableElevation>
            {t("SearchPanel.SaveSearch")}
          </Button>
        }></FormInputText>
        {search?.length > 0 && <Box className={styles["search-list-container"]}>
          <Typography variant="subtitle1" component="div">
            {t("SearchPanel.SavedSearches")}
          </Typography>
          <List>
            {search.map((value) => {
              const labelId = `checkbox-list-label-${value._id}`;
              return (
                <Box key={labelId}>
                  <ListItem disablePadding>
                    <ListItemButton className={`${styles["list-item"]} ${selectedSearch && selectedSearch._id === value._id ? styles["list-item-selected"] : ""}`} onClick={() => selectSearch(value)} dense>
                      <ListItemText id={labelId} primary={value.name} />
                      <Box className={styles["search-actions"]}>
                        <IconButton edge="end" aria-label="comments" onClick={(e: any) => { deleteSearch(e, value._id); }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </ListItemButton>
                    <Divider />
                  </ListItem>
                </Box>
              );
            })}
          </List>
        </Box>
        }
      </form>
      <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
    </Tab>
  );
}

export interface SearchPanelProps {
  open: boolean;
  project: HsbProject;
  projectNode: any;
  onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onVisibilityToggle: (entities: any) => void;
  modelviewer: any;
  allVisualEntities: any;
  onVisibilityChangeEvent: EventEmitter;
}
