import { Tooltip } from '@mui/material';
import { useContainerDimensions } from 'hooks/useContainerDimensions'
import React, { useRef } from 'react'
import styles from '../../styles/OneLineTextDiv.module.css'

export function getTextWidth(text, fontSize = '16px') {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontSize} Roboto, Helvetica, Arial, sans-serif`
    return context.measureText(text).width;
}

const OneLineTextDiv: React.FC<{ text: string, tooltip?: string, hightLightText?: string, className?: string, minWidth?: number, minHeight?: number }> = ({ text, tooltip = '', hightLightText = undefined, className = '', minWidth = 150, minHeight = 150 }) => {
    const componentRef = useRef()
    const { width, } = useContainerDimensions(componentRef, minWidth, minHeight)
    const getDivJSX = () => <div style={{ width: `${width * 0.98}px` }} className={styles.text + ' ' + className}>
        {text} <span className={styles.hightLightText}>{hightLightText}</span>
    </div>

    return <div ref={componentRef} >
        {tooltip ? <Tooltip title={tooltip}>{getDivJSX()}</Tooltip> : getDivJSX()}
    </div>
}

export const TextSizeWithMax: React.FC<{ text: string, className?: string, maxWidth?: number, fontSize?: string }> = ({ text, maxWidth, className = '', fontSize = '16px' }) => {
    const width = Math.min(getTextWidth(text, fontSize), maxWidth)
    return <div style={{ width: width }} className={styles.text + ' ' + className}>
        {text}
    </div>
}

export default OneLineTextDiv;
