import { Box, Button, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Typography } from "@mui/material";
import DialogBox from "../DialogBox";
import { FormInputText } from "../FormComponents/FormInputText";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Close } from "@mui/icons-material";
import styles from "./DeleteDialog.module.scss";

export interface DeleteDialogProps extends DialogProps {
    title?: string;
    description?: string;
    verification?: string;
    buttonText?: string;
    caseSensitive?: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export default function DeleteDialog(props: DeleteDialogProps) {
    const { title, description, verification, buttonText, caseSensitive, onSubmit, onClose, ...dialogProps } = props;
    const { t } = useTranslation("common");
    const { control, reset } = useForm<any>({
        defaultValues: {
            verification: ''
        },
        mode: 'onChange',
    });
    const [verificationComplete, setVerificationComplete] = useState(false);

    const handleVerificationChange = (event: any) => {
        if (caseSensitive) {
            setVerificationComplete(event.target.value === verification);
        } else {
            setVerificationComplete(event.target.value.toLowerCase() === verification.toLowerCase());
        }
    }

    const handleClose = () => {
        reset();
        setVerificationComplete(false);
        onClose();
    }

    const handleSubmit = () => {
        reset();
        setVerificationComplete(false);
        onSubmit();
    }

    return (
        <DialogBox {...dialogProps} onClick={(e: any) => e.stopPropagation()}>
            <DialogTitle variant="error">
                {title || t('DeleteDialog.Title')}
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles['delete-dialog-content-container']}>
                <Typography>{description || t('DeleteDialog.Description')}</Typography>
                {
                    verification && onSubmit &&
                    <Box className={styles['verification-container']}>
                        <Typography>{`To confirm, type "`}<span className={styles['verification']}>{verification}</span>{`" in the box below`}</Typography>
                        <FormInputText control={control} name="verification" onChange={handleVerificationChange} />
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button type="submit" variant="contained" color="error" onClick={handleSubmit} disabled={verification && !verificationComplete}>{buttonText || t('DeleteDialog.Submit')}</Button>
            </DialogActions>
        </DialogBox>
    )
}