import { HsbEntity, Viewer } from "hsbshareviewer";


export enum ImportSetType {
    'ImportSetHsbType.Beam' = 'Beam',//
    'ImportSetHsbType.Sheet' = 'Sheet',
    'ImportSetHsbType.CLT' = 'CLT',
    'ImportSetHsbType.Wall' = 'Wall',
    'ImportSetHsbType.ElementWallSF' = 'StickframeWall',
    'ImportSetHsbType.ElementWallSip' = 'PanelWall',
    'ImportSetHsbType.ElementRoof' = 'RoofElement',
    'ImportSetHsbType.ElementRoofSip' = 'RoofElementPanel',
    'ImportSetHsbType.ElementFloor' = 'FloorElement',
    'ImportSetHsbType.ElementFloorSip' = 'FloorElementPanel',
    'ImportSetHsbType.RoofPlane' = 'RoofPanel',
    'ImportSetHsbType.FloorPlane' = 'FloorPlane',
    'ImportSetHsbType.GenericBody' = 'GenericBody',
    'ImportSetHsbType.MeshBody' = 'MeshBody',
    'ImportSetHsbType.HsbItem' = 'HsbItem',
}



const _getEntitity = (project: any, modelId: string, importSetRef) => {
    const entitiesTable = project?.EntityTable[modelId] ?? {};
    const modelEntity = entitiesTable[importSetRef.referenceId ?? importSetRef.handle];
    let ifcEntity = Object.values(entitiesTable).find((entity: any) => entity.ifcid === importSetRef.ifcId);
    return { ifcEntity, modelEntity };
}
export const getImportSetEntities = (
    viewer: Viewer, modelReferences: Record<string, { handle?: string, ifcId?: string }[]>
) => {
    if (!viewer?.project || !modelReferences) {
        return [];
    }
    const entities = Object.keys(modelReferences)?.reduce((acc, moduleId) => {
        const modelReferenceIds = (modelReferences[moduleId] ?? []).filter(ref => ref !== undefined);

        modelReferenceIds.forEach(reference => {
            const entityPair = _getEntitity(viewer.project, moduleId, reference);
            if (entityPair.modelEntity) {
                acc.push(entityPair.modelEntity);
            }
            else if (!entityPair.modelEntity && entityPair.ifcEntity) {
                //if the no model entity is found then add the ifc entity.
                acc.push(entityPair.ifcEntity);
            }
        });
        return acc;
    }, []);

    return entities;
}
const getEntityReference = (entity: HsbEntity): { handle?: string, ifcId?: string } => {
    let reference: { handle?: string, ifcId?: string } = entity.handle ? { handle: entity.handle } : {};
    reference = entity.ifcid ? { ...reference, ifcId: entity.ifcid } : { ...reference };
    return reference;
}
export const getEntitiesImportSetReferences = (entities: HsbEntity[]) => entities.reduce(
    (acc, entity): Record<string, { handle?: string, ifcId?: string }[]> => {
        const modelId = entity.hsbmodel.modelId;
        if (acc.hasOwnProperty(modelId)) {
            acc[modelId].push(getEntityReference(entity));
        } else {
            acc[modelId] = [getEntityReference(entity)];
        }
        return acc;
    }, {});