import React from 'react'
import { Button, ButtonProps, styled } from '@mui/material' 



const StyledButton = styled(Button)(({theme}) => ({
    borderRadius: 25,
    textTransform: 'none',
    padding: '5px 40px',
}))



const RoundedButton : React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
    return (
        <StyledButton {...props} > 
            { props.children }
        </StyledButton>
    )
}

export default RoundedButton