import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import React from 'react';

export default function Ripple(props: { parentRef: HTMLElement }) {
    let { parentRef } = props;
    const rippleRef = React.useRef(null);

    const onRippleStart = (e: globalThis.MouseEvent) => {
        rippleRef.current.start(e);
    };
    const onRippleStop = (e: any) => {
        if (e.type === 'mouseout' && parentRef?.contains?.(e.toElement))
            return;
        rippleRef.current.stop(e);
    };
    React.useEffect(() => {
        let currRef
        if (parentRef && parentRef.getAttribute('rippleListener') !== 'true') {
            currRef = parentRef;
            parentRef?.addEventListener('mousedown', onRippleStart);
            parentRef?.addEventListener('mouseout', onRippleStop);
            parentRef?.addEventListener('mouseup', onRippleStop);
            parentRef.setAttribute('rippleListener', 'true');
        };
        return () => {
            currRef?.setAttribute('rippleListener', 'false');
            currRef?.removeEventListener('mousedown', onRippleStart);
            currRef?.removeEventListener('mouseout', onRippleStop);
            currRef?.removeEventListener('mouseup', onRippleStop);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentRef]);

    return (
        <TouchRipple ref={rippleRef} center={false} />
    );
}
