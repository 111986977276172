import { Accordion, AccordionSummary, List, ListItem, ListItemText, Typography, Box } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { Property, HsbElement } from 'hsbshareviewer';
import { useTranslation } from 'react-i18next';
import Tab from 'components/Shared/Tab';
import { KeyboardArrowDown } from '@mui/icons-material';
import Cookies from 'universal-cookie';
import { fetchSettings, UserSettingsContext } from 'context/UserSettingsContext';
import formatter from 'services/formatting';
import useSession from 'hooks/useSession';
import styles from './PropertiesPanel.module.scss'

export default function PropertiesPanel({
    open,
    container,
    selection: inSelection,
    selectionMode,
    multiSelect,
    onClose,
}: PropertiesPanelProps) {
    const { isGuest } = useSession();
    const [selection, setSelection] = useState(null);
    const { t } = useTranslation('common');
    const [decimalPoint, setDecimalPoint] = useState(null);
    const { settings } = useContext(UserSettingsContext);

    useEffect(() => {
        setSelection(inSelection);
        //TODO: get from settings instead of cookies
        const cookies = new Cookies();
        setDecimalPoint(cookies.get('settings_decimal_point'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inSelection]);

    useEffect(() => {
        let prefSettings = settings?.preferenceSettings;
        if (!isGuest() && !prefSettings) {
            fetchSettings().then((fetchedSettings) => {
                formatter.unitSettings = fetchedSettings?.preferenceSettings.unitSettings;
            }).catch((err) => {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
                    console.error('Propertiespanel: Could not fetch settings', err);
                else console.error('Propertiespanel: Could not fetch settings');
            });
        } else formatter.unitSettings = settings?.preferenceSettings.unitSettings;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings]);

    /**
     * Interprets the passed selection in the props and returns the properties as a map
     * @returns {JSX.Element} A list containing all properties, noted 'VARIES' if a property is not uniform.
     */
    const getProperties = () => {
        // DATA
        let entities = selection;
        // If multi select is on we need to pass this test
        if (multiSelect) {
            let elements: any[] = entities.filter((e) => e instanceof HsbElement);
            elements.forEach((element) => {
                // If all children of an element are selected + the element itself
                // We filter those childrne out of the selection
                let children = element.entities;
                let test = children.some((child) => !entities.includes(child));
                if (test) {
                    entities = entities.filter((e) => !children.includes(e));
                }
            });
        }
        let properties: Property[] = entities.map((e) => e._properties).flat();
        let output: Map<string, Map<string, any>> = new Map();
        properties.forEach((prop) => {
            // If not trans we just use the actual value
            if (!prop._translationCategory) {
                prop._translationCategory = prop._category;
            }

            if (!prop._translationKey) {
                prop._translationKey = prop._key;
            }

            if (!output.has(prop._translationCategory)) {
                output.set(prop._translationCategory, new Map<string, any>());
            }

            if (output.get(prop._translationCategory).has(prop._translationKey)) {
                let value = output.get(prop._translationCategory).get(prop._translationKey);
                if (prop._value !== value) {
                    output.get(prop._translationCategory).set(prop._translationKey, '*' + t('Property.Varies').toUpperCase() + '*');
                }
            } else {
                output.get(prop._translationCategory).set(prop._translationKey, prop._value);
            }
        });

        // VIEW
        return (
            <List sx={{ paddingTop: '0px' }}>
                {Array.from(output).map(([catKey, catValue], index) => (
                    <Accordion key={'prop-' + index} defaultExpanded={true} disableGutters={true} elevation={0}>
                        <AccordionSummary expandIcon={<KeyboardArrowDown />} id={`catid-${catValue}`} sx={{ backgroundColor: '#EEEEEE' }}>
                            <Typography sx={{ color: 'text.primary', fontWeight: 'bold' }}>{t(catKey)}</Typography>
                        </AccordionSummary>
                        {Array.from(catValue).map(([key, value]) => {
                            let val = value;
                            // CHeck if we have a type of measurement
                            let measure = [
                                'length',
                                'weight',
                                'area',
                                'volume',
                                'width',
                                'height',
                                'distance',
                                'thickness',
                                'thicknes',
                                'inset',
                            ].find((m) => key.toLowerCase().includes(m));
                            // These all fall under length
                            if (['width', 'height', 'distance', 'thickness', 'thicknes', 'inset'].includes(measure)) {
                                measure = 'length';
                            }
                            // If measurement we need to convert it
                            if (measure && typeof val === 'number') {
                                val = formatter.format(val, { measure: measure });
                            }

                            return (
                                <ListItem key={`item-${catKey}-${key}`} divider={true}>
                                    <ListItemText
                                        primary={t(key)}
                                        primaryTypographyProps={{
                                            color: 'text.primary',
                                            fontWeight: 'bold',
                                        }}
                                    />
                                    <Typography>{`${val === '' ? '-' : isNaN(val) ? val : Number(val).toFixed(decimalPoint)}`}</Typography>
                                </ListItem>
                            );
                        })}
                    </Accordion>
                ))}
            </List>
        );
    };
    return (
        <Tab open={open} container={container} direction={'right'} onClose={onClose} title={t('Property.Properties')}>
            {selection?.length > 0 ? (
                getProperties()
            ) : (
                <Box className={styles['no-selection']}>
                    <Typography variant="subtitle1">{t('Property.NoSelection')}</Typography>
                </Box>
            )}
        </Tab>
    );
}

interface PropertiesPanelProps {
    open: boolean;
    onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
    selection: any;
    selectionMode: 'elements' | 'entities';
    multiSelect: boolean;
    container?: Element | (() => Element);
}
