import TeamListController from '../Teams/TeamListController'
import TeamMemberListController from './TeamMemberListController'
import TeamMemberInfoController from './TeamMemberInfoController'
import styles from '../../styles/TeamsController.module.css'
import TeamRolesController from './TeamRolesController'
import amITeamAdminAtom from 'atoms/amITeamAdmin'
import { useRecoilValue } from 'recoil'
import { Box } from '@mui/material'

export default function TeamsController() {
    const amITeamAdmin = useRecoilValue(amITeamAdminAtom)
    return (
        <Box className={`${styles.teamControllerBox} ${amITeamAdmin ? styles.admin : styles.noAdmin}`}>
            <Box style={{ gridArea: 'teams' }}  >
                <TeamListController />
            </Box>
            <Box style={{ gridArea: 'members', transition: 'height 1s' }}  >
                <TeamMemberListController />
            </Box>
            <Box style={{ gridArea: 'info' }}>
                <TeamMemberInfoController />
            </Box>
            {amITeamAdmin &&
                <Box style={{ gridArea: 'roles' }} >
                    <TeamRolesController />
                </Box>}
        </Box>)
}
