import { Grid, Typography } from '@mui/material'
import LanguageBar from './LanguageBar'
import { useTranslation } from "react-i18next";

export default function HalfFooter() {
    const { t } = useTranslation('common');

    return (
        <Grid container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            item xs={12}>
            <Typography style={{ color: 'rgb(139, 139, 139, 0.6)', paddingBottom: 6, fontSize: 14, fontWeight: 400 }}>{t('footer.copyright')}</Typography>
            <LanguageBar />
        </Grid>
    )
}
