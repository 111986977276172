import { Box, CircularProgress, IconButton, ListItem, Stack, Tooltip, Typography } from '@mui/material'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import selectedTeamAtom from '../../atoms/selectedTeamAtom'
import EditIcon from '@mui/icons-material/Edit'
import styles from 'styles/TeamItem.module.scss'
import { FormInputAvatar } from 'components/Shared/FormComponents/FormInputAvatar'
import { useForm } from 'react-hook-form'
import UserAvatar, { TeamAvatar } from 'components/Shared/AvatarObject'
import restAPI from 'services/rest-api'
import { FormInputText } from 'components/Shared/FormComponents/FormInputText'
import amITeamAdminAtom from 'atoms/amITeamAdmin'
import CheckIcon from '@mui/icons-material/Check';
import SnackBarMessage, { ISnackBarData } from 'components/Shared/snackBar/SnackBarMessage'

export interface ITeamItem {
    teamID: string,
    name: string,
    teamPicture: TeamAvatar,
    isActive: boolean,
    avatar?: UserAvatar,
    isAdmin: boolean,
    admins?: any[],
    users?: any[],
    isEdit?: boolean,
    setEditMode?: (teamId: string) => void
}

export interface TeamItemProps {
    index: number,
    remove: (propertyNameId: string, id: string, teamName: string) => void,
    update: (propertyNameId: string, id: string, editPropertyName: string, editPropertyValue: any) => void,
    onLeave: (teamID: string) => void
}

export const TeamItem: React.FC<ITeamItem & TeamItemProps> = (props: ITeamItem & TeamItemProps) => {
    const [stateSnackBar, setSnackBar] = useState<ISnackBarData>({ open: false, severity: 'success', message: '' })
    let { teamID, name, teamPicture, isActive, index, isAdmin, remove, update, onLeave, isEdit, setEditMode } = props
    const { handleSubmit, control, setValue, reset, getValues, } = useForm<{ avatar: TeamAvatar, teamName: string }>({
        defaultValues: {
            avatar: null,
            teamName: ""
        },
        mode: 'onSubmit'
    })
    const { t } = useTranslation('common')
    const [newName, setNewName] = useState(name)
    const [selectedTeam, setSelectedTeam] = useRecoilState<any>(selectedTeamAtom)
    let [, setAmITeamAdmin] = useRecoilState(amITeamAdminAtom)
    const teamItemRef = useRef();
    let [loading, setLoading] = useState(false);

    const renameTeam = (e: any) => {
        e?.stopPropagation();
        setEditMode(teamID);
        if (isEdit) {
            onSubmit(getValues(), null);
        }
    }

    const select = (teamID: string) => {
        if (selectedTeam?.teamID === teamID && selectedTeam?.selectedIndex === index) {
            return;//already selected
        }
        setLoading(true)

        restAPI.isTeamAdmin(teamID).then(result => {
            const isTeamAdmin = result?.[0] ?? false
            setAmITeamAdmin(isTeamAdmin)
            setSelectedTeam({ teamID: teamID, name, selectedIndex: index })
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            console.error('isTeamAdmin REST error: ' + error)
        })
    }

    const deleteTeam = (e: any) => {
        e?.stopPropagation()
        remove('teamID', teamID, name)
        setSelectedTeam(null);
    }

    const leaveTeam = () => {
        if (onLeave)
            onLeave(teamID);
    }

    const onChangeAvatar = (data: TeamAvatar) => {
        if (data)
            restAPI.setTeamAvatar(teamID, data.URI, data.color)
    }

    const onSubmit = async (data, e) => {
        setEditMode(teamID);
        if (data.teamName?.substring(0, 1).toUpperCase() !== newName?.substring(0, 1).toUpperCase()) {
            setValue('avatar.letter', data.teamName?.substring(0, 1).toUpperCase());
        }
        setNewName(data.teamName);
        update('teamID', teamID, 'teamName', data.teamName);
        await restAPI.setTeamName(teamID, data.teamName);
        setSnackBar({ open: true, severity: 'success', message: t('InfoMessage.TeamNameUpdated', { newName: data.teamName }) });
    }

    const onInvalidSubmit = (data, e) => {
        console.error(data, e)
    }

    const handleBlur = (e) => {
        reset({ ...getValues(), teamName: newName });
        setEditMode(teamID);
    }

    useEffect(() => {
        let avatar = {
            URI: teamPicture?.URI ?? '',
            letter: newName?.substring(0, 1).toUpperCase(),
            color: teamPicture?.color
        };
        reset({ avatar: avatar, teamName: newName ?? name })
        return () => {

        }
    }, [isActive, name, newName, reset, teamPicture?.URI, teamPicture?.color])

    useEffect(() => {
        if (teamItemRef && teamItemRef.current && teamID === selectedTeam?.teamID && selectedTeam?.init === true) {
            const elem: any = teamItemRef?.current
            elem?.scrollIntoView({ block: "end", inline: "nearest" })
        }
    }, [selectedTeam, teamID])

    return (
        <div>
            <ListItem ref={teamItemRef} onDoubleClick={(e) => renameTeam(e)} id={teamID} onClick={(e) => select(teamID)} className={`${styles.onhover} ${styles.box} ${styles.teamItem}`} selected={selectedTeam?.selectedIndex === index} button>
                {loading && (
                    <CircularProgress
                        size={68}
                        sx={{
                            position: 'absolute',
                            top: '6px',
                            marginLeft: '-2px',
                            zIndex: 9000
                        }}
                    />
                )}
                <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)} className={styles.form}>
                    <FormInputAvatar name="avatar" control={control} setValue={setValue} getValues={getValues} size={"64px"} onChange={onChangeAvatar} disabled={!isAdmin} />
                    <div className={styles.teamName}>
                        {isEdit
                            ? <Stack direction={'row'} justifyContent='flex-start' alignItems={'center'}>
                                <FormInputText sx={{ width: '150px' }} name={'teamName'} variant='standard' control={control} onBlur={handleBlur} autoFocus onFocus={event => setTimeout(() => event.target.select(), 1)} />
                                <Tooltip title={t("DoneButton.Done")}>
                                    <IconButton type='submit'>
                                        <CheckIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            : <Typography noWrap variant="h6" >{newName}</Typography>
                        }
                    </div>
                </form>
                {isActive &&
                    <Box className={styles.activeTeamIndicator}>
                        {t('TeamList.ActiveParentheses')}
                    </Box>}
                <div className={styles.teamItemActions}>
                    <Tooltip title={t('TeamList.LeaveTeam')?.toString()}>
                        <ExitToAppIcon onClick={leaveTeam} className={styles.hiddenChild} />
                    </Tooltip>
                    {isAdmin &&
                        <Tooltip title={t('TeamList.Rename')?.toString()}>
                            <EditIcon onClick={(e) => renameTeam(e)} className={styles.hiddenChild} />
                        </Tooltip>}
                    {isAdmin &&
                        <Tooltip title={t('TeamList.DeleteTeam')?.toString()}>
                            <DeleteOutlineIcon onClick={deleteTeam} className={styles.hiddenChild} />
                        </Tooltip>}
                </div>
            </ListItem>
            <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
        </div>
    )
}
