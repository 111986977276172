import { Box, Stack, styled } from '@mui/material';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from 'components/Projects/ProjectPage.module.scss';
import ProjectItem from 'components/Projects/ProjectItem';
import { useEffect, useState } from 'react';
import restAPI from 'services/rest-api';

const MediaList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row',
    position: 'relative',
    minHeight: '1px',
}));

export default function ProjectList(props: any) {
    const { t } = useTranslation('common');
    const [lastUsedProjects, setLastUsedProjects] = useState([]);

    const handleProjectDelete = (projectId: string) => {
        setLastUsedProjects(lastUsedProjects.filter(project => project.projectDB?.projectID !== projectId));
    }

    useEffect(() => {
        let mounted = true;
        let loadingProjects = [];
        for (let i = 0; i < 20; i++) {
            loadingProjects[i] = null;
        }
        setLastUsedProjects(loadingProjects);
        restAPI.getLastOpenedProjects().then((lastUserOpenedProjects) => {
            if (!mounted) return;
            setLastUsedProjects(lastUserOpenedProjects);
        }).catch((error) => {
            console.error('error from server getting last opened projects: ' + error);
        });

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setLastUsedProjects]);

    return (
        <Box>
            <Stack sx={{ marginTop: 0, padding: 0 }} direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                <Typography variant="h5" sx={{ paddingLeft: '15px', lineHeight: '40px' }}>
                    {t('ProjectList.RecentProjects')}
                </Typography>
                <Link to="/projects">
                    <Typography sx={{ marginRight: '-7px', color: 'rgba(0,0,0,0.87)', padding: 0 }} variant="body1">
                        {t('ProjectList.SeeAllProjects')}
                        <ChevronRightIcon style={{ marginBottom: -8 }} />
                    </Typography>
                </Link>
            </Stack>
            <PerfectScrollbar
                style={{ width: '100%' }}
                options={{ useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false }}>
                <MediaList className={styles['media-list']}>
                    {
                        lastUsedProjects.map((project, index) => {
                            return <ProjectItem
                                key={`project-item-${project?.projectDB?.projectID ?? index}`}
                                state={project?.projectDB?.projectState ?? 'open'}
                                notifications={project?.openedIssues?.length ?? 0}
                                projectId={project?.projectDB?.projectID}
                                projectName={project?.projectDB?.name}
                                teamName={project?.teamDB?.name}
                                thumbnail={project?.projectDB?.nodeModel?.thumbnailDataUrl}
                                updatedText={t('ProjectList.LastOpenedOnThenDate') + ' ' + new Date(project?.openedOn).toLocaleDateString()}
                                onProjectDelete={handleProjectDelete}
                            />
                        })
                    }
                </MediaList>
            </PerfectScrollbar>
        </Box>
    );
}
