import useSession from 'hooks/useSession';
import { useEffect, useRef, useState } from 'react';
import useQuery from '../../hooks/userQuery';
import restAPI from '../../services/rest-api';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import styles from './NewAccount.module.scss';
import { CircularProgress,  Typography } from '@mui/material';

enum AccessState {
    'initial',
    'empty',
    'success',
    'error'
}

export default function NewAccount() {
    const query = useQuery();
    const { setSession } = useSession();
    const navigate = useNavigate();
    const [state, setState] = useState<AccessState>(AccessState.initial);
    const [countdown, setCountdown] = useState(999);
    const countdownInterval = useRef(null);

    const startCountdown = ()=>{
        setCountdown(5);
        countdownInterval.current = setInterval(() => {
            setCountdown((c) => Math.max(c - 1,0));
        }, 1000);
    }

    useEffect(() => {
        if (countdownInterval.current) return;
        const createAccountToken = query.get('access_token');
        if (!createAccountToken) {
            setState(AccessState.empty);
            startCountdown();
            return;
        }
        restAPI
            .getSessionTokenFromNewAccountToken(createAccountToken)
            .then((response) => {
                setState(AccessState.success);
                const session = response?.data?.user;
                if (!session) {
                }
                setSession(session);
                startCountdown();
            })
            .catch((err) => {
                console.error("New account error",err);
                setState(AccessState.error);
                startCountdown();
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (countdown <= 0) {
            clearInterval(countdownInterval.current);
            navigate('/');
        }
    }, [countdown, navigate]);

    return (
        <Box className={styles['new-account-container']}>
            <Box className={styles['new-account-content']}>
                <Box className={styles['fetching-data-container']}>
                    <Typography variant="h4">{'Setting up account'}</Typography>
                    <CircularProgress className={styles['fetching-data-loading']} />
                </Box>
                <Box className={styles['info-container']}>
                    {state === AccessState.initial && <Typography className={styles['info-text']} variant="caption">{`Fetching account info`}</Typography>}
                    {state === AccessState.empty && (
                        <Typography className={styles['error-text']} variant="caption">
                            {`No access token provided! Redirecting to homepage in ${countdown}`}
                        </Typography>
                    )}
                    {state === AccessState.success && (
                        <Typography className={styles['success-text']} variant="caption">
                            {`Success! Redirecting to homepage in ${countdown}`}
                        </Typography>
                    )}
                    {state === AccessState.error && (
                        <Typography className={styles['error-text']} variant="caption">
                            {`Something went wrong! Redirecting to homepage in ${countdown}`}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
