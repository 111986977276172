import { Grid } from '@mui/material'
import ForgotPassword from '../components/Password/ForgotPassword'
import styles from '../styles/ForgotPasswordPage.module.css'

export default function ForgotPasswordPage() {
    return (
        <Grid className={styles.grid} container>
            <Grid item xs={12} sm={12} md={5}>
                <div className={styles.loginSidePanel}>
                    <ForgotPassword />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7} className={`${styles.imageSidePanel} ${styles.responsive} `}>
                <div className={styles.clipi} style={{ position: 'relative', height: '100vh' }}>
                </div>
            </Grid>
        </Grid>
    )
}