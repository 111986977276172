import { FormInputSelect } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelect';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import API from 'services/rest-api';
import { Add, Close } from '@mui/icons-material';
import { DialogTitle, IconButton, DialogContent, DialogActions, Button } from '@mui/material';
import DialogBox from 'components/Shared/DialogBox';
import { FormInputSelectMenuItem } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem';

const AddProjectDialog: React.FC<{ onClose: () => void; isOpen: boolean; onCreateProject: (data: any) => void }> = ({
    onClose,
    isOpen,
    onCreateProject,
}) => {
    const { t } = useTranslation('common');
    const [teams, setTeams] = useState(null);
    const { reset, control, handleSubmit } = useForm({
        defaultValues: {
            addProjectDialog: {
                name: '',
                teamId: '',
            },
        },
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
    });

    useEffect(() => {
        API.getUserTeams()
            .then((teamsResponse) => {
                setTeams(teamsResponse.data.teams);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const handleValidSubmit = (data: any, e: any) => {
        onCreateProject(data.addProjectDialog);
        handleCancel();
    };

    const handleInvalidSubmit = (...data: any) => {
        console.error('Invalid:', data);
    };

    const handleCancel = (...data: any) => {
        onClose();
        reset({
            addProjectDialog: {
                name: '',
                teamId: '',
                // state: '' //not yet needed
            },
        });
    };

    const getTeamOptions = () => {
        return teams?.map((value, index) => (
            <FormInputSelectMenuItem key={value.teamID} value={value.teamID}>
                {value.name}
            </FormInputSelectMenuItem>
        ));
    };

    return (
        <DialogBox aria-labelledby="add-project-dialog-title" open={isOpen} onClose={handleCancel}>
            <form onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}>
                <DialogTitle id="add-project-dialog-title">
                    {t('AddProjectDialog.Title')}
                    <IconButton onClick={handleCancel}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormInputText
                        rules={{ required: true }}
                        name="addProjectDialog.name"
                        control={control}
                        label={t('AddProjectDialog.Name')}
                        sx={{ marginBottom: '12px' }}
                    />
                    <FormInputSelect
                        search
                        rules={{ required: true }}
                        name="addProjectDialog.teamId"
                        control={control}
                        label={t('AddProjectDialog.Team')}
                        placeholder={t('AddProjectDialog.TeamPlaceholder')}
                        sx={{ marginBottom: '12px' }}>
                        {getTeamOptions()}
                    </FormInputSelect>
                </DialogContent>
                <DialogActions>
                    <Button key="submitButton" variant="contained" type="submit">
                        <Add />
                        {t('AddProjectDialog.Submit')}
                    </Button>
                </DialogActions>
            </form>
        </DialogBox>
    );
};
export default AddProjectDialog;
