import { Box, Paper, Typography } from '@mui/material'
import List from '@mui/material/List'
import React from 'react'
import { ActivityItemHeader } from './ActivityItemHeader'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styles from 'styles/HomeLayout.module.scss'
import { useTranslation } from 'react-i18next'

const ActivityList: React.FC<{ activity: string, children: Array<React.ReactNode> }> = ({ activity, children }) => {
    const { t } = useTranslation("common");

    const getEmptyListIcon = () => {
        var endpoint = null;
        switch (activity) {
            case 'Activity.AssignedToMe':
                endpoint = "no-assign-icon.svg";
                break;
            case 'Activity.CreatedByMe':
                endpoint = "no-created-icon.svg";
                break;
            case 'Activity.LatestActivity':
                endpoint = "no-activity-icon.svg";
                break;
        }
        return process.env.PUBLIC_URL + '/icons/' + endpoint;
    }

    return (
        <Paper elevation={2} sx={{ height: "100%" }}>
            <ActivityItemHeader activity={activity} />
            {
                children?.length > 0 ?
                    <PerfectScrollbar className={styles['scroll-container']}>
                        <List style={{ overflowX: 'auto' }}>
                            {children?.map((listItem: any) => listItem)}
                        </List>
                    </PerfectScrollbar> :
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'calc(100% - 56px)'} overflow={'hidden'}>
                        <img className={styles['empty-icon']} src={getEmptyListIcon()} alt="Icon for activity list" />
                        <Typography sx={{ fontSize: '18px', textAlign: 'center' }}>{t("Activity.NoActivities")}</Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: '400', textAlign: 'center' }}>{`${t(activity + "Help")}`}</Typography>
                    </Box>
            }
        </Paper>
    )
}

export default ActivityList
