import constants from '../services/constants';
import { atom } from 'recoil';
import { ISelectedTeamMember } from './selectedTeamMemberAtom';

export interface ISelectedTeam {
    teamID: string,
    name: string,
    selectedIndex: number,
    selectedTeamMember?: ISelectedTeamMember,
    init?: boolean
}

const selectedTeamAtom = atom<ISelectedTeam | null>({
    key: constants.selectedTeamAtom,
    default: null
});

export default selectedTeamAtom;