import React from 'react'

export class LogoProps {
    width?: number | string = 'auto'
}

export default function HsbShareLogo(props: LogoProps) {
    let { width, ...imageProps } = props;
    return (
        <img alt="hsbShare logo" src="/img/hsbShareLogo.svg" width={width} {...imageProps} />
    )
}