import React from 'react'
import styles from '../../styles/LoginPanel.module.scss'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Link } from 'react-router-dom'
import respAPI from '../../services/rest-api'
import { useFormik } from 'formik'
import SnackBarMessage, { ISnackBarData } from '../Shared/snackBar/SnackBarMessage'
import { useTranslation } from "react-i18next"
import HalfFooter from '../Shared/HalfFooter'
import { Box } from '@mui/material'
import constants from '../../services/constants'
import RoundedButton from '../Shared/RoundedButton'
import HsbShareLogo from 'components/Shared/HsbShareLogo'
import useSession from 'hooks/useSession'

export default function Login() {
    const { t } = useTranslation('common');
    const { setSession } = useSession();
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [stateSnackBar, setSnackBar] = React.useState<ISnackBarData>({ open: false, severity: 'success', message: '' });

    const validate = (values: any) => {
        let errors: any = {}
        if (!values.email) {
            errors.email = 'Email cannot be empty'
        }
        if (!values.password) {
            errors.password = 'Password cannot be empty'
        }
        return errors;
    }

    const formik = useFormik({
        validate: validate,
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: async (values) => {
            try {
                const response = await respAPI.login(values.email, values.password)
                const session = response?.data?.user;
                setSession(session);
            } catch (error: any) {
                if (error?.response?.data?.message === 'Invalid credentials' && error?.response?.status === 401) {
                    setSnackBar({ open: true, severity: 'error', message: t('login.invalidEmailPassword') })
                }
                else if (error.response?.status === 502) {
                    setSnackBar({ open: true, severity: 'error', message: t('Server.networkError') })
                }
                else {
                    console.error(JSON.stringify(error?.response?.data || 'unknown error'));
                    setSnackBar({ open: true, severity: 'error', message: error?.response?.data?.message })
                }
            }
        },
    });

    return (
        <Grid className={styles.grid} flexDirection={'column'} justifyContent={'space-between'} display={'flex'}>
            <Box>
                <HsbShareLogo width={222} />
            </Box>
            <Box>
                <form onSubmit={formik.handleSubmit}>
                    <Grid item xs={12}>
                        <Typography {...constants.pageHeading} variant="h3">
                            {t('login.title')}
                        </Typography>
                        <Typography  {...constants.pageText} gutterBottom>{t('login.shareShortDescription')}</Typography>
                    </Grid>
                    <Grid item style={{ paddingTop: 10 }} xs={12}>
                        <Box {...constants.vSpacing}><Divider className={styles.divider}></Divider></Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={0}
                            className={styles.section}>
                            <Grid item xs={12}>
                                <FormControl {...constants.vSpacing} variant="outlined" fullWidth>
                                    <Typography variant="caption" gutterBottom>{t('login.email')}</Typography>
                                    <OutlinedInput id="email" onChange={formik.handleChange} value={formik.values.email} required type="email" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <Typography variant="caption" gutterBottom>{t('login.password')}</Typography>
                                    <OutlinedInput id="password" onChange={formik.handleChange} required type={showPassword ? 'text' : 'password'}
                                        value={formik.values.password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    onMouseDown={(event) => event.preventDefault()}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid style={{ paddingTop: 10 }} item xs={12}>
                                <div className={styles.corners}>
                                    <span>&nbsp;</span>
                                    <Link className={styles.forgot} to="/forgot-password">{t('login.forgotPassword')}</Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ paddingTop: 10 }} item xs={12}>
                        <div className={styles.section}>
                            <RoundedButton type="submit" variant="contained">{t('login.login')}</RoundedButton>
                        </div>
                    </Grid>
                    <Grid className={`${styles.account}`} item xs={12}>
                        <Typography variant="caption" gutterBottom>{t('login.notRegisteredYet')}&nbsp;<Link to="/create-account">{t('login.createAccount')}</Link></Typography>
                    </Grid>
                </form>
            </Box>
            <Box>
                <HalfFooter />
            </Box>
            <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
        </Grid>
    )
}
