import React from "react"
import { useFormik } from "formik"
import styles from "../../styles/ResetPassword.module.css"
import respAPI from "../../services/rest-api"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import HalfFooter from "../Shared/HalfFooter"
import { Box, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Typography, } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import constants from "../../services/constants"
import RoundedButton from "components/Shared/RoundedButton"
import HsbShareLogo from "components/Shared/HsbShareLogo";
import SnackBarMessage, { ISnackBarData } from '../Shared/snackBar/SnackBarMessage'


export default function ResetPassword() {
  const { t, } = useTranslation("common");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [stateSnackBar, setSnackBar] = React.useState<ISnackBarData>({ open: false, severity: 'success', message: '' });
  const urlParams = new URLSearchParams(window.location.search);
  const tempPass = urlParams.get("tempPassword");
  const email = urlParams.get("email");

  const validate = (values: any) => { 
    const errors : any = {};
  
    if (!values.password) {
      errors.password = 'Required';
    } 
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Required';
    } 
    // if (values.password !== values.confirmPassword) {
    //   errors.confirmPassword = 'Passwords must match';
    //   setSnackBar({ open: true, severity: 'error', message: 'Passwords must match' })
    // } 
  
    return errors;
  };

  const formik = useFormik({
    validate: validate,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      try {
            if (values.password !== values.confirmPassword) {
              return setSnackBar({ open: true, severity: 'error', message: 'Passwords must match' });
            } 
            await respAPI.resetUserPassword(
              email,
              values?.confirmPassword?.trim(),
              tempPass
        );
        setSnackBar({ open: false, severity: 'success', message: ''});
        setSnackBar({ open: true, severity: 'success', message: 'The password has been updated. Redirecting to login page...' });
        setTimeout(() => navigate('/login'), 3000);
      } catch (error) { 
        console.error(error);
        setSnackBar({ open: true, severity: 'error', message: 'Error resetting password: ' + JSON.stringify(error) })
      }
    },
  });

  return (
    <Grid className={styles.grid} flexDirection={'column'} justifyContent={'space-between'} display={'flex'}>
      <Box>
        <HsbShareLogo width={222} />
      </Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid item xs={12}>
            <Typography>
              <Link to="/login"> {t("resetpassword.back")} </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h1" {...constants.pageHeading}>{t("resetpassword.title")}</Typography>
            <Typography {...constants.pageText} style={{ paddingBottom: 15 }}>
              {t("resetpassword.undertitle")}.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 15 }}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="caption" gutterBottom>
                {t("resetpassword.password")}
              </Typography>
              <OutlinedInput
                size="small"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                required
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      className={styles.iconbtn}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="caption" gutterBottom>
                {t("resetpassword.confirmpassword")}
              </Typography>
              <OutlinedInput
                size="small"
                id="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                required
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      className={styles.iconbtn}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid style={{ paddingTop: 10 }} item xs={12}>
            <div className={styles.section}>
              <RoundedButton type="submit" variant="contained">
                {t("resetpassword.title")}
              </RoundedButton>
            </div>
          </Grid>
        </form>
      </Box>
      <Box>
        <HalfFooter />
      </Box>
      <SnackBarMessage severity={stateSnackBar.severity} message={stateSnackBar.message} open={stateSnackBar.open} onSetOpen={setSnackBar} />
    </Grid>
  );
}