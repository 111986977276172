import LoginPage from "../pages/LoginPage";
import CreateAccountPage from "../pages/CreateAccountPage";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./Shared/PrivateRoute";
import NewAccount from "./CreateAccount/NewAccount";
import Home from "../pages/Home";
import setInterceptors from "../services/setInterceptors";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import MailSentPage from "../pages/MailSentPage";
import NotFoundPage from "../pages/NotFoundPage";
import ProjectPage from "pages/ProjectPage";
import TermsAndContionsPage from "pages/TermsAndConditionsPage";

const RouterRoot = function () {
  setInterceptors();

  return (
    <Routes>
      
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/create-account" element={<CreateAccountPage />} />
      <Route path="/new/account" element={<NewAccount />} />
      <Route path="/:component/:id" element={<ProjectPage />} />
      <Route path="/terms" element={<TermsAndContionsPage />} />
      <Route path="/:component" element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/email-sent" element={<MailSentPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default RouterRoot;
