import styles from './HierarchyPanel.module.scss'
import { TreeItem } from "@mui/lab";
import { useState, useEffect } from "react";
import { NodeData } from './NodeData';
import NodeLabel from './ConsoleLabel';

function sortNodesAlphabeticallyIncNumeric(a: NodeData, b: NodeData) {
    let aNr = parseInt(a.name);
    let bNr = parseInt(b.name);
    if (isNaN(aNr) || isNaN(bNr))
        return a.name.localeCompare(b.name);
    return aNr - bNr;
}

export default function ConsoleNode(props: ConsoleNodeProps): JSX.Element {
    let { node, onVisibility, onHover, searchTerm, onIsolation, isolatedHandle, rootSelection } = props;
    const [visibleTrigger, setVisibleTrigger] = useState(true);

    const handleVisibility = (e: React.MouseEvent) => {
        e.stopPropagation();
        setVisibleTrigger(!node.visible);
        onVisibility(node, node.visible);
    }

    const handleHover = (state: boolean) => {
        if (onHover) {
            onHover(node, state);
        }
    }

    // Update the visibility icon if necessary
    useEffect(() => {
        if (visibleTrigger !== node.visible) {
            setVisibleTrigger(node.visible);
        }
    }, [node.visible, visibleTrigger])

    return (
        <TreeItem key={"TreeItem-" + node.name + "-" + node.dbId} id={"TreeItem-" + node.dbId} sx={{ "& .MuiTreeItem-content": { paddingLeft: `${node.level * 24}px !important` } }}
            classes={{ content: styles['content-node'], selected: node.dbId.toString() === rootSelection ? styles["selected-root-node"] : styles['selected-node'], group: styles['group-node'], iconContainer: styles['icon-node'], focused: styles['focused-node'] }}
            nodeId={node.dbId.toString()}
            label={
                <NodeLabel node={node} onVisibility={handleVisibility} onHover={handleHover} searched={searchTerm} onIsolation={onIsolation} isolatedHandle={isolatedHandle} />
            }>
            {node.children && node.children.sort(sortNodesAlphabeticallyIncNumeric).map((child) => {
                return <ConsoleNode key={"NodeTree-" + child.name + "-" + child.dbId} node={child} onVisibility={onVisibility} onHover={onHover} searchTerm={searchTerm} onIsolation={onIsolation} isolatedHandle={isolatedHandle} rootSelection={rootSelection} />;
            })}
        </TreeItem>
    )
}

export interface ConsoleNodeProps {
    node: NodeData;
    onVisibility: (node: any, visible: boolean) => void;
    onHover: (node: any, hover: boolean) => void;
    searchTerm?: string;
    onIsolation: (node: NodeData, isolated: boolean) => void;
    isolatedHandle: string;
    rootSelection: string;
}