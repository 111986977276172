import { useEffect, useState } from "react";

const getDimensions = (myRef, minWidth, minHeight) => {
    const newWidth = myRef?.current?.offsetWidth ?? 0
    const newHeight = myRef?.current?.offsetHeight ?? 0

    return {
        width: newWidth > 0 ? newWidth : minWidth,
        height: newHeight > 0 ? newHeight : minHeight
    }
}

export const useContainerDimensions = (myRef, minWidth, minHeight) => {
    const [dimensions, setDimensions] = useState({ width: minWidth, height: minHeight })

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions(myRef, minWidth, minHeight))
        }
        if (myRef.current) {
            setDimensions(getDimensions(myRef, minWidth, minHeight))
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [myRef, minHeight, minWidth])

    return dimensions;
};
