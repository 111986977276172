import React, { useCallback, useEffect, useRef, useState } from 'react';
import CommentList from './CommentList';
import { CommentInput } from './CommentInput';
import { IPropertyChange } from './DisplayPropertyChange';
import { Box } from '@mui/system';
import restAPI from 'services/rest-api';
import { States, Priorities, Types } from '../IssueDialog';
import { useTranslation } from 'react-i18next';
import scrollCommentIntoViewAtom from 'atoms/scrollCommentIntoViewAtom';
import { useRecoilState } from 'recoil';
import dateUtils from 'services/dateUtils';
import reloadActiveIssueTimelineAtom from 'atoms/reloadActiveIssueTimelineAtom';

export interface ICommentActions {
    addComment: (issueId: string, comment: string) => void;
    addAttachment: () => void;
}
export interface IComment extends ICommentActions {
    user: string;
    comment: string;
    avatar: {
        URI: string;
        backgroundColor: string;
    };
    created: string;
    updated: string;
    changes?: IPropertyChange | IPropertyChange[];
    images?: string[] | null;
    workGroupFirstUpdate?: string;
}

const augmentUserAvatar: (IComment, any) => void = (workGroup, workItem) => {
    if (!workItem?.userAvatar) {
        return workGroup;
    }
    workGroup.avatar = workItem?.userAvatar;
    workGroup.user = workItem?.userName;
};

const augmentCommentActivity: (IComment, any) => void = (workGroup, workItem) => {
    if (workItem?.activity !== 'userComment' || !workItem?.changeset?.comment) {
        return workGroup;
    }
    workGroup.comment = workItem?.changeset?.comment;
};

export default function Comments(props: React.PropsWithChildren<{ sx?: any; issueId: string }>) {
    const [, setScrollCommentIntoView] = useRecoilState(scrollCommentIntoViewAtom);
    const [issueComments, setIssueComments] = useState<Array<IComment>>([]);
    const [reloadIssueTimeline, setReloadIssueTimeline] = useRecoilState(reloadActiveIssueTimelineAtom);
    const loadedIssueTimeline = useRef(false);
    const { t } = useTranslation('common');

    const commentActions: ICommentActions = {
        addComment: (issueId: string, comment: string) => {
            restAPI
                .addIssueComment(issueId, comment)
                .then((result) => {
                    setReloadIssueTimeline(true);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        addAttachment: () => {},
    };

    const getEmptyWorkGroup: () => IComment = useCallback(() => {
        return {
            avatar: null,
            user: '',
            comment: '',
            created: '',
            updated: '',
            changes: [],
            images: null,
            addComment: commentActions.addComment,
            addAttachment: commentActions.addAttachment,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const augmentPropertyChangeActivity: (IComment, any) => void = useCallback((workGroup, workItem) => {
        const activity = workItem?.activity;
        if (
            activity !== 'issueAssignedTo' &&
            activity !== 'issueDescriptionUpdated' &&
            activity !== 'issueTitleUpdated' &&
            activity !== 'issuePriorityUpdated' &&
            activity !== 'issueStateUpdated' &&
            activity !== 'issueTypeUpdated'
        ) {
            return workGroup;
        }

        const newKey = workItem?.changeset?.updateDescription?.newValue;
        const oldKey = workItem?.changeset?.updateDescription?.oldValue;
        let propName = workItem?.changeset?.updateDescription?.fieldName;
        let newValue = newKey;
        let oldValue = oldKey;

        switch (activity) {
            case 'issuePriorityUpdated':
                newValue = Priorities[newKey] ? t(`IssueDialog.Priorities.${Priorities[newKey]}`) : newValue;
                oldValue = Priorities[oldKey] ? t(`IssueDialog.Priorities.${Priorities[oldKey]}`) : oldValue;
                propName = t(`IssueDialog.Priority`);
                break;
            case 'issueStateUpdated':
                newValue = States[newKey] ? t(`IssueDialog.States.${States[newKey]}`) : newValue;
                oldValue = States[oldKey] ? t(`IssueDialog.States.${States[oldKey]}`) : oldValue;
                propName = t(`IssueDialog.State`);
                break;
            case 'issueTypeUpdated':
                newValue = Types[newKey] ? t(`IssueDialog.Types.${Types[newKey]}`) : newValue;
                oldValue = Types[oldKey] ? t(`IssueDialog.Types.${Types[oldKey]}`) : oldValue;
                propName = t(`IssueDialog.Type`);
                break;
            case 'issueAssignedTo':
                propName = t(`IssueDialog.AssignedTo`);
                break;
            case 'issueDescriptionUpdated':
                propName = t(`IssueDialog.Description`);
                break;
            case 'issueTitleUpdated':
                propName = t(`IssueDialog.Title`);
                break;
        }
        const empty = activity === 'issueAssignedTo' ? 'unassigned' : 'unset';

        workGroup.changes.push({
            propName: propName,
            propOrgValue: oldValue !== '' && oldValue !== null ? oldValue : empty,
            propNewValue: newValue !== '' && newValue !== null ? newValue : empty,
            propKey: activity,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatWorkGroup: (IComment, any) => IComment = useCallback((workGroup, workItem) => {
        workGroup.updated = dateUtils.formatDate(new Date(workItem.createdAt));
        workGroup.workGroupFirstUpdate = dateUtils.formatDate(new Date(workItem.workGroupFirstUpdate));
        augmentCommentActivity(workGroup, workItem);
        augmentPropertyChangeActivity(workGroup, workItem);
        augmentUserAvatar(workGroup, workItem);
        return workGroup;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCommentsFromRawData: (rawData: Array<any>) => IComment[] = useCallback((rawData) => {
        // Remove initial creation and initial thumbnail
        rawData?.forEach((groupParent) => {
            groupParent.workGroup = groupParent.workGroup?.filter((wg) => {
                return (
                    wg.activity !== 'issueCreatedBy' &&
                    !(
                        wg.activity === 'issueDocumentAdded' &&
                        wg.changeset?.updateDescription?.updatedFields?.[0]?.newValue?.endsWith('thumbnail.png')
                    )
                );
            });
        });
        // Remove empty workgrous
        rawData = rawData.filter((gp) => gp.workGroup.length > 0);
        // Formatting
        const comments: IComment[] = rawData?.map((groupParent, index) => {
            const workGroup = getEmptyWorkGroup();
            groupParent?.workGroup?.forEach((workItem) => {
                formatWorkGroup(workGroup, workItem);
            });
            return workGroup;
        });
        return comments;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loadedIssueTimeline.current || !props.issueId) {
            return;
        }
        let mounted = true;
        loadedIssueTimeline.current = true;
        restAPI
            .getIssueTimeline(props.issueId)
            .then((data) => {
                if (!mounted) return Promise.resolve();
                setIssueComments(getCommentsFromRawData(data));
                if (reloadIssueTimeline) {
                    setScrollCommentIntoView(true);
                    setReloadIssueTimeline(false);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                loadedIssueTimeline.current = false;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadIssueTimeline, props.issueId]);

    return (
        <Box minHeight={0} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} gap={'10px'}>
            <CommentList comments={issueComments}></CommentList>
            <CommentInput issueId={props.issueId} addComment={commentActions.addComment} addAttachment={commentActions.addAttachment} />
        </Box>
    );
}
