import { IconButton, Paper, Stack, Typography, Box, Tooltip } from '@mui/material'
import List from '@mui/material/List'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ListSearch from 'components/Shared/List/Search/ListSearch'
import styles from 'styles/TeamList.module.scss';

const TeamList: React.FC<{ children: Array<any>, addTeam: () => void, onFilter: (query: string) => void, roles }> = ({ children, addTeam, onFilter, roles }) => {
    const { t } = useTranslation('common');

    return (
        <div className={styles.teamList}>
            <Stack direction='row' justifyContent='flex-start' flexWrap='nowrap' alignItems='center' minHeight={'40px'}>
                <Typography variant="h5">{t('TeamList.Title')}</Typography>
                <Box className={styles.actions}>
                    <ListSearch onFilter={onFilter} />
                    <Tooltip title="Add Team">
                        <IconButton onClick={() => addTeam()}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Stack>
            <Paper elevation={2} classes={{ root: `${styles.paper}` }}>
                <PerfectScrollbar options={{ suppressScrollX: true, minScrollbarLength: 20 }}>
                    <List>
                        {children}
                    </List>
                </PerfectScrollbar>
            </Paper>
        </div>
    )
}

export default TeamList