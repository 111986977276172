import styles from './HierarchyPanel.module.scss'
import { Box, Typography, Tooltip, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CropFreeIcon from '@mui/icons-material/CropFree'

const ProjectIcon = () => {
    return (
        <img className={styles['class-icon']} src={process.env.PUBLIC_URL + '/icons/home-solid.svg'} alt="Icon for project" height={'auto'} width={20} />
    )
}

const ElementIcon = () => {
    return (
        <img className={styles['class-icon']} src={process.env.PUBLIC_URL + '/icons/cubes-solid.svg'} alt="Icon for elements" height={'auto'} width={20} />
    )
}

const EntityIcon = () => {
    return (
        <img className={styles['class-icon']} src={process.env.PUBLIC_URL + '/icons/cube-solid.svg'} alt="Icon for entities" height={'auto'} width={14.24} />
    )
}

const InvisibilityIcon = () => {
    return (
        <img className={styles['iconButton']} src={process.env.PUBLIC_URL + '/icons/eye-closed.png'} alt="Icon for invisibility" height={'auto'} width={18} />
    )
}

const IsolationIcon = () => {
    return <img src={process.env.PUBLIC_URL + '/icons/compress-arrows-alt-solid.svg'} alt="Icon for isolation" height={'auto'} width={16} />
}

const NonGeomIcon = () => {
    return (
        <Tooltip title="This node does not contain any geometry">
            <CropFreeIcon className={styles['class-icon']} sx={{ width: '20px', height: 'auto' }} />
        </Tooltip>
    )
}

export default function NodeLabel({ node, onVisibility, onHover, searched, onIsolation, isolatedHandle }): JSX.Element {
    const handleIsolation = (e) => {
        e.stopPropagation()
        onIsolation(node, isolatedHandle !== node.dbId)
    }

    const highlightSearchedTerm = (sentence: string) => {
        if (searched?.length > 0) {
            const parts = sentence.split(new RegExp(`(${searched})`, 'gi'))
            return parts.filter((part) => part.length > 0).map((part, index) => (
                <span key={index} className={part.toLowerCase() === searched.toLowerCase() ? styles['searched-term'] : ''}>
                    {part}
                </span>
            ))
        } else {
            return sentence
        }
    }

    const getNodeIcon = () => {
        if (node.level === 0) {
            // Base level
            return <ProjectIcon />;
        } else if (!node.hasGeometry) {
            // No geometry
            return <NonGeomIcon />;
        } else if (node.children?.length > 0) {
            // Has child entities
            return <ElementIcon />;
        } else {
            // Solo entity
            return <EntityIcon />;
        }
    }

    return (
        <Box className={`${styles['node-label-container']} ${!node.visible && styles['node-label-container-invisible']} ${!node.hasGeometry && styles['node-label-container-no-geo']}`}>
            <Box className={styles['node-label-wrapper']}>
                {getNodeIcon()}
                <Typography classes={{ root: styles['node-label'] }}>{highlightSearchedTerm(node.name)}</Typography>
            </Box>
            <Box className={styles['action-buttons']}>
                <Tooltip title="Isolate">
                    <IconButton className={styles['icon-button']} onClick={(event: any) => handleIsolation(event)}>
                        <IsolationIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Toggle Visibility">
                    <IconButton className={styles['visibility-button']} onClick={onVisibility}>
                        {node.visible ? <VisibilityIcon classes={{ root: styles.iconButton }} sx={{ width: '20px', height: 'auto' }} /> : <InvisibilityIcon />}
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}
