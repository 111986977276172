import { Box, Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import restApi from 'services/rest-api';
import styles from './ImportSetDialog.module.scss';
import { useForm } from 'react-hook-form';
import { FormInputSelectMenuItem } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelectMenuItem';
import { FormInputSelect } from 'components/Shared/FormComponents/FormInputSelect/FormInputSelect';
import { FormInputText } from 'components/Shared/FormComponents/FormInputText';
import { Viewer } from 'hsbshareviewer';
import { ImportSetType, getImportSetEntities } from 'components/ModelViewer/ImportSets/importSetEntityHelper';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SnackBarMessage from 'components/Shared/snackBar/SnackBarMessage';

interface ImportSetDialogProps {
    open: boolean;
    projectId: string;
    onClose: (event) => void;
    onImportSetCreated?: (importset: any) => void;
    onImportSetUpdated?: (importset: any) => void;
    importSet?: any; //edit mode
    modelReferences?: Record<string, { handle?: string; ifcId?: string }[]>; //insert mode
    viewer: Viewer;
}

export default function ImportSetDialog(props: ImportSetDialogProps) {
    const { t } = useTranslation('common');
    const { open, onClose, onImportSetCreated, onImportSetUpdated, importSet, modelReferences, viewer } = props;
    const [thumbnail, setThumbnail] = useState(null);
    const [showError, setShowError] = useState(false);

    const { control, getValues, formState } = useForm<any>({
        defaultValues: {
            title: importSet?.properties?.name?.value || '',
            type: Object.keys(ImportSetType).includes(importSet?.properties?.hsbType?.key) ? t(importSet.properties.hsbType.key) : '',
            description: importSet?.properties?.comments?.value || '',
        },
        mode: 'onChange',
    });

    const getImportSetOptions = () => {
        const types = Object.keys(ImportSetType).map((s) => {
            return (
                <FormInputSelectMenuItem
                    key={s}
                    value={s}
                    type={'iconRight'}>
                    {t(`${ImportSetType[s]}`)}
                </FormInputSelectMenuItem>
            );
        });
        return types;
    };

    const handleCreateImportSet = () => {
        if (!formState.isValid) {
            return setShowError(true);
        }

        if (importSet) {
            //update mode
            return restApi.ImportSets.updateImportSet(importSet._id, getValues('title'), getValues('type'), getValues('description')).then(
                (result) => {
                    onClose(null);
                    onImportSetUpdated?.(result);
                },
                (error) => console.error(error)
            );
        }
        // Create mode
        restApi.ImportSets.createImportSet(getValues('title'), getValues('type'), getValues('description'), modelReferences).then(
            (result) => {
                onClose(null);
                onImportSetCreated?.(result);
            },
            (error) => console.error(error)
        );
    };

    useEffect(() => {
        let mounted = true;
        if (open) {
            const entities = getImportSetEntities(viewer, modelReferences ?? importSet?.modelReferences);
            viewer?.screenCapture.screenshotEntities(entities).then((url) => {
                if (mounted) setThumbnail(url);
            });
        }

        return () => {
            mounted = false;
        };
    }, [importSet, modelReferences, open, viewer]);

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: styles['importset-dialog'] }}>
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <DialogTitle className={styles['header-container']}>
                    {importSet ? t('ImportSetDialog.EditImportSet') : t('ImportSetDialog.CreateImportSet')}
                </DialogTitle>
                <DialogContent classes={{ root: styles['body-container'] }}>
                    <img className={styles['body-image']} src={thumbnail} alt="Thumbnail of import set"></img>
                    <Box className={styles['body-input']}>
                        <Stack direction={'row'}>
                            <Typography>{t('ImportSetDialog.Title')}</Typography>
                            <Typography classes={{ root: styles['label-input-required'] }}>*</Typography>
                        </Stack>
                        <FormInputText
                            rules={{ required: true }}
                            variant="outlined"
                            control={control}
                            name="title"
                            placeholder={t('ImportSetDialog.Title')}
                            classes={{ container: styles['form-input'] }}></FormInputText>
                    </Box>
                    <Box className={styles['body-input']}>
                        <Stack direction={'row'}>
                            <Typography>{t('ImportSetDialog.Type')}</Typography>
                            <Typography classes={{ root: styles['label-input-required'] }}>*</Typography>
                        </Stack>
                        <FormInputSelect
                            search
                            name="type"
                            rules={{ required: true }}
                            control={control}
                            classes={{ container: styles['form-input'] }}
                            placeholder={t('ImportSetDialog.Unset')}>
                            {getImportSetOptions()}
                        </FormInputSelect>
                    </Box>
                    <Box className={styles['body-input']}>
                        <Typography>{t('ImportSetDialog.Description')}</Typography>
                        <FormInputText
                            variant="outlined"
                            multiline
                            minRows={2}
                            maxRows={3}
                            control={control}
                            name="description"
                            placeholder={t('ImportSetDialog.Description')}
                            classes={{ container: styles['form-input'] }}></FormInputText>
                    </Box>
                </DialogContent>
                <Box className={styles['footer-container']}>
                    <Button
                        disabled={!formState.isValid || (importSet && !formState.isDirty)}
                        variant="contained"
                        className={styles['form-input']}
                        onClick={handleCreateImportSet}>
                        {importSet ? t('ImportSetDialog.Update') : t('ImportSetDialog.Create')}
                    </Button>
                </Box>
                <SnackBarMessage
                    severity="error"
                    message={t('ImportSetDialog.ErrorNotEmpty')}
                    open={showError}
                    onSetOpen={() => setShowError(false)}
                />
            </PerfectScrollbar>
        </Dialog>
    );
}
