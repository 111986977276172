import { Grid } from "@mui/material";
import ResetPassword from "../components/Password/ResetPassword";
import styles from "../styles/ResetPasswordPage.module.css";

export default function ResetPasswordPage() {
  return (
    <Grid className={styles.grid} container>
      <Grid item xs={12} sm={12} md={5}>
        <div className={styles.loginSidePanel}>
          <ResetPassword />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={7} className={`${styles.imageSidePanel} ${styles.responsive}`}>
        <div
          className={styles.clipi}
          style={{ position: "relative", height: "100vh" }}
        ></div>
      </Grid>
    </Grid>
  );
}
